import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

// Components
import Alert from '../../../utils/Alert';
import SelectButton from '../../../utils/SelectButton';

// Actions
import { model_create, model_update } from '../../../../actions/modelsActions';

// Utils
import errorExists from '../../../../utils/errorExists';
import isEmpty from '../../../../utils/isEmpty';

// Icons
import checkAnimation from '../../../../static/icons/checkAnimation.json';

const ModelForm = (props) => {
	const { show, onHide, orgFilters, update, modelToUpdate, single } = props;

	const dispatch = useDispatch();

	const { organizations, workspaces, modules } = useSelector(state => state.organization);

	const { model_create_loading, model_create_succes, models_errors } = useSelector(state => state.models);
	
	const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);

	useEffect(() => {
		if (!isEmpty(modelToUpdate)) {
			setModelInfo({
				name: modelToUpdate.name,
				description: modelToUpdate.description
			});
		}
	}, [modelToUpdate]);
	
	const initialModelInfo = {
		organization: orgFilters.organization,
		workspace: orgFilters.workspace,
		module: orgFilters.module,
		name: '',
		description: ''
	};
	const [modelInfo, setModelInfo] = useState(initialModelInfo);

	const closeModalRef = useRef();
	const lottieRef	= useRef();

	const closeModal = () => {
		onHide();
		setAlert(initialAlert);
		setModelInfo(initialModelInfo);
	}

	const validateModelInfo = modelInfo.name === '' || modelInfo.description === '';

	const createModel = () => {		
		if (validateModelInfo) {
			setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
			return;
		}
		setAlert(initialAlert);

		dispatch(model_create(modelInfo, closeModalRef, {}));
	}

	const updateModel = () => {
		if (validateModelInfo) {
			setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
			return;
		}
		setAlert(initialAlert);

		const modelBody = {
			organization: modelToUpdate?.organization?.$oid || null,
			workspace: modelToUpdate?.workspace?.$oid || null,
			module: modelToUpdate?.module?.$oid || null,
			name: modelInfo.name,
			description: modelInfo.description
		}
		dispatch(model_update(modelToUpdate?._id?.$oid, modelBody, closeModalRef, {}, single));
	}

	return (
		<Modal
			show={show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{update ? 'Update' : 'Create'} Model</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{alert.display && <Alert type={alert.type} msg={alert.msg} />}
				
				{errorExists(models_errors, 'model_create') &&
					<Alert type='danger' msg={models_errors['model_create'].msg} />
				}

				<Form>
					{orgFilters.organization === '' &&
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Organization <span className='text-muted'>(optional)</span></p>
							<SelectButton
								options={organizations}
								name='organization'
								value={modelInfo.organization}
								onChange={(e) => setModelInfo({ ...modelInfo, organization: e ? e.value : null })}
							/>
						</Container>
					}
					{orgFilters.workspace === '' &&
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Workspace <span className='text-muted'>(optional)</span></p>
							<SelectButton
								options={workspaces}
								name='organization'
								value={modelInfo.organization}
								onChange={(e) => setModelInfo({ ...modelInfo, organization: e ? e.value : null })}
							/>
						</Container>
					}
					{orgFilters.module === '' &&
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Module <span className='text-muted'>(optional)</span></p>
							<SelectButton
								options={modules}
								name='organization'
								value={modelInfo.organization}
								onChange={(e) => setModelInfo({ ...modelInfo, organization: e ? e.value : null })}
							/>
						</Container>
					}
					<Form.Group className='mb-3'>
						<Form.Label>Name</Form.Label>
						<Form.Control
							type='text'
							placeholder='Type model name'
							value={modelInfo.name}
							onChange={(e) => setModelInfo({ ...modelInfo, name: e.target.value })}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label>Description</Form.Label>
						<Form.Control
							type='text'
							placeholder='Type model descriptcion'
							value={modelInfo.description}
							onChange={(e) => setModelInfo({ ...modelInfo, description: e.target.value })}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			<Button variant='outline-secondary' onClick={closeModal} ref={closeModalRef}>Cancel</Button>
				<Button 
					className={model_create_succes ? 'success-btn' : 'submit-btn'}
					onClick={update ? updateModel : createModel}
				>
					{model_create_succes
						?	<Lottie
								loop={false}
								lottieRef={lottieRef}
								animationData={checkAnimation}
								style={{ height: '24px' }}
							/>
						:	model_create_loading
							?	<Fragment>
									<Spinner className='me-2' as='span' animation='border' size='sm' role='status' aria-hidden='true' />
									{update ? 'Updating' : 'Creating'}
								</Fragment>
							:	update ? 'Update' : 'Create'
					}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

ModelForm.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	orgFilters: PropTypes.object.isRequired,
	update: PropTypes.bool.isRequired,
	modelToUpdate: PropTypes.object.isRequired,
	single: PropTypes.bool.isRequired
}

export default ModelForm;
