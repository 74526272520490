import React from 'react';

// React Bootstrap
import { Container, Card, Col, Row } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

const ServiceLoadData = (props) => {
	const { title, data } = props;

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>{title}</Card.Title>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {data._id}</Card.Subtitle>
				<hr/>

				{title === 'General' &&
					<Container className='p-0'>
						<Container className='p-0'>
							<Row>
								<Col className='text-center'><strong>Type:</strong> {data.type}</Col>
							</Row>
							<hr/>
							<Row>
								<Col className='text-center'><strong>Service:</strong> {data.service}</Col>
								<Col className='text-center'><strong>Instance:</strong> {data.instance}</Col>
								<Col className='text-center'><strong>Process:</strong> {data.process}</Col>
							</Row>
						</Container>
					</Container>
				}

				{title !== 'General' &&
					<Container className='p-0'>
						<Row>
							<Col className='text-center'><strong>Num. of processes:</strong> {data.n_processes}</Col>
						</Row>
						<Row>
							<Col>
								<p className='text-center m-0 p-0'><strong>Min process time:</strong> {parseFloat(data.min_process_time).toFixed(4)} sec.</p>
								<p className='text-center m-0 p-0'><strong>Max process time:</strong> {parseFloat(data.max_process_time).toFixed(4)} sec.</p>
							</Col>
							<Col>
								<p className='text-center m-0 p-0'><strong>Sum process times:</strong> {parseFloat(data.sum_process_times).toFixed(4)} sec.</p>
								<p className='text-center m-0 p-0'><strong>Average process time:</strong> {parseFloat(data.average_process_time).toFixed(4)} sec.</p>
							</Col>
						</Row>
					</Container>
				}
			</Card.Body>
		</Card>
	)
}

ServiceLoadData.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired
}

export default ServiceLoadData;
