import { combineReducers } from 'redux';

// Reducers
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import organizationReducer from './organizationReducer';
import servicesReducer from './servicesReducer';
import instanceReducer from './instanceReducer';
import workersReducer from './workersReducer';
import workerInstanceReducer from './workerInstanceReducer';
import jobsReducer from './jobsReducer';
import alertReducer from './alertReducer';
import logsReducer from './logsReducer';
import loadReducer from './loadReducer';
import messagesReducer from './messagesReducer';
import helpersReducer from './helpersReducer';
import helperInstanceReducer from './helperInstanceReducer';
import modelsReducer from './modelsReducer';
import serviceInstanceReducer from './serviceInstanceReducer';
import requestsReducer from './requestsReducer';

export default combineReducers ({
	errors: errorReducer,
	auth: authReducer,
	organization: organizationReducer,
	services: servicesReducer,
	instance: instanceReducer,
	workers: workersReducer,
	workerInstance: workerInstanceReducer,
	jobs: jobsReducer,
	alert: alertReducer,
	logs: logsReducer,
	load: loadReducer,
	messages: messagesReducer,
	helpers: helpersReducer,
	helperInstance: helperInstanceReducer,
	models: modelsReducer,
	serviceInstance: serviceInstanceReducer,
	requests: requestsReducer
});
