import Axios from 'axios';

import {
	WORKER_INSTANCE_LOADING_TRUE,
	WORKER_INSTANCE_LOADING_FALSE,
	WORKER_INSTANCE_INFO_GET,
	WORKER_INSTANCE_INFO_ERROR,
	WORKER_INSTANCE_DATA_GET,
	WORKER_INSTANCE_DATA_ERROR,
	WORKER_INSTANCE_DATA_HISTORY_GET,
	WORKER_INSTANCE_DATA_HISTORY_ERROR,
	WORKER_INSTANCE_CONFIG_GET,
	WORKER_INSTANCE_CONFIG_ERROR,
	WORKER_INSTANCE_START_ERROR,
	WORKER_INSTANCE_STOP_ERROR,
	WORKER_INSTANCE_END_ERROR,
	WORKER_INSTANCE_CLEAN_ERROR,
	WORKER_INSTANCE_STATUS_HISTORY_GET,
	WORKER_INSTANCE_STATUS_HISTORY_ERROR,
	WORKER_INSTANCE_COMMANDS_LOADING,
	WORKER_INSTANCE_COMMANDS_GET,
	WORKER_INSTANCE_COMMANDS_ERROR
} from './types';

// Actions
import { alert_remove, alert_set } from './alertActions';
import { get_jobs_failed, get_jobs_history, get_jobs_waiting, get_job_current, get_job_last } from './jobsActions';

// Utils
import create_query_params from '../utils/create_query_params';

export const worker_instance_loading_true = () => dispatch => {
	dispatch({
		type: WORKER_INSTANCE_LOADING_TRUE
	})
}

export const worker_instance_loading_false = () => dispatch => {
	dispatch({
		type: WORKER_INSTANCE_LOADING_FALSE
	})
}

export const get_worker_instance_info = (instance_id) => dispatch => {
	// console.log(instance_id)
	dispatch(worker_instance_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/state`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_INSTANCE_INFO_GET,
			payload: res.data
		});
		dispatch(worker_instance_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_INFO_ERROR,
			payload: { type: 'worker_instance_info', msg: err.message }
		});
		dispatch(worker_instance_loading_false());
	})
}

export const get_worker_instance_data = (instance_id) => dispatch => {
	// console.log(instance_id)
	dispatch(worker_instance_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/data`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_INSTANCE_DATA_GET,
			payload: typeof res.data === 'string' ? JSON.parse(res.data) : res.data
		});
		dispatch(worker_instance_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_DATA_ERROR,
			payload: { type: 'worker_instance_data', msg: err.message }
		});
		dispatch(worker_instance_loading_false());
	})
}

export const get_worker_instance_data_history = (instance_id, skip, limit) => dispatch => {
	// console.log(instance_id)

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/data/history?skip=${skip}&limit=${limit}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_INSTANCE_DATA_HISTORY_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_DATA_HISTORY_ERROR,
			payload: { type: 'worker_instance_data_history', msg: err.message }
		});
	})
}

export const get_worker_instance_config = (instance_id) => dispatch => {
	// console.log(instance_id)
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/configuration`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_INSTANCE_CONFIG_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_CONFIG_ERROR,
			payload: { type: 'worker_instance_config', msg: err.message }
		});
	})
}

export const worker_instance_start = (instance_id) => dispatch => {
	// console.log(instance_id)
	dispatch(worker_instance_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/start`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch(alert_set('#198754', 'success', 'Worker instance started successfully.'));
		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);

		dispatch(worker_instance_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_START_ERROR,
			payload: { type: 'worker_instance_start', msg: err.message }
		});
		dispatch(worker_instance_loading_false());
	})
}

export const worker_instance_stop = (instance_id) => dispatch => {
	// console.log(instance_id)
	dispatch(worker_instance_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/stop`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch(alert_set('#0d6efd', 'success', 'Worker instance stopped successfully.'));
		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);

		dispatch(worker_instance_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_STOP_ERROR,
			payload: { type: 'worker_instance_stop', msg: err.message }
		});
		dispatch(worker_instance_loading_false());
	})
}

export const worker_instance_end = (instance_id) => dispatch => {
	// console.log(instance_id)
	dispatch(worker_instance_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/end`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch(alert_set('#dc3545', 'success', 'Worker instance ended successfully.'));
		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);

		dispatch(worker_instance_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_END_ERROR,
			payload: { type: 'worker_instance_end', msg: err.message }
		});
		dispatch(worker_instance_loading_false());
	})
}

export const worker_instance_clean = (instance_id, reqBody, closeModalRef) => dispatch => {
	// console.log(instance_id)
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/clean`;

	Axios.delete(url, { data: reqBody })
	.then((res) => {
		// console.log(res.data)
		dispatch(alert_set('#fd7e14', 'success', 'Worker instance cleaned successfully.'));
		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);

		setTimeout(() => {
			closeModalRef.current.click();
			dispatch(get_jobs_waiting('workers', instance_id, 0, 9));
			dispatch(get_job_current('workers', instance_id));
			dispatch(get_job_last('workers', instance_id));
			dispatch(get_jobs_history('workers', instance_id, -10, -1));
			dispatch(get_jobs_failed('workers', instance_id, -10, -1));
		}, 1000);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_END_ERROR,
			payload: { type: 'worker_instance_end', msg: err.message }
		});
	})
}

export const get_worker_instance_status_history = (instance_id, skip, limit) => dispatch => {
	// console.log(instance_id)
	let url = process.env.REACT_APP_SERVER_URL + `/api/resources/workers/instances/${instance_id}/history?skip=${skip}&limit=${limit}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_INSTANCE_STATUS_HISTORY_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_STATUS_HISTORY_ERROR,
			payload: { type: 'worker_instance_status_history', msg: err.message }
		});
	})
}

export const get_worker_instance_commands = (instance_id, filters) => dispatch => {
	dispatch({
		type: WORKER_INSTANCE_COMMANDS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/commands?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_INSTANCE_COMMANDS_GET,
			payload: res.data
		});

		dispatch({
			type: WORKER_INSTANCE_COMMANDS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCE_COMMANDS_ERROR,
			payload: { type: 'worker_instance_commands', msg: err.message }
		});

		dispatch({
			type: WORKER_INSTANCE_COMMANDS_LOADING,
			payload: false
		});
	})
}
