import React from 'react';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getWorkerConfigTypeDesc } from '../../../utils/getStatusDesc';

const WorkerInstConfig = (props) => {
	const { config } = props;

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>Worker</Card.Title>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {config.worker}</Card.Subtitle>
				<hr/>
				<Container className='p-0'>
					<Row>
						<Col className='text-center'><strong>Worker instance:</strong> {config.worker_instance}</Col>
						<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(parseFloat(config.date * 1000))}</Col>
					</Row>
					<hr/>
					<Row>
						<Col>
							<p className='text-center m-0 p-0'><strong>Config type:</strong> {getWorkerConfigTypeDesc(config.config_type)}</p>
							<p className='text-center m-0 p-0'><strong>Logs exp:</strong> {config.logs_exp}</p>
						</Col>
						<Col>
							<p className='text-center m-0 p-0'><strong>History size:</strong> {config.history_size}</p>
							<p className='text-center m-0 p-0'><strong>Fails size:</strong> {config.fails_size}</p>
						</Col>
					</Row>
				</Container>
			</Card.Body>
		</Card>
	)
}

WorkerInstConfig.propTypes = {
	config: PropTypes.object.isRequired
}

export default WorkerInstConfig;
