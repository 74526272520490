import React, { Fragment } from 'react';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../../utils/StatusBadge';

// Utils
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getServiceStatusDesc, getServiceTypeDesc } from '../../../../utils/getStatusDesc';

const ServiceData = (props) => {
	const { serviceData, mainPage } = props;

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				{mainPage
					?	<Card.Title className='text-center' as='h5'>Service Information</Card.Title>
					: <Fragment>
							<Card.Title className='text-center' as='h5'>{serviceData?.name}</Card.Title>
							<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{serviceData?.description}</Card.Subtitle>
						</Fragment>
				}
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {serviceData?._id?.$oid}</Card.Subtitle>
				<hr/>						
				<Container className='p-0'>
					<Row>
						<Col className='text-center'><strong>Alias:</strong> {serviceData?.alias}</Col>
						<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(serviceData?.date?.$date)}</Col>
					</Row>
					<hr/>
					<Row>
						<Col>
							<p className='text-center m-0 p-0'><strong>Status:</strong> <StatusBadge status={getServiceStatusDesc(serviceData?.status)} /></p>
							<p className='text-center m-0 p-0'><strong>Type:</strong> {getServiceTypeDesc(serviceData?.service_type)}</p>
						</Col>
						<Col>
							<p className='text-center m-0 p-0'><strong>Next instance:</strong> {serviceData?.next_instance}</p>
							<p className='text-center m-0 p-0'><strong>Next value:</strong> {serviceData?.next_value}</p>
						</Col>
					</Row>
				</Container>
			</Card.Body>
		</Card>
	)
}

ServiceData.propTypes = {
	serviceData: PropTypes.object.isRequired,
	mainPage: PropTypes.bool.isRequired
}

export default ServiceData;
