import moment from 'moment-timezone';
import 'moment/locale/es';

export const epochToLocalDate = (epoch, locale) => {
	let momentTime = 'None';

	if (epoch !== 0 && epoch !== null && epoch !== undefined) {
		moment.locale(locale);
		momentTime = moment(epoch).format('L LTS');
	}

	return momentTime;
}

export const getLocalEpoch = (dateString) => {
	const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const date = moment.tz(dateString, tz).startOf('day');

	// Get the epoch time in milliseconds
	const epochMillisLocal = date.valueOf();

	// Get the epoch time in seconds
	const epochSecondsLocal = Math.floor(epochMillisLocal / 1000);

	return epochSecondsLocal;
}
