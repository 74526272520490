import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Modal } from 'react-bootstrap';

// Components
import JobInfo from './JobInfo';

// Actions
import { close_removed_job_modal } from '../../../actions/jobsActions';

const RemovedJobModal = () => {
	const dispatch = useDispatch();

	const { job_removed } = useSelector(state => state.jobs);

	return (
		<Modal
			show={job_removed.show}
			onHide={() => dispatch(close_removed_job_modal())}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Job Removed</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<JobInfo title='Job Info' jobsType='workers' jobInfo={job_removed.jobInfo} displayLogs={false} />
			</Modal.Body>
		</Modal>
	)
}

export default RemovedJobModal;
