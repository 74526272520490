import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// React Bootstrap
import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Button, Collapse } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Actions
import { delete_message } from '../../../actions/messagesActions';

// Utils
import { getWorkerMessageTypeDesc } from '../../../utils/getStatusDesc';
import { epochToLocalDate } from '../../../utils/dateConverter';

const Message = (props) => {
	const { message, showModal } = props;

	const dispatch = useDispatch();

	const [showMsgInfo, setShowMsgInfo] = useState(false);

	return (
		<Card className='shadow mb-4 bg-body rounded card-border'>
			<Container>
				<Row>
					<Col style={{ cursor: 'pointer' }} onClick={() => setShowMsgInfo(!showMsgInfo)}>
						<Card.Body className='ps-0'>
							<Card.Title as='h6'>{message.name}</Card.Title>
							<Card.Subtitle className='mb-2 text-muted' as='p'><strong>Id:</strong> {message._id?.$oid}</Card.Subtitle>
						</Card.Body>
					</Col>
					<Col className='d-flex align-items-center' md='auto'>
						<OverlayTrigger
							placement='bottom'
							overlay={<Tooltip id='button-tooltip-2'>Edit</Tooltip>}
						>
							<Button type='button' className='jobs-actions me-3' onClick={showModal}>
								<i className='bi bi-pencil-fill fs-5'></i>
							</Button>
						</OverlayTrigger>
						<OverlayTrigger
							placement='bottom'
							overlay={<Tooltip id='button-tooltip-2'>Delete</Tooltip>}
						>
							<Button type='button' className='jobs-actions me-3' 
								onClick={() => dispatch(delete_message(message._id?.$oid, message))}
							>
								<i className='bi bi-trash-fill fs-5'></i>
							</Button>
						</OverlayTrigger>
					</Col>
				</Row>
				<Collapse in={showMsgInfo}>
					<Container className='mb-3'>
						<Row>
							<hr/>
							<Col>
								<p className='m-0 p-0'><strong>Text:</strong> {message.text}</p>
								<p className='m-0 p-0'><strong>Path:</strong> {message.path}</p>
							</Col>
							<Col>
								<p className='m-0 p-0'><strong>Message type:</strong> {getWorkerMessageTypeDesc(message.message_type)}</p>
								<p className='m-0 p-0'><strong>Worker:</strong> {message.worker?.$oid ? message.worker?.$oid : 'None'}</p>
							</Col>
							<Col>
								<p className='m-0 p-0'><strong>Date:</strong> {epochToLocalDate(message.date.$date)}</p>
							</Col>
						</Row>
					</Container>
				</Collapse>
			</Container>
		</Card>
	)
}

Message.propTypes = {
	message: PropTypes.object.isRequired,
	showModal: PropTypes.func.isRequired
}

export default Message;
