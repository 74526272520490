import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

// Components
import Alert from '../../../utils/Alert';

// Actions
import { version_create, version_update } from '../../../../actions/modelsActions';

// Utils
import errorExists from '../../../../utils/errorExists';
import isEmpty from '../../../../utils/isEmpty';

// Icons
import checkAnimation from '../../../../static/icons/checkAnimation.json';

const VersionForm = (props) => {
	const { show, onHide, modelInfo, update, versionToUpdate } = props;

	const dispatch = useDispatch();

	const { version_create_loading, version_create_succes, versions_errors } = useSelector(state => state.models);
	
	const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);

	useEffect(() => {
		if (!isEmpty(versionToUpdate)) {
			setVersionInfo({
				name: versionToUpdate.name,
				description: versionToUpdate.description
			});
		}
	}, [versionToUpdate]);
	
	const initialVersionInfo = {
		name: '',
		description: ''
	};
	const [versionInfo, setVersionInfo] = useState(initialVersionInfo);

	const closeModalRef = useRef();
	const lottieRef	= useRef();

	const closeModal = () => {
		onHide();
		setAlert(initialAlert);
		setVersionInfo(initialVersionInfo);
	}

	const validateVersionInfo = versionInfo.name === '' || versionInfo.description === '';

	const createVersion = () => {
		if (validateVersionInfo) {
			setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
			return;
		}
		setAlert(initialAlert);

		const versionBody = {
			organization: modelInfo?.organization?.$oid || null,
			workspace: modelInfo?.workspace?.$oid || null,
			module: modelInfo?.module?.$oid || null,
			model: modelInfo?._id?.$oid,
			name: versionInfo.name,
			description: versionInfo.description
		}
		dispatch(version_create(versionBody, closeModalRef, { model: modelInfo?._id?.$oid }));
	}

	const updateVersion = () => {
		if (validateVersionInfo) {
			setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
			return;
		}
		setAlert(initialAlert);

		const versionBody = {
			organization: versionToUpdate?.organization?.$oid || null,
			workspace: versionToUpdate?.workspace?.$oid || null,
			module: versionToUpdate?.module?.$oid || null,
			model: versionToUpdate?._id?.$oid,
			name: versionInfo.name,
			description: versionInfo.description
		}
		dispatch(version_update(versionToUpdate?._id?.$oid, versionBody, closeModalRef, { model: versionToUpdate?.model?.$oid }));
	}

	return (
		<Modal
			show={show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{update ? 'Update' : 'Create'} Model Version</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{alert.display && <Alert type={alert.type} msg={alert.msg} />}

				{errorExists(versions_errors, 'version_create') &&
					<Alert type='danger' msg={versions_errors['version_create'].msg} />
				}
				{errorExists(versions_errors, 'version_update') &&
					<Alert type='danger' msg={versions_errors['version_update'].msg} />
				}

				<Form>
					<Form.Group className='mb-3'>
						<Form.Label>Name</Form.Label>
						<Form.Control
							type='text'
							placeholder='Type model name'
							value={versionInfo.name}
							onChange={(e) => setVersionInfo({ ...versionInfo, name: e.target.value })}
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label>Description</Form.Label>
						<Form.Control
							type='text'
							placeholder='Type model descriptcion'
							value={versionInfo.description}
							onChange={(e) => setVersionInfo({ ...versionInfo, description: e.target.value })}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			<Button variant='outline-secondary' onClick={closeModal} ref={closeModalRef}>Cancel</Button>
				<Button 
					className={version_create_succes ? 'success-btn' : 'submit-btn'}
					onClick={update ? updateVersion : createVersion}
				>
					{version_create_succes
						?	<Lottie
								loop={false}
								lottieRef={lottieRef}
								animationData={checkAnimation}
								style={{ height: '24px' }}
							/>
						:	version_create_loading
							?	<Fragment>
									<Spinner className='me-2' as='span' animation='border' size='sm' role='status' aria-hidden='true' />
									{update ? 'Updating' : 'Creating'}
								</Fragment>
							:	update ? 'Update' : 'Create'
					}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

VersionForm.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired
}

export default VersionForm;
