import {
	LOGS_LOADING_TRUE,
	LOGS_LOADING_FALSE,
	LOGS_GET,
	LOGS_GET_ERROR
} from '../actions/types';

const initialState = {
	logs_loading: false,
	logs: [],
	logs_errors: {}
}

export default function logsReducer(state = initialState, action){
	switch(action.type){
		case LOGS_LOADING_TRUE:
			return {
				...state,
				logs_loading: true
			}
		case LOGS_LOADING_FALSE:
			return {
				...state,
				logs_loading: false
			}
		case LOGS_GET:
			delete state.logs_errors.logs
			return {
				...state,
				logs: action.payload
			}
		case LOGS_GET_ERROR:
			return {
				...state,
				logs_errors: {
					...state.logs_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
