import React, { useState } from 'react';

// Packages
import { Container, Col, Row, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import LoadingSpinner from './LoadingSpinner';
import StatusBadge from './StatusBadge';

// Utils
import { epochToLocalDate } from '../../utils/dateConverter';

// Hooks
import useDidMountEffect from '../../hooks/useDidMountEffect';

const StatusUpdate = (props) => {
	const { show, onHide, loading, statusHistory, statusDescriptions, statusQty, changeStatus } = props;

	const [statusTypes, setStatusTypes] = useState([]);

	useDidMountEffect(() => {
		for (let i = 1; i < statusQty; i++) {
			setStatusTypes(statusTypes => [
				...statusTypes,
				{ value: i }
			]);
		}
	}, [statusQty]);

	const handleStatusChange = (status) => {
		changeStatus(status);
		onHide();
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Status History</Modal.Title>
			</Modal.Header>
			<Modal.Body className='mt-4 mb-4' style={{ maxHeight: '300px', overflowY: 'scroll' }}>
				{loading
					?	<LoadingSpinner loadingMsg='Loading models' />
					:	statusHistory.count > 0
						?	statusHistory.updates.map((update, index) => (
							<Container key={index} className='mb-4'>
								<Row className='text-center'>
									<Col xs={3}>
										<StatusBadge status={statusDescriptions(update.prev_status)} />
									</Col>
									<Col className='d-flex align-items-center justify-content-between' xs={6}>
										<i className='bi bi-arrow-right pe-4'></i>
										<p style={{ marginBottom: 0 }}>{epochToLocalDate(update.date.$date)}</p>
										<i className='bi bi-arrow-right ps-4'></i>
									</Col>
									<Col xs={3}>
										<StatusBadge status={statusDescriptions(update.current_status)} />
									</Col>
								</Row>
							</Container>
						))
						:	<p>No status history found</p>
				}

			</Modal.Body>
			<Modal.Footer style={{ display: 'block' }}>
				<h5 className='text-center mt-3 mb-3'>Options</h5>
				<div className='text-center mb-4'>
					{statusTypes.map((status) => (
						<Button key={status.value} className='jobs-actions me-2' onClick={() => handleStatusChange(status.value)}>
							<StatusBadge status={statusDescriptions(status.value)} />
						</Button>
					))}
				</div>
			</Modal.Footer>
		</Modal>
	)
}

StatusUpdate.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	statusHistory: PropTypes.object.isRequired,
	statusDescriptions: PropTypes.func.isRequired,
	statusQty: PropTypes.number.isRequired,
	changeStatus: PropTypes.func.isRequired
}

export default StatusUpdate;
