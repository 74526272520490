import Axios from 'axios';

import {
	WORKERS_LOADING_TRUE,
	WORKERS_LOADING_FALSE,
	ALL_WORKER_RESOURCES_GET,
	ALL_WORKER_RESOURCES_ERROR,
	WORKER_INFO_GET,
	WORKER_INFO_ERROR,
	WORKER_INSTANCES_LOADING_TRUE,
	WORKER_INSTANCES_LOADING_FALSE,
	WORKER_INSTANCES_GET,
	WORKER_INSTANCES_ERROR,
	WORKER_START,
	WORKER_START_ERROR,
	WORKER_STOP,
	WORKER_STOP_ERROR,
	WORKER_END,
	WORKER_END_ERROR,
	WORKER_EVENTS_LOADING_TRUE,
	WORKER_EVENTS_GET,
	WORKER_EVENTS_ERROR,
	WORKER_JOB_CREATE_LOADING_TRUE,
	WORKER_JOB_CREATE_SUCCESS,
	WORKER_JOB_CREATE_ERROR,
	WORKER_COMMANDS_LOADING,
	WORKER_COMMANDS_GET,
	WORKER_COMMANDS_ERROR
} from './types';

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}=${filters[f]}&`;
	})

	return query_params;
}

export const workers_loading_true = () => dispatch => {
	dispatch({
		type: WORKERS_LOADING_TRUE
	})
}

export const workers_loading_false = () => dispatch => {
	dispatch({
		type: WORKERS_LOADING_FALSE
	})
}

export const get_all_worker_resources = () => dispatch => {
	dispatch(workers_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + '/api/resources/workers?limit=0';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_WORKER_RESOURCES_GET,
			payload: res.data
		});
		dispatch(workers_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_WORKER_RESOURCES_ERROR,
			payload: { type: 'all_worker_resources', msg: err.message }
		});
		dispatch(workers_loading_false());
	})
}

export const get_worker_info = (worker_id) => dispatch => {
	dispatch(workers_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/resources/workers/${worker_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_INFO_GET,
			payload: res.data
		});
		dispatch(workers_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INFO_ERROR,
			payload: { type: 'worker_info', msg: err.message }
		});
		dispatch(workers_loading_false());
	})
}

export const get_worker_instances = (filters) => dispatch => {
	// console.log(filters)
	dispatch({ type: WORKER_INSTANCES_LOADING_TRUE });

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/workers/state?skip=0&limit=0&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_INSTANCES_GET,
			payload: res.data
		});
		dispatch({ type: WORKER_INSTANCES_LOADING_FALSE });
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_INSTANCES_ERROR,
			payload: { type: 'worker_instances', msg: err.message }
		});
		dispatch({ type: WORKER_INSTANCES_LOADING_FALSE });
	})
}

export const worker_start = (worker_info) => dispatch => {
	// console.log(worker_info)
	dispatch(workers_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/workers/start';

	Axios.post(url, worker_info)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_START,
			payload: res.data
		});
		dispatch(workers_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_START_ERROR,
			payload: { type: 'worker_start', msg: err.message }
		})
		dispatch(workers_loading_false());
	})
}

export const worker_stop = (worker_info) => dispatch => {
	// console.log(worker_info)
	dispatch(workers_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/workers/stop';

	Axios.post(url, worker_info)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_STOP,
			payload: res.data
		});
		dispatch(workers_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_STOP_ERROR,
			payload: { type: 'worker_stop', msg: err.message }
		})
		dispatch(workers_loading_false());
	})
}

export const worker_end = (worker_info) => dispatch => {
	// console.log(worker_info)
	dispatch(workers_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/workers/end';

	Axios.post(url, worker_info)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_END,
			payload: res.data
		});
		dispatch(workers_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_END_ERROR,
			payload: { type: 'worker_end', msg: err.message }
		})
		dispatch(workers_loading_false());
	})
}

export const get_worker_events = (worker_id) => dispatch => {
	dispatch({ type: WORKER_EVENTS_LOADING_TRUE });
	let url = process.env.REACT_APP_SERVER_URL + `/api/resources/workers/events?worker=${worker_id}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_EVENTS_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_EVENTS_ERROR,
			payload: { type: 'worker_events', msg: err.message }
		});
	})
}

export const create_worker_job = (workerJobInfo, closeModalRef) => dispatch => {
	dispatch({ type: WORKER_JOB_CREATE_LOADING_TRUE });
	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/workers/jobs/create';

	Axios.post(url, workerJobInfo)
	.then(() => {
		dispatch({
			type: WORKER_JOB_CREATE_SUCCESS,
			payload: true
		});

		setTimeout(() => {
			closeModalRef.current.click();
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: WORKER_JOB_CREATE_SUCCESS,
				payload: false
			})
		}, 2500);
	}).catch((err) => {
		dispatch({
			type: WORKER_JOB_CREATE_ERROR,
			payload: { type: 'create_worker_job', msg: Object.values(err.response.data)[0] }
		});
	})
}

export const get_worker_commands = (filters) => dispatch => {
	dispatch({
		type: WORKER_COMMANDS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/commands?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKER_COMMANDS_GET,
			payload: res.data
		});

		dispatch({
			type: WORKER_COMMANDS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKER_COMMANDS_ERROR,
			payload: { type: 'worker_commands', msg: err.message }
		});

		dispatch({
			type: WORKER_COMMANDS_LOADING,
			payload: false
		});
	})
}