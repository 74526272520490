import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Col, Container, Form, Row } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../utils/LoadingSpinner';
import Helper from './Helper';

// Actions
import { get_organizations } from '../../../actions/organizationActions';
import { get_all_helpers } from '../../../actions/helpersActions';

const Helpers = () => {
	const dispatch = useDispatch();

	const { helpers_loading, all_helpers } = useSelector(state => state.helpers);

	const [allHelper, setAllHelper] = useState([]);
	const [helperSearch, setHelperSearch] = useState('');

	useEffect(() => {
		dispatch(get_organizations());
		dispatch(get_all_helpers());
	}, []);

	useEffect(() => {
		setAllHelper(all_helpers.helpers);
	}, [all_helpers]);

	useEffect(() => {
		const helpersFound = all_helpers.helpers.filter(helper => helper.name.toLowerCase().includes(helperSearch.toLowerCase()));
		setAllHelper(helpersFound);
	}, [helperSearch]);

	return (
		<Container className='mt-3'>
			<h3 className='text-center'>Helpers</h3>

			<Form onSubmit={(e) => e.preventDefault()}>
				<Form.Group as={Row} className='mb-3' controlId='helperName'>
					<Form.Label column sm='2' className='text-center'>
						<i className='bi bi-search me-2'></i> Helper Name
					</Form.Label>
					<Col sm='10'>
						<Form.Control
							type='text'
							placeholder='Type helper name.'
							onChange={(e) => setHelperSearch(e.target.value)}
						/>
					</Col>
				</Form.Group>
			</Form>
			<p className='text-muted'>Total helpers: {all_helpers.count}</p>
			
			<Container className='custom-container'>
				{helpers_loading
					?	<LoadingSpinner loadingMsg='Loading helpers' />
					:	<Container>
							{allHelper.length > 0
								?	allHelper.map((helper, idx) => (
										<Helper key={idx} helper={helper} />
									))
								: helperSearch !== ''
									?	<p className='text-center'>No matches</p>
									: <p className='text-center'>No helpers found</p>
							}
						</Container>
				}
			</Container>
		</Container>
	);
}

export default Helpers;
