import Axios from 'axios';

import {
	SERVICE_INSTANCE_LOADING,
	SERVICE_INSTANCE_INFO_GET,
	SERVICE_INSTANCE_INFO_ERROR
} from './types';

export const get_service_instance_info = (instance_id) => dispatch => {
	dispatch({
		type: SERVICE_INSTANCE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/resources/instances/${instance_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: SERVICE_INSTANCE_INFO_GET,
			payload: res.data
		});
		
		dispatch({
			type: SERVICE_INSTANCE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SERVICE_INSTANCE_INFO_ERROR,
			payload: { type: 'service_instance_info', msg: err.message }
		});
		
		dispatch({
			type: SERVICE_INSTANCE_LOADING,
			payload: false
		});
	})
}
