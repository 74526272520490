import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Table } from 'react-bootstrap';

// Components
import StatusBadge from '../../utils/StatusBadge';
import Pagination from '../../utils/Pagination';

import { get_helper_instance_status_history } from '../../../actions/helperInstanceActions';

// Utils
import { getHelperInstanceStatusDesc } from '../../../utils/getStatusDesc';
import { epochToLocalDate } from '../../../utils/dateConverter';

const StatusHistory = () => {
  const { instance_id } = useParams();

  const dispatch = useDispatch();

  const { helper_instance_status_history } = useSelector(state => state.helperInstance);

  const [skip, setSkip] = useState(0);
	const [limit] = useState(10);

  const nPages = Math.ceil(helper_instance_status_history.count / limit);

  useEffect(() => {
    dispatch(get_helper_instance_status_history(instance_id, skip, limit));
  }, [skip]);

  return (
    <Fragment>
      <Table className='mt-4' responsive>
        <thead style={{backgroundColor: '#e7f1ff'}}>
          <tr>
            <th>Id</th>
            <th>Prev Status</th>
            <th>Current Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {helper_instance_status_history.count > 0
            ?	helper_instance_status_history.history.map((status) => (
                <tr key={status._id.$oid}>
                  <td>{status._id.$oid}</td>
                  <td><StatusBadge status={getHelperInstanceStatusDesc(status.prev_status)} /></td>
                  <td><StatusBadge status={getHelperInstanceStatusDesc(status.current_status)} /></td>
                  <td>{epochToLocalDate(status.date.$date)}</td>
                </tr>
              ))
            :	<tr>
                <td className='text-center' colSpan={4}>No history</td>
              </tr>
          }
        </tbody>
      </Table>
      <Pagination
        count={helper_instance_status_history.count}
        arrayLength={helper_instance_status_history.history.length}
        nPages={nPages}
        skip={skip}
        setSkip={setSkip}
        limit={limit}
      />
    </Fragment>
  );
}

export default StatusHistory;
