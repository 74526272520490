import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import JobInfo from './JobInfo';
import Error from '../../utils/Error';

// Actions
import { get_job_current, get_job_last } from '../../../actions/jobsActions';

// Utils
import errorExists from '../../../utils/errorExists';

const JobCurrentLast = (props) => {
	const { jobsType } = props;

	const { instance_id } = useParams();

	const dispatch = useDispatch();
	
	const { job_current, job_last, jobs_errors } = useSelector(state => state.jobs);

	useEffect(() => {
		dispatch(get_job_current(jobsType, instance_id));
		dispatch(get_job_last(jobsType, instance_id));
	}, []);

	return (
		<Row>
			<Col>
				{errorExists(jobs_errors, 'job_current')
					?	<Error title='Current Job' type='warning' msg={jobs_errors['job_current'].msg} />
					:	<JobInfo title='Current Job' jobsType={jobsType} jobInfo={job_current} displayLogs />
				}
			</Col>
			<Col>
				{errorExists(jobs_errors, 'job_last')
					?	<Error title='Last Job' type='warning' msg={jobs_errors['job_last'].msg} />
					: <JobInfo title='Last Job' jobsType={jobsType} jobInfo={job_last} displayLogs />
				}
			</Col>
		</Row>
	);
}

JobCurrentLast.propTypes = {
	jobsType: PropTypes.string.isRequired
}

export default JobCurrentLast;
