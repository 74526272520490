import React from 'react';

// React Bootstrap
import { Container, Row, Col, Card } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../utils/StatusBadge';

// Utils
import { getWorkerStatusDesc } from '../../../utils/getStatusDesc';

const Worker = (props) => {
	const { worker } = props;

	return (
		<Card className='shadow mb-4 bg-body rounded card-border' style={{ cursor: 'pointer' }}
			onClick={() => window.open(`/worker/${worker._id.$oid}/resource/${worker.resource._id.$oid}`, '_self')}
		>
			<Container>
				<Row>
					<Col>
						<Card.Body className='ps-0'>
							<Card.Title as='h6'>{worker.name}</Card.Title>
							<Card.Subtitle className='mb-2 text-muted' as='p'>{worker.description}</Card.Subtitle>
							<Card.Text>
								<strong>Id:</strong> {worker._id.$oid} - <strong>Alias:</strong> {worker.alias} - <StatusBadge status={getWorkerStatusDesc(worker.status)} />
							</Card.Text>
						</Card.Body>
					</Col>
				</Row>
			</Container>
		</Card>
	);
}

Worker.propTypes = {
	worker: PropTypes.object.isRequired
}

export default Worker;
