import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

// React Bootstrap
import { Table, Container, Row, Col, Spinner, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../utils/StatusBadge';
import JobData from './JobData';

// Actions
import { retry_job } from '../../../actions/jobsActions'; 

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import isEmpty from '../../../utils/isEmpty';
import { getJobsStatusDesc } from '../../../utils/getStatusDesc';
import findWorkerEvent from '../../../utils/findWorkerEvent';

const JobsTable = (props) => {
	const { loading, jobsType, jobs, noJobsMsg } = props;

	const { worker_id, helper_id, instance_id } = useParams();

	const dispatch = useDispatch();

	const { worker_events } = useSelector(state => state.workers);

	const [showJobData, setShowJobData] = useState(false);
	const [selectedJob, setSelectedJob] = useState({});

	const selectJob = (jobId) => {
		let jobFound = {};
		
		if (!isEmpty(selectedJob)) {
			if (jobId !== selectedJob.header.id) {
				jobFound = jobs.find(job => job.header.id === jobId);
				if (isEmpty(jobFound.data)) {
					jobFound.data = { data: 'No data' }
				}
				setSelectedJob(jobFound);
				setShowJobData(true);
			}
			else {
				setShowJobData(false);
				setSelectedJob({});
			}
		}
		else {
			jobFound = jobs.find(job => job.header.id === jobId);
			if (isEmpty(jobFound.data)) {
				jobFound.data = { data: 'No data' }
			}
			setSelectedJob(jobFound);
			setShowJobData(true);
		}
	}

	const setLogsLink = (job) => {
		let logsLink = '';

		if (jobsType === 'workers') {
			logsLink = `/worker/${worker_id}/instance/${instance_id}/job/${job.header.id}`;
		}
		else if (jobsType === 'helpers') {
			logsLink = `/helper/${helper_id}/instance/${instance_id}/job/${job.header.id}`;
		}

		return logsLink;
	}

	return (
		<Table responsive>
			<thead style={{backgroundColor: '#e7f1ff'}}>
				<tr>
					<th>Id</th>
					<th>Type</th>
					<th>Status</th>
					<th>Source</th>
					<th>Date</th>
					<th>Start</th>
					<th>End</th>
					<th>Process</th>
					<th>Failed</th>
				</tr>
			</thead>
			<tbody>
				{loading 
					? <tr>
							<td className='text-center' colSpan={9}>
								<Container className='d-flex align-items-center justify-content-center' style={{ height: '5rem' }}>
									<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
								</Container>
							</td>
						</tr>
					: jobs.length > 0
						?	jobs.map((job) => (
							<Fragment key={job.header.id}>
								<tr style={{ cursor: 'pointer' }} onClick={() => selectJob(job.header.id)}>
									<td>{job.header.id}</td>
									<td>{findWorkerEvent(worker_events, job.header.type)}</td>
									<td><StatusBadge status={getJobsStatusDesc(job.header.status)} /></td>
									<td>{job.header.source}</td>
									<td>{epochToLocalDate(job.header.date * 1000)}</td>
									<td>{epochToLocalDate(job.header.start * 1000)}</td>
									<td>{epochToLocalDate(job.header.end * 1000)}</td>
									<td>{job.header.process}</td>
									<td>{epochToLocalDate(job.header.failed * 1000)}</td>
								</tr>
								{showJobData && job.header.id === selectedJob.header.id
									?	<tr>
											<td colSpan={9}>
												<Container>
													<Row>
														<Col>
															<JobData jobData={selectedJob.data} />
														</Col>
														<Col className='d-flex align-items-center' md='auto'>
															{jobsType === 'workers' &&
																<OverlayTrigger
																	placement='bottom'
																	overlay={<Tooltip id='button-tooltip-2'>Retry</Tooltip>}
																>
																	<Button type='button' className='logs-link me-3' 
																		onClick={() => dispatch(retry_job(
																			instance_id,
																			job.header.id, 
																			job.header.status >= 5 && job.header.status <= 7 ? 'fails' : 'history'
																		))}
																	>
																		<i className='bi bi-arrow-repeat'></i>
																	</Button>
																</OverlayTrigger>
															}
															<OverlayTrigger
																placement='bottom'
																overlay={<Tooltip id='button-tooltip-2'>View Logs</Tooltip>}
															>
																<Link className='logs-link me-2' to={setLogsLink(job)}>
																	<i className='bi bi-card-text'></i>
																</Link>
															</OverlayTrigger>
														</Col>
													</Row>
												</Container>
											</td>
										</tr>
									: null
								}
							</Fragment>
						))
						:	<tr>
								<td className='text-center' colSpan={9}>{noJobsMsg}</td>
							</tr>
				}
			</tbody>
		</Table>
	);
}

JobsTable.propTypes = {
	loading: PropTypes.bool,
	jobsType: PropTypes.string.isRequired,
	jobs: PropTypes.array.isRequired,
	noJobsMsg: PropTypes.string.isRequired
}

export default JobsTable;
