import Axios from 'axios';

import {
	HELPERS_LOADING,
	ALL_HELPERS_GET,
	ALL_HELPERS_ERROR,
	HELPER_INFO_GET,
	HELPER_INFO_ERROR,
	HELPER_INSTANCES_LOADING,
	HELPER_INSTANCES_GET,
	HELPER_INSTANCES_ERROR,
	HELPER_START,
	HELPER_START_ERROR,
	HELPER_STOP,
	HELPER_STOP_ERROR,
	HELPER_END,
	HELPER_END_ERROR,
	HELPER_COMMANDS_LOADING,
	HELPER_COMMANDS_GET,
	HELPER_COMMANDS_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';

export const get_all_helpers = () => dispatch => {
	dispatch({
		type: HELPERS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/resources/helpers?limit=0';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_HELPERS_GET,
			payload: res.data
		});
		
		dispatch({
			type: HELPERS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_HELPERS_ERROR,
			payload: { type: 'all_helpers', msg: err.message }
		});
		
		dispatch({
			type: HELPERS_LOADING,
			payload: false
		});
	})
}

export const get_helper_info = (helper_id) => dispatch => {
	dispatch({
		type: HELPERS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/resources/helpers/${helper_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: HELPER_INFO_GET,
			payload: res.data
		});
		
		dispatch({
			type: HELPERS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: HELPER_INFO_ERROR,
			payload: { type: 'helper_info', msg: err.message }
		});
		
		dispatch({
			type: HELPERS_LOADING,
			payload: false
		});
	})
}

export const get_helper_instances = (filters) => dispatch => {
	// console.log(filters)
	dispatch({
		type: HELPER_INSTANCES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/helpers/state?skip=0&limit=0&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: HELPER_INSTANCES_GET,
			payload: res.data
		});
		
		dispatch({
			type: HELPER_INSTANCES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: HELPER_INSTANCES_ERROR,
			payload: { type: 'helper_instances', msg: err.message }
		});
		
		dispatch({
			type: HELPER_INSTANCES_LOADING,
			payload: false
		});
	})
}

export const helper_start = (helper_info) => dispatch => {
	// console.log(helper_info)

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/helpers/start';

	Axios.post(url, helper_info)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: HELPER_START,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: HELPER_START_ERROR,
			payload: { type: 'helper_start', msg: err.message }
		})
	})
}

export const helper_stop = (helper_info) => dispatch => {
	// console.log(helper_info)

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/helpers/stop';

	Axios.post(url, helper_info)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: HELPER_STOP,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: HELPER_STOP_ERROR,
			payload: { type: 'helper_stop', msg: err.message }
		})
	})
}

export const helper_end = (helper_info) => dispatch => {
	// console.log(helper_info)

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/helpers/end';

	Axios.post(url, helper_info)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: HELPER_END,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: HELPER_END_ERROR,
			payload: { type: 'helper_end', msg: err.message }
		})
	})
}

export const get_helper_commands = (filters) => dispatch => {
	// console.log(filters)
	dispatch({
		type: HELPER_COMMANDS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/helpers/commands?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		console.log(res.data)
		dispatch({
			type: HELPER_COMMANDS_GET,
			payload: res.data
		});

		dispatch({
			type: HELPER_COMMANDS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: HELPER_COMMANDS_ERROR,
			payload: { type: 'helper_commands', msg: err.message }
		});

		dispatch({
			type: HELPER_COMMANDS_LOADING,
			payload: false
		});
	})
}
