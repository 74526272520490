import React, { useEffect, useState } from 'react';

// Packages
import { Container } from 'react-bootstrap';
import moment from 'moment';

const StopWatch = () => {
	const [reloadDate] = useState(moment().format('L - LTS'));
	const [startDateMoment] = useState(moment());
	const [endDateMoment, setEndDateMoment] = useState(moment());
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		const timerID = setInterval(() => {
			setEndDateMoment(moment());
		}, 1000);

		return () => clearInterval(timerID);
	}, []);
	
	useEffect(() => {
		const secondsDiff = endDateMoment.diff(startDateMoment, 'seconds');

		const secondsAux = `0${(secondsDiff % 60)}`.slice(-2);
		const minutesDiff = `${Math.floor(secondsDiff / 60)}`;
		const minutesAux = `0${minutesDiff % 60}`.slice(-2);
		const hoursAux = `0${Math.floor(secondsDiff / 3600)}`.slice(-2);

		setHours(hoursAux);
		setMinutes(minutesAux);
		setSeconds(secondsAux);
	}, [startDateMoment, endDateMoment]);

	return (
		<Container className='mt-5 text-center'>
			<p className='fs-5'>
				<strong>Last Page Refresh:</strong> {reloadDate} 
				<i className='bi bi-stopwatch ms-3 me-2'></i> {hours} : {minutes} : {seconds}
				<span className='ms-2'>ago</span>
			</p>
		</Container>
	);
}

export default StopWatch;
