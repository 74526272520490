import React from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import clsx from 'clsx';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// Components
import SelectButton from '../../../utils/SelectButton';

// Utils
import { getJobsStatusDesc, getRequestMethodDesc } from '../../../../utils/getStatusDesc';

const RequestFilters = (props) => {
	const { searchReqsFilters, setSearchReqsFilters, resetSearchReqsFilters, handleSearchRequest } = props;

	const { organizations } = useSelector(state => state.organization);
	
	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Container className='p-0'>
					<Form>
						<h5 className='text-center mb-3'>Path</h5>
						<Row className='mb-3'>
							<Col>
								<Row className='align-items-center'>
									<Col className={clsx('text-center', {
											'text-primary fw-bold': searchReqsFilters.method !== null
										})} lg='2'
									>
										Method:
									</Col>
									<Col>
										<SelectButton
											options={Array.from({ length: 9 }, (_, i) => ({ value: i, name: getRequestMethodDesc(i) }))}
											name='method'
											value={searchReqsFilters.method}
											onChange={(e) => setSearchReqsFilters({ ...searchReqsFilters, method: e !== null ? e.value : null })}
										/>
									</Col>
								</Row>
							</Col>
							<Col>
								<Row className='align-items-center'>
									<Col className={clsx('text-center', {
											'text-primary fw-bold': searchReqsFilters.path.length > 0
										})} lg='2'
									>
										Path:
									</Col>
									<Col>
										<Form.Control
											value={searchReqsFilters.path}
											onChange={(e) => setSearchReqsFilters({ ...searchReqsFilters, path: e.target.value })}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<hr/>
						<h5 className='text-center mb-3'>User</h5>
						<Row className='mb-3'>
							<Col lg='4' md='4' sm='12'>
								<Row className='align-items-center'>
									<Col className={clsx('text-center', {
											'text-primary fw-bold': searchReqsFilters.organization !== null
										})} lg='2'
									>
										Org:
									</Col>
									<Col>
										<SelectButton
											options={organizations}
											name='organization'
											value={searchReqsFilters.organization}
											onChange={(e) => setSearchReqsFilters({ ...searchReqsFilters, organization: e !== null ? e.value : null })}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg='4' md='4' sm='12'>
								<Row className='align-items-center'>
									<Col className={clsx('text-center', {
											'text-primary fw-bold': searchReqsFilters.token.length > 0
										})} lg='3'
									>
										Token:
									</Col>
									<Col>
										<Form.Control
											value={searchReqsFilters.token}
											onChange={(e) => setSearchReqsFilters({ ...searchReqsFilters, token: e.target.value })}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg='4' md='4' sm='12'>
								<Row className='align-items-center'>
									<Col className={clsx('text-center', {
											'text-primary fw-bold': searchReqsFilters.user.length > 0
										})} lg='2'
									>
										User:
									</Col>
									<Col>
										<Form.Control
											value={searchReqsFilters.user}
											onChange={(e) => setSearchReqsFilters({ ...searchReqsFilters, user: e.target.value })}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<hr/>
						<h5 className='text-center mb-3'>Status</h5>
						<Row className='align-items-center mb-3'>
							<Col lg='4' md='4' sm='12'>
								<Row className='align-items-center'>
									<Col className={clsx('text-center', {
											'text-primary fw-bold': searchReqsFilters.status !== null
										})} lg='3'
									>
										Status:
									</Col>
									<Col>
										<SelectButton
											options={Array.from({ length: 7 }, (_, i) => ({ value: i, name: getJobsStatusDesc(i) }))}
											name='status'
											value={searchReqsFilters.status}
											onChange={(e) => setSearchReqsFilters({ ...searchReqsFilters, status: e !== null ? e.value : null })}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg='4' md='4' sm='12' className='text-center'>
								<Form.Check
									inline
									id='good'
									type='checkbox'
									label='Good'
									checked={searchReqsFilters.good}
									onChange={() => setSearchReqsFilters({ ...searchReqsFilters, good: !searchReqsFilters.good })}
								/>
							</Col>
							<Col lg='4' md='4' sm='12' className='text-center'>
								<Form.Check
									inline
									id='bad'
									type='checkbox'
									label='Bad'
									checked={searchReqsFilters.bad}
									onChange={() => setSearchReqsFilters({ ...searchReqsFilters, bad: !searchReqsFilters.bad })}
								/>
							</Col>
						</Row>
						<hr/>
						<h5 className='text-center mb-3'>Dates</h5>
						<Row className='mb-3'>
							<Col lg='6' md='6' sm='12'>
								<Row className='align-items-center'>
									<Col className={clsx('text-center', {
											'text-primary fw-bold': searchReqsFilters.start.length > 0
										})} lg='2'
									>
										Start:
									</Col>
									<Col>
										<input
											type='date'
											value={searchReqsFilters.start}
											max={moment().format('YYYY-MM-DD')}
											onChange={(e) => setSearchReqsFilters({ ...searchReqsFilters, start: e.target.value })}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg='6' md='6' sm='12'>
								<Row className='align-items-center'>
									<Col className={clsx('text-center', {
											'text-primary fw-bold': searchReqsFilters.end.length > 0
										})} lg='2'
									>
										End:
									</Col>
									<Col>
										<input
											type='date'
											value={searchReqsFilters.end}
											max={moment().format('YYYY-MM-DD')}
											onChange={(e) => setSearchReqsFilters({ ...searchReqsFilters, end: e.target.value })}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<hr/>
						<h5 className='text-center mb-3'>Sort</h5>
						<div className='d-flex justify-content-center mb-3'>
							<Form.Check
								id='sort'
								type='checkbox'
								label='Sort by date'
								checked={searchReqsFilters.sort}
								onChange={() => setSearchReqsFilters({ ...searchReqsFilters, sort: !searchReqsFilters.sort })}
							/>
						</div>
						<div className='d-flex justify-content-evenly'>
							<Button className='btn-submit-outline' style={{ width: '200px' }} onClick={resetSearchReqsFilters}>
								<i className='bi bi-x-circle me-2'></i>Reset Form
							</Button>
							<Button className='submit-btn' style={{ width: '200px' }} onClick={() => handleSearchRequest(true)}>
								<i className='bi bi-search me-2'></i>Search
							</Button>
						</div>
					</Form>
				</Container>
			</Card.Body>
		</Card>
	)
}

RequestFilters.propTypes = {
	searchReqsFilters: PropTypes.object.isRequired,
	setSearchReqsFilters: PropTypes.func.isRequired,
	resetSearchReqsFilters: PropTypes.func.isRequired,
	handleSearchRequest: PropTypes.func.isRequired
}

export default RequestFilters;
