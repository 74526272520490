import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Actions
import { get_instance_options } from '../../../actions/instanceActions';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';

const InstanceOption = (props) => {
	const { option_id } = props;

	const dispatch = useDispatch();

	const { instance_options } = useSelector(state => state.instance);

	const [option, setOption] = useState({});

	useEffect(() => {
		dispatch(get_instance_options());
	}, []);

	useEffect(() => {
		if (instance_options.count > 0) {
			const optionFound = instance_options.options.find(option => option._id.$oid === option_id);
			setOption(optionFound);
		}
	}, [instance_options]);

	return (
		<Container className='mt-3'>
			<h5 className='text-center'>Option</h5>

			<Card className='mb-4'>
				<Card.Body>
					<Card.Title as='h6'>{option?.name}</Card.Title>
					<Card.Subtitle className='mb-2 text-muted' as='p'>{option?.description}</Card.Subtitle>
						<Container>
							<Row>
								<Col>
									<Row>
										<p className='m-0 p-0'><strong>Id:</strong> {option?._id?.$oid}</p>
									</Row>
									<Row>
										<p className='m-0 p-0'><strong>Date:</strong> {epochToLocalDate(option?.date?.$date)}</p>
									</Row>
								</Col>
							</Row>
						</Container>
				</Card.Body>
			</Card>
		</Container>
	);
}

InstanceOption.propTypes = {
	option_id: PropTypes.string.isRequired
}

export default InstanceOption;
