import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Container } from 'react-bootstrap';

// Components
import InstanceInfo from '../../instances/InstanceInfo';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import ServiceData from './ServiceData';

// Actions
import { get_service_state } from '../../../../actions/servicesActions';

// Utils
import isEmpty from '../../../../utils/isEmpty';

const ServiceInfo = () => {
	const { service_id } = useParams();

	const dispatch = useDispatch();

	const { services_loading, service_state } = useSelector(state => state.services);

	useEffect(() => {
		dispatch(get_service_state(service_id));
	}, []);

	if (services_loading && isEmpty(service_state)) {
		return (
			<LoadingSpinner loadingMsg='Loading service info' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container'>
				<h2 className='text-center'>{service_state?.service?.name}</h2>
				<p className='text-center text-muted'>{service_state?.service?.description}</p>

				<ServiceData serviceData={service_state.service} mainPage={true} />
				<hr/>

				{service_state.instances !== undefined
					?	service_state.instances.length > 0
						?	<Fragment>
								<h2 className='text-center'>Instances</h2>
								{service_state.instances.map((instance, idx) => (
									<InstanceInfo key={idx} instance={instance} />
								))}
							</Fragment>
						: null
					: null
				}
			</Container>
		);
	}
}

export default ServiceInfo;
