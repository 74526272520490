import Axios from 'axios';

import {
	MESSAGES_LOADING_TRUE,
	MESSAGES_LOADING_FALSE,
	ALL_MESSAGES_GET,
	ALL_MESSAGES_GET_ERROR,
	UPDATE_MESSAGE_LOADING_TRUE,
	UPDATE_MESSAGE_LOADING_FALSE,
	CREATE_MESSAGE,
	CREATE_MESSAGE_ERROR,
	UPDATE_MESSAGE,
	UPDATE_MESSAGE_ERROR
} from './types';

import { alert_remove, alert_set } from './alertActions';

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}=${filters[f]}&`;
	})

	return query_params;
}

export const messages_loading_true = () => dispatch => {
	dispatch({
		type: MESSAGES_LOADING_TRUE
	})
}

export const messages_loading_false = () => dispatch => {
	dispatch({
		type: MESSAGES_LOADING_FALSE
	})
}

export const get_all_messages = (filters) => dispatch => {
	// console.log(filters)
	dispatch(messages_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + '/api/resources/workers/messages?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_MESSAGES_GET,
			payload: res.data
		});
		dispatch(messages_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_MESSAGES_GET_ERROR,
			payload: { type: 'all_messages', msg: err.message }
		});
		dispatch(messages_loading_false());
	})
}

export const create_message = (messageInfo, closeModalRef) => dispatch => {
	// console.log(messageInfo)
	dispatch({ type: UPDATE_MESSAGE_LOADING_TRUE });
	let url = process.env.REACT_APP_SERVER_URL + '/api/resources/workers/messages/create';

	Axios.post(url, messageInfo)
	.then((res) => {
		// console.log(res.data)
		dispatch({ type: UPDATE_MESSAGE_LOADING_FALSE });

		dispatch({
			type: CREATE_MESSAGE,
			payload: true
		});

		setTimeout(() => {
			closeModalRef.current.click();
			dispatch(get_all_messages({}));
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: CREATE_MESSAGE,
				payload: false
			})
		}, 2500);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CREATE_MESSAGE_ERROR,
			payload: { type: 'create_message', msg: Object.values(err.response.data)[0] }
		});
		dispatch({ type: UPDATE_MESSAGE_LOADING_FALSE });
	})
}

export const update_message = (messageId, messageInfo, closeModalRef) => dispatch => {
	// console.log(messageInfo)
	dispatch({ type: UPDATE_MESSAGE_LOADING_TRUE });
	let url = process.env.REACT_APP_SERVER_URL + `/api/resources/workers/messages/${messageId}/update`;

	Axios.put(url, messageInfo)
	.then((res) => {
		// console.log(res.data)
		dispatch({ type: UPDATE_MESSAGE_LOADING_FALSE });

		dispatch({
			type: UPDATE_MESSAGE,
			payload: true
		});

		setTimeout(() => {
			closeModalRef.current.click();
			dispatch(get_all_messages({}));
		}, 2400);

		setTimeout(() => {
			dispatch({
				type: UPDATE_MESSAGE,
				payload: false
			})
		}, 2500);
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: UPDATE_MESSAGE_ERROR,
			payload: { type: 'update_message', msg: Object.values(err.response.data)[0] }
		});
		dispatch({ type: UPDATE_MESSAGE_LOADING_FALSE });
	})
}

export const delete_message = (messageId, message) => dispatch => {
	// console.log(messageId)
	let url = process.env.REACT_APP_SERVER_URL + `/api/resources/workers/messages/${messageId}/remove`;

	Axios.delete(url)
	.then((res) => {
		// console.log(res.data)
		dispatch(alert_set('#198754', 'success', `Message worker deleted — ${message.name}.`));
		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);

		dispatch(get_all_messages({}));
	}).catch((err) => {
		// console.log(err);
		dispatch(alert_set('#dc3545', 'danger', `Error when removing message — ${Object.values(err.response.data)[0]}`));
		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
	})
}