import {
	INSTANCE_OPTIONS_GET,
	INSTANCE_OPTIONS_ERROR,
	INSTANCE_MACHINES_GET,
	INSTANCE_MACHINES_ERROR
} from '../actions/types';

const initialState = {
	instance_options: { count: 0, options: [] },
	instance_machines: { count: 0, machines: [] },
	instance_error: { isError: false, type: '', msg: '' }
}

export default function instanceReducer(state = initialState, action){
	switch(action.type){
		case INSTANCE_OPTIONS_GET:
			return {
				...state,
				instance_options: {
					count: action.payload.count,
					options: action.payload.options
				}
			}
		case INSTANCE_MACHINES_GET:
			return {
				...state,
				instance_machines: {
					count: action.payload.count,
					machines: action.payload.machines
				}
			}
		case INSTANCE_OPTIONS_ERROR:
		case INSTANCE_MACHINES_ERROR:
			return {
				...state,
				instance_error: { isError: true, type: action.payload.type, msg: action.payload.msg }
			}
		default:
			return state;
	}
}
