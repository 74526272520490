const isObjectId = (string) => {
	let validMongoID = new RegExp('^[0-9a-fA-F]{24}$');

	if (validMongoID.test(string)) {
		return true;
	}
	else {
		return false;
	}
}

export default isObjectId;
