import Axios from 'axios';

import {
	ORGANIZATIONS_GET,
	ORGANIZATIONS_ERROR,
	ORG_INFO_GET,
	ORG_INFO_ERROR,
	WORKSPACES_GET,
	WORKSPACES_ERROR,
	MODULES_GET,
	MODULES_ERROR
} from './types';

import create_query_params from '../utils/create_query_params';

export const get_organizations = () => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/organizations';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORGANIZATIONS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORGANIZATIONS_ERROR,
			payload: { type: 'organizations', msg: err.message }
		})
	})
}

export const get_organization_info = (org_id) => dispatch => {
	// console.log(org_id)
	let url = process.env.REACT_APP_SERVER_URL + `/api/organizations/${org_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORG_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORG_INFO_ERROR,
			payload: { type: 'org_info', msg: err.message }
		})
	})
}

export const get_workspaces = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/organizations/workspaces?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKSPACES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKSPACES_ERROR,
			payload: {type: 'wokspaces', msg: err.message}
		})
	})
}

export const get_modules = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/modules?module_type=2&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MODULES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MODULES_ERROR,
			payload: {type: 'modules', msg: err.message}
		})
	})
}
