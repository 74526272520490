import React from 'react';

// React Bootstrap
import { Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../utils/StatusBadge';
import JobInfo from '../jobs/JobInfo';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { getWorkerSpeedDesc, getWorkerInstanceStatusDesc } from '../../../utils/getStatusDesc';
import { epochToLocalDate } from '../../../utils/dateConverter';

const WorkerInstanceJob = (props) => {
	const { job_worker_instance: { instance, match } = {} } = props;

	if (isEmpty(instance)) {
		return null;
	}

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>{instance?.name}</Card.Title>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{instance?.description}</Card.Subtitle>
				<hr/>
				<Row>
					<Col>
						<p className='text-center m-0 p-0'><strong>Id:</strong> {instance?._id.$oid}</p>
					</Col>
					<Col>
						<p className='text-center m-0 p-0'><strong>Organization:</strong> {instance?.organization === null ? 'None' : instance?.organization.$oid}</p>
					</Col>
				</Row>
				<hr/>

				<Row>
					<Col>
						<p className='text-center m-0 p-0'><strong>Speed:</strong> {getWorkerSpeedDesc(instance?.speed)}</p>
					</Col>
					<Col>
						<p className='text-center m-0 p-0'><strong>Status:</strong> <StatusBadge status={getWorkerInstanceStatusDesc(instance?.status)} /></p>
					</Col>
					<Col>
						<p className='text-center m-0 p-0'><strong>Date:</strong> {epochToLocalDate(instance?.date.$date)}</p>
					</Col>
				</Row>

				<hr/>
				<JobInfo title='Job' jobsType='workers' jobInfo={match} instanceId={instance?._id.$oid} displayLogs />
			</Card.Body>
		</Card>
	);
}

WorkerInstanceJob.propTypes = {
	job_worker_instance: PropTypes.object.isRequired
}
 
export default WorkerInstanceJob;
