import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Table } from 'react-bootstrap';

// Components
import WorkersInstanceData from './WorkerInstData';
import Pagination from '../../utils/Pagination';

import { get_worker_instance_data_history } from '../../../actions/workerInstanceActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { epochToLocalDate } from '../../../utils/dateConverter';

const WorkerInstDataHistory = () => {
	const { instance_id } = useParams();
	
	const dispatch = useDispatch();
	
	const { worker_instance_data_history } = useSelector(state => state.workerInstance);
	
	const [skip, setSkip] = useState(0);
	const [limit] = useState(10);
	
	const nPages = Math.ceil(worker_instance_data_history.count / limit);
	
	const [showData, setShowData] = useState(false);
	const [selectedData, setSelectedData] = useState({});
	
	useEffect(() => {
		dispatch(get_worker_instance_data_history(instance_id, skip, limit));
	}, [skip]);
	
	const selectData = (dataId) => {
		if (!isEmpty(selectedData)) {
			if (dataId !== selectedData._id.$oid) {
				const dataFound = worker_instance_data_history.history.find(data => data._id.$oid === dataId);
				if (!isEmpty(dataFound.data)) {
					setSelectedData(dataFound);
					setShowData(true);
				}
			}
			else {
				setShowData(false);
				setSelectedData({});
			}
		}
		else {
			const dataFound = worker_instance_data_history.history.find(data => data._id.$oid === dataId);
			if (!isEmpty(dataFound.data)) {
				setSelectedData(dataFound);
				setShowData(true);
			}
		}
	}
	
	const reset = () => {
		setShowData(false);
		setSelectedData({});
	}
	
	return (
		<Fragment>
			<Table className='mt-4' responsive>
				<thead style={{backgroundColor: '#e7f1ff'}}>
					<tr>
						<th>Id</th>
						<th>Resource</th>
						<th>Organization</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{worker_instance_data_history.count > 0
						?	worker_instance_data_history.history.map((data) => (
								<Fragment key={data._id.$oid}>
									<tr style={{ cursor: 'pointer' }} onClick={() => selectData(data._id.$oid)}>
										<td>{data._id.$oid}</td>
										<td>{data.resource.$oid ? data.resource.$oid : data.resource._id.$oid}</td>
										<td>{data.organization === null ? 'Null' : data.organization.$oid}</td>
										<td>{epochToLocalDate(data.date.$date)}</td>
									</tr>
									{showData && data._id.$oid === selectedData._id.$oid
										?	<tr>
												<td colSpan={4}>
													{Object.keys(data.data).map((key, idx) => (
														<WorkersInstanceData key={idx} title={key} data={data.data[key]} />
													))}
												</td>
											</tr>
										: null
									}
								</Fragment>
							))
						:	<tr>
								<td className='text-center' colSpan={4}>No data history</td>
							</tr>
					}
				</tbody>
			</Table>
			<Pagination
				count={worker_instance_data_history.count}
				arrayLength={worker_instance_data_history.history.length}
				nPages={nPages}
				skip={skip}
				setSkip={setSkip}
				limit={limit}
				reset={reset}
			/>
		</Fragment>
	);
}

export default WorkerInstDataHistory;
