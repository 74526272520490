import React from 'react';

// React Bootstrap
import { Container, Col } from 'react-bootstrap';	

// Packages
import PropTypes from 'prop-types';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getLogTypeDesc } from '../../../utils/getStatusDesc';

const LogType = (props) => {
	const { type, date } = props;

	const setTextColor = (type) => {
		let color = '';

		switch (type) {
			case 0:		color = '#adb5bd';	break;
			case 1:		color = '#e231c8';	break;
			case 2:		color = '#0d6efd';	break;
			case 3:		color = '#ffc107';	break;
			case 4:		color = '#e53935';	break;
			case 5:		color = '#b71c1c';	break;
			default:	color = '#adb5bd';	break;
		}

		return color;
	}

	return ( 
		<p style={{ fontSize: '16px', color: `${setTextColor(type)}`}}>
			&#91; {getLogTypeDesc(type)} &#93; {epochToLocalDate(date * 1000)}
		</p>
	);
}

LogType.propTypes = {
	type: PropTypes.number.isRequired,
	date: PropTypes.number.isRequired
}

const LogsTerminal = (props) => {
	const { logs } = props;

	return (
		<Container className='terminal-container'>
			<Container className='wrapper-container px-0'>
				<Col className='title mt-2'>
					<h5 style={{ textAlign: 'center', color: '#a2a2a2' }}><pre>percepthor@linux</pre></h5>
				</Col>
				<Col className='icons-btns mt-2 px-0'>
					<Container className='d-flex align-items-center justify-content-end'>
						<i className='bi bi-circle-fill mx-1' style={{ color: '#efcf84' }}></i>
						<i className='bi bi-circle-fill mx-1' style={{ color: '#94c68b' }}></i>
						<i className='bi bi-circle-fill mx-1' style={{ color: '#cf5062' }}></i>
					</Container>
				</Col>
			</Container>
			<Container className='terminal terminal-scrollbar'>
				{logs.map((log, idx) => (
					<div key={idx} >
						<LogType
							type={log.type === undefined ? 0 : log.type}
							date={log.date === undefined ? 0 : log.date}
						/>
						<pre style={{ color: '#eeeeee', width: 'fit-content' }}>{log?.message}</pre>
					</div>
				))}
			</Container>
		</Container>
	)
}

LogsTerminal.propTypes = {
	logs: PropTypes.array.isRequired
}

export default LogsTerminal;
