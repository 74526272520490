import React from 'react';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

const HelperInstData = (props) => {
	const { data } = props;

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>Information</Card.Title>
				<hr/>
				<Container className='p-0'>
					<Row>
						<Col className='text-center'><strong>Num. of processes:</strong> {data.n_processes}</Col>
						<Col className='text-center'><strong>Last process time:</strong> {data.last_process_time}</Col>
					</Row>
					<hr/>
					<Row>
						<Col>
							<p className='text-center m-0 p-0'><strong>Min process time:</strong> {data.min_process_time}</p>
							<p className='text-center m-0 p-0'><strong>Max process time:</strong> {data.max_process_time}</p>
						</Col>
						<Col>
							<p className='text-center m-0 p-0'><strong>Sum process times:</strong> {data.sum_process_times}</p>
							<p className='text-center m-0 p-0'><strong>Average process time:</strong> {data.average_process_time}</p>
						</Col>
					</Row>
				</Container>
			</Card.Body>
		</Card>
	);
}

HelperInstData.propTypes = {
	data: PropTypes.object.isRequired
}

export default HelperInstData;
