import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

// Components
import ToastAlert from '../../../utils/ToastAlert';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import Model from './Model';
import SelectButton from '../../../utils/SelectButton';
import ModelForm from './ModelForm';

// Actions
import { get_organizations, get_workspaces, get_modules } from '../../../../actions/organizationActions';
import { get_all_models } from '../../../../actions/modelsActions';

const Models = () => {
	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { organizations, workspaces, modules } = useSelector(state => state.organization);

	const { models_loading, all_models } = useSelector(state => state.models);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });

	const [allModels, setAllModels] = useState([]);
	const [modelSearch, setModelSearch] = useState('');
	const [modalShow, setModalShow] = useState(false);
	const [update, setUpdate] = useState(false);
	const [modelToUpdate, setModelToUpdate] = useState({});

	useEffect(() => {
		dispatch(get_organizations());
		dispatch(get_all_models());
	}, []);

	useEffect(() => {
		setAllModels(all_models.models);
	}, [all_models]);

	useEffect(() => {
		const modelsFound = all_models.models.filter(model => model.name.toLowerCase().includes(modelSearch.toLowerCase()));
		setAllModels(modelsFound);
	}, [modelSearch]);

	useEffect(() => {
		let _organization = '';

		if (organizations.length <= 1) {
			_organization = user.organization;
		}
		else {
			_organization = localStorage.getItem('organization');
		}
		
		setOrgFilters({
			...orgFilters,
			organization: _organization,
			workspace: localStorage.getItem('workspace') || '',
			module: localStorage.getItem('module') || ''
		});
	}, [organizations]);

	useEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
		}
	}, [orgFilters.organization]);

	useEffect(() => {
		if (orgFilters.workspace !== '') {
			dispatch(get_modules({ organization: orgFilters.organization }));
		}
	}, [orgFilters.organization]);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
			localStorage.setItem(key, '');
		}

		if (e !== null){
			filters[name] = e.value;
			localStorage.setItem(name, e.value);
		}

		setOrgFilters(filters);
	}

	const onHide = () => {
		setModalShow(false);
		setUpdate(false);
		setModelToUpdate({});
	}

	const createModel = () => {
		setModalShow(true);
		setUpdate(false);
		setModelToUpdate({});
	}

	const updateModel = (model) => {
		setModalShow(true);
		setUpdate(true);
		setModelToUpdate(model);
	}

	return (
		<Container className='mt-3 pb-5'>
			<ToastAlert />

			<ModelForm
				show={modalShow}
				onHide={onHide}
				orgFilters={orgFilters}
				update={update}
				modelToUpdate={modelToUpdate}
				single={false}
			/>

			<Container className='wrapper-container px-0 mb-3'>
				<Col className='title'>
					<h3 className='text-center'>Models</h3>
				</Col>
				<Col className='icons-btns'>
					<Container className='d-flex align-items-center justify-content-end px-0'>
						<Button className='submit-btn' onClick={createModel}>
							<strong>Create</strong>
						</Button>
					</Container>
				</Col>
			</Container>

			<Row className='mb-3'>
				<Col>
					<p className='text-center mb-2'>Organization</p>
					<SelectButton
						options={organizations}
						name='organization'
						value={orgFilters.organization ? orgFilters.organization : null}
						onChange={onChangeOrgFilters}
					/>
					</Col>
				<Col>
					<p className='text-center mb-2'>Workspace</p>
					<SelectButton
						options={workspaces}
						name='workspace'
						value={orgFilters.workspace ? orgFilters.workspace : null}
						onChange={onChangeOrgFilters}
					/>
				</Col>
				<Col>
					<p className='text-center mb-2'>Module</p>
					<SelectButton
						options={modules}
						name='module'
						value={orgFilters.module ? orgFilters.module : null}
						onChange={onChangeOrgFilters}
					/>
				</Col>
			</Row>

			<Form onSubmit={(e) => e.preventDefault()}>
				<Form.Group as={Row} className='mb-3' controlId='modelName'>
					<Form.Label column sm='2' className='text-center'>
						<i className='bi bi-search me-2'></i> Model Name
					</Form.Label>
					<Col sm='10'>
						<Form.Control
							type='text'
							placeholder='Type model name'
							onChange={(e) => setModelSearch(e.target.value)}
						/>
					</Col>
				</Form.Group>
			</Form>
			<p className='text-muted'>Total models: {all_models.count}</p>
			
			<Container className='custom-container'>
				{models_loading
					?	<LoadingSpinner loadingMsg='Loading models' />
					:	<Container>
							{allModels.length > 0
								?	allModels.map((model, idx) => (
										<Model
											key={idx}
											model={model}
											updateModel={updateModel}
										/>
									))
								: modelSearch !== ''
									?	<p className='text-center'>No matches</p>
									: <p className='text-center'>No models found</p>
							}
						</Container>
				}
			</Container>
		</Container>
	);
}

export default Models;
