import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Card, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../utils/LoadingSpinner';
import HelperInstance from '../helpers/HelperInstance';
import Error from '../../utils/Error';
import HelperInstConfig from './HelperInstConfig';
import { ActionButtons, ActionButton } from '../../utils/ActionButtons';
import StatusHistory from './StatusHistory';
import HelperInstData from './HelperInstData';
import DataHistory from './DataHistory';
import ToastAlert from '../../utils/ToastAlert';
import HelperInstanceJobs from './HelperInstanceJobs';
import WorkerInstCleanModal from '../workersInstance/WorkerInstCleanModal';
import Commands from '../commands/CommandsTable';

// Actions
import { get_helper_info } from '../../../actions/helpersActions';
import {
	get_helper_instance_info, get_helper_instance_data, get_helper_instance_config,
	helper_instance_start, helper_instance_stop, helper_instance_end, helper_instance_clean,
	get_helper_instance_commands
} from '../../../actions/helperInstanceActions';
import { clear_job } from '../../../actions/jobsActions';

// Utils
import errorExists from '../../../utils/errorExists';
import isEmpty from '../../../utils/isEmpty';

const HelperInstInfo = () => {
	const { helper_id, resource_id, instance_id } = useParams();

	const dispatch = useDispatch();

	const { helper_info } = useSelector(state => state.helpers);

	const { helper_instance_loading, helper_instance, helper_instance_commands_loading, helper_instance_commands, helper_instance_errors } = useSelector(state => state.helperInstance);

	const [showCleanJobsModal, setShowCleanJobsModal] = useState(false);
	const [pageTitle, setPageTitle] = useState(process.env.REACT_APP_NAME);

	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(9);
	const [limit] = useState(10);

	useEffect(() => {
		dispatch(get_helper_info(helper_id));
		dispatch(get_helper_instance_info(instance_id));
		dispatch(get_helper_instance_config(instance_id));
		dispatch(get_helper_instance_data(instance_id));
		
		dispatch(clear_job());
	}, []);

	useEffect(() => {
		const commandsFilters = { sort: true, skip: start, limit: limit };
		dispatch(get_helper_instance_commands(instance_id, commandsFilters));
	}, [start]);

	useEffect(() => {
		if (!isEmpty(helper_info)) {
			setPageTitle(`${process.env.REACT_APP_NAME} - ${helper_info?.name}`);
		}
	}, [helper_info]);

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	if (helper_instance_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading helper info' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container' fluid>
				<ToastAlert/>

				<WorkerInstCleanModal
					jobsType='helper'
					show={showCleanJobsModal}
					onHide={() => setShowCleanJobsModal(false)}
					actionFunction={(jobsToClean, closeModalRef) => dispatch(helper_instance_clean(instance_id, jobsToClean, closeModalRef))}
				/>
				
				<Row>
					<Col className='text-start'>
						<Card.Link style={{ textDecoration: 'none' }} href={`/helper/${helper_id}/resource/${resource_id}`}>
							<i className='bi bi-chevron-left ms-1 me-2'></i> Return to {helper_info.name}
						</Card.Link>
					</Col>
					<Col xs={6}><h2 className='text-center'>Helper Instance</h2></Col>
					<Col></Col>
				</Row>
				<Container>

				<HelperInstance helperInstance={helper_instance.info} showLink={false} />

				<hr/>
				<h3 className='text-center pt-3'>Helper Configuration</h3>
				{errorExists(helper_instance_errors, 'helper_instance_config')
					?	<Error type='warning' msg={helper_instance_errors['helper_instance_config'].msg} />
					:	<HelperInstConfig config={helper_instance.config} />
				}

				<hr/>
				<h3 className='text-center pt-3'>Helper Instance Status</h3>
				<Tabs
					defaultActiveKey='actions'
					id='justify-tab-example'
					className='mt-3'
					justify
				>
					<Tab eventKey='actions' title='Actions'>
						<Container>
							<Row>
								<Col xs={9}>
									<ActionButtons
										isHelper={true}
										startFunction={() => dispatch(helper_instance_start(instance_id))}
										stopFunction={() => dispatch(helper_instance_stop(instance_id))}
										endFunction={() => dispatch(helper_instance_end(instance_id))}
									/>
								</Col>
								<Col className='text-center mt-3' xs={3}>
									<ActionButton
										title='Clean worker'
										variant='#fd7e14'
										icon='eraser'
										actionFunction={() => setShowCleanJobsModal(true)}
									/>
								</Col>
							</Row>
						</Container>
					</Tab>
					<Tab eventKey='history' title='History'>
						<StatusHistory />
					</Tab>
				</Tabs>

				<hr/>
				<h2 className='text-center'>Data</h2>
				<Tabs
					defaultActiveKey='current'
					id='justify-tab-example'
					className='mt-3'
					justify
				>
					<Tab eventKey='current' title='Current'>
						<Container className='p-0 mt-4'>
							<HelperInstData data={helper_instance.data} />
						</Container>
					</Tab>
					<Tab eventKey='history' title='History'>
						<DataHistory />
					</Tab>
				</Tabs>

				<hr/>
				<h2 className='text-center'>Commands</h2>
				<Commands
					tableLoading={helper_instance_commands_loading}
					dataCount={helper_instance_commands.count}
					tableData={helper_instance_commands.commands}
					start={start}
					setStart={setStart}
					end={end}
					setEnd={setEnd}
					limit={limit}
				/>

				<hr/>
				<h2 className='text-center'>Jobs</h2>
				<HelperInstanceJobs jobsType='helpers' />

				</Container>
			</Container>
		);
	}
}

export default HelperInstInfo;
