import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../utils/LoadingSpinner';
import StatusBadge from '../../utils/StatusBadge';
import WorkerResource from '../workers/WorkerResource';
import { ActionButtons } from '../../utils/ActionButtons';
import WorkerActionModal from '../workers/WorkerActionModal';
import ConfirmDialog from '../../utils/ConfirmDialog';
import SelectButton from '../../utils/SelectButton';
import HelperInstance from './HelperInstance';
import Error from '../../utils/Error';
import Commands from '../commands/CommandsTable';

// Hooks
import useDidMountEffect from '../../../hooks/useDidMountEffect';

// Actions
import { get_organizations } from '../../../actions/organizationActions';
import { get_helper_commands, get_helper_info, get_helper_instances, helper_end, helper_start, helper_stop } from '../../../actions/helpersActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getWorkerStateDesc, getWorkerTypeDesc } from '../../../utils/getStatusDesc';

const HelperInfo = () => {
	const { helper_id, resource_id } = useParams();

	const dispatch = useDispatch();

	const { organizations } = useSelector(state => state.organization);

	const { helpers_loading, helper_info, helper_commands_loading, helper_commands, helper_instances_loading, helper_instances } = useSelector(state => state.helpers);

	const [modalShow, setModalShow] = useState(false);
	const [modalInfo, setModalInfo] = useState({ title: '', variant: '', actionFunction: null });

	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
	const [confirmFunction, setConfirmFunction] = useState(null);

	const [pageTitle, setPageTitle] = useState(process.env.REACT_APP_NAME);

	const [organization, setOrganization] = useState('');

	const [instancesStats, setInstancesStats] = useState({ total: 0, available: 0, working: 0, stopped: 0, ended: 0 });

	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(9);
	const [limit] = useState(10);

	useEffect(() => {
		dispatch(get_organizations());
		
		dispatch(get_helper_info(helper_id));
		dispatch(get_helper_instances({ helper: helper_id, resource: resource_id }));
	}, []);

	useEffect(() => {
		const commandsFilters = { helper: helper_id, sort: true, skip: start, limit: limit };
		dispatch(get_helper_commands(commandsFilters));
	}, [start]);

	useEffect(() => {
		if (!isEmpty(helper_info)) {
			setPageTitle(`${process.env.REACT_APP_NAME} - ${helper_info?.name}`);
		}
	}, [helper_info]);

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	const setSuccessModal = () => {
		setModalShow(true);
		setModalInfo({
			title: 'Start helper', variant: 'success', actionFunction: helper_start
		});
	}

	const setStopModal = () => {
		setModalShow(true);
		setModalInfo({
			title: 'Stop helper', variant: 'primary', actionFunction: helper_stop
		});
	}

	const setEndModal = () => {
		setModalShow(true);
		setModalInfo({
			title: 'End helper', variant: 'danger', actionFunction: helper_end
		});
	}

	useDidMountEffect(() => {
		if (organization !== '') {
			let helperFilters = { helper: helper_id, resource: resource_id, organization: organization };
	
			dispatch(get_helper_instances(helperFilters));
		}
	}, [organization]);

	useEffect(() => {
		if (helper_instances?.instances?.length > 0) {
			const total = helper_instances?.instances?.filter(inst => !isEmpty(inst));
			const available = helper_instances?.instances?.filter(inst => inst?.state === '1');
			const working = helper_instances?.instances?.filter(inst => inst?.state === '2');
			const stopped = helper_instances?.instances?.filter(inst => inst?.state === '3');
			const ended = helper_instances?.instances?.filter(inst => inst?.state === '4');

			setInstancesStats({ total: total?.length, available: available?.length, working: working?.length, stopped: stopped?.length, ended: ended?.length });
		}
	}, [helper_instances]);

	if (helpers_loading && helper_instances_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading helper info' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container'>
				<ConfirmDialog 
					show={showConfirmDialog}
					confirmDialogTitle={confirmDialogTitle}
					onHide={() => setShowConfirmDialog(false)}
					confirmFunction={() => dispatch(confirmFunction)}
				/>

				<h2 className='text-center'>{helper_info.name}</h2>
				<p className='text-center text-muted'>{helper_info.description}</p>

				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Card.Title className='text-center' as='h5'>Helper Information</Card.Title>
						<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {helper_info._id?.$oid}</Card.Subtitle>
						<hr/>						
						<Container className='p-0'>
							<Row>
								<Col className='text-center'><strong>Alias:</strong> {helper_info.alias}</Col>
								<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(helper_info.date?.$date)}</Col>
							</Row>
							<hr/>
							<Row>
								<Col className='text-center'><strong>Status:</strong> <StatusBadge status={getWorkerStateDesc(helper_info.status)} /></Col>
								<Col className='text-center'><strong>Helper type:</strong> {getWorkerTypeDesc(helper_info.helper_type)}</Col>
							</Row>
						</Container>

						<WorkerResource
							title='Helper Resource'
							workerResource={helper_info.resource !== undefined ? helper_info.resource : {}}
						/>
					</Card.Body>
				</Card>

				{helper_instances.count > 0
					?	<Fragment>
							<hr/>
							<h3 className='text-center pt-3'>Helper Actions</h3>
							<ActionButtons
								isHelper={true}
								startFunction={setSuccessModal}
								stopFunction={setStopModal}
								endFunction={setEndModal}
							/>

							<WorkerActionModal
								isHelper={true}
								show={modalShow}
								onHide={() => setModalShow(false)}
								modalInfo={modalInfo}
								workerId={helper_id}
								resourceId={resource_id}
								confirmDialogHandlers={{
									setShowConfirmDialog: setShowConfirmDialog,
									setConfirmDialogTitle: setConfirmDialogTitle,
									setConfirmFunction: setConfirmFunction
								}}
							/>
						</Fragment>
					:	null
				}

				<hr className='mt-4'/>
				<h3 className='text-center'>Helper Commands</h3>
				<Commands
					tableLoading={helper_commands_loading}
					dataCount={helper_commands.count}
					tableData={helper_commands.commands}
					start={start}
					setStart={setStart}
					end={end}
					setEnd={setEnd}
					limit={limit}
				/>
				
				<hr className='mt-4'/>
				<h3 className='text-center pt-3'>Helper Instances</h3>
				<p className='text-center pt-3'><i className='bi bi-filter ms-1 me-2'></i>Filters</p>
				<Row className='mb-3'>
					<Col>
						<Row className='align-items-center'>
							<Col className='text-center' lg='2'>Organization</Col>
							<Col>
								<SelectButton
									options={organizations}
									name='organization'
									value={organization}
									onChange={(e) => setOrganization(e !== null ? e.value : '')}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				{helper_instances.count > 0
					?	<Fragment>
							{helper_instances_loading
								? <Container className='d-flex align-items-center justify-content-center' style={{ height: '533px' }}>
										<p className='spinner-text'>Loading</p>
										<div className='spinner-margin'>
											<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
										</div>
									</Container>
								:	<Fragment>
										<p className='text-center pt-3'><i className='bi bi-bar-chart-line ms-1 me-2'></i>Stats</p>
										<p className='text-center'>
											<span className='fw-bold'>Total:</span> {instancesStats.total}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Available:</span> {instancesStats.available}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Working:</span> {instancesStats.working}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Stopped:</span> {instancesStats.stopped}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Ended:</span> {instancesStats.ended}
										</p>
										{helper_instances.instances.map((helperInstance, idx) => (
											!isEmpty(helperInstance) &&
												<HelperInstance 
													key={idx}
													helperInstance={helperInstance}
													showLink={true}
													helperId={helper_info._id?.$oid}
													resourceId={helper_info.resource?._id?.$oid}
												/>
										))}
									</Fragment>	
							}
						</Fragment>
					:	<Error type='warning' msg='No worker instances found.' />
				}
			</Container>
		);
	}
}

export default HelperInfo;
