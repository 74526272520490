import React from 'react';
import { Link } from 'react-router-dom';

// Packages
import { Container, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../../utils/StatusBadge';
import TablePagination from '../../../utils/TablePagination';

// Utils
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getJobsStatusDesc, getRequestMethodDesc } from '../../../../utils/getStatusDesc';

const RequestsTable = (props) => {
	const { tableLoading, dataCount, tableData, start, setStart, end, setEnd, limit } = props;

	const nPages = Math.ceil(dataCount / limit);

	return (
		<div style={{ marginTop: '2rem' }}>
			<Table responsive>
				<thead style={{backgroundColor: '#e7f1ff'}}>
					<tr>
						<th>Id</th>
						<th>User</th>
						<th>Status</th>
						<th>Method</th>
						<th>Path</th>
						<th>Date</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{tableLoading 
						? <tr>
								<td className='text-center' colSpan={9}>
									<Container className='d-flex align-items-center justify-content-center' style={{ height: '5rem' }}>
										<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
									</Container>
								</td>
							</tr>
						: tableData.length > 0
							?	tableData.map((req) => (
								<tr key={req?._id?.$oid}>
									<td>{req?._id?.$oid}</td>
									<td>{req?.auth?.user?.$oid}</td>
									<td><StatusBadge status={getJobsStatusDesc(req?.status)} /></td>
									<td>{getRequestMethodDesc(req?.path?.method)}</td>
									<td>{req?.path?.url}</td>
									<td>{epochToLocalDate(req?.date?.$date)}</td>
									<td>
										<Link
											style={{ textDecoration: 'none' }}
											to={`/requests/${req?._id?.$oid}/details`}
											target='_blanck'
											rel='noopener noreferrer'
										>
											More info
										</Link>
									</td>
								</tr>
							))
							:	<tr>
									<td className='text-center' colSpan={9}>No requests found</td>
								</tr>
					}
				</tbody>
			</Table>
			<TablePagination
				count={dataCount}
				arrayLength={tableData.length}
				nPages={nPages}
				start={start}
				setStart={setStart}
				end={end}
				setEnd={setEnd}
				limit={limit}
			/>
		</div>
	)
}

RequestsTable.propTypes = {
	tableLoading: PropTypes.bool.isRequired,
	dataCount: PropTypes.number.isRequired,
	tableData: PropTypes.array.isRequired,
	start: PropTypes.number.isRequired,
	setStart: PropTypes.func.isRequired,
	end: PropTypes.number.isRequired,
	setEnd: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
}

export default RequestsTable;
