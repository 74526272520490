import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Button, Card, Col, Container, Form, InputGroup, Row, Tab, Tabs } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../utils/LoadingSpinner';
import WorkerInstance from '../workers/WorkerInstance';
import WorkersInstanceData from './WorkerInstData';
import JobsWaiting from '../jobs/JobsWaiting';
import JobCurrentLast from '../jobs/JobCurrentLast';
import JobsFailed from '../jobs/JobsFailed';
import JobsHistory from '../jobs/JobsHistory';
import { ActionButtons, ActionButton } from '../../utils/ActionButtons';
import ToastAlert from '../../utils/ToastAlert';
import JobInfo from '../jobs/JobInfo';
import Error from '../../utils/Error';
import WorkerInstHistory from './WorkerInstHistory';
import WorkerInstDataHistory from './WorkerInstDataHistory';
import RemovedJobModal from '../jobs/RemovedJobModal';
import WorkerInstConfig from './WorkerInstConfig';
import JobsSearch from '../jobs/JobsSearch';
import WorkerInstCleanModal from './WorkerInstCleanModal';
import WorkerJobCreate from '../workers/WorkerJobCreate';
import Stopwatch from '../../utils/StopWatch';
import Commands from '../commands/CommandsTable';

// Actions
import { get_worker_events, get_worker_info } from '../../../actions/workersActions';
import { 
	get_worker_instance_info, get_worker_instance_data, get_worker_instance_config,
	worker_instance_start, worker_instance_stop, worker_instance_end, worker_instance_clean,
	get_worker_instance_commands
} from '../../../actions/workerInstanceActions';
import { clear_job, get_job_info } from '../../../actions/jobsActions';

// Utils
import errorExists from '../../../utils/errorExists';
import isEmpty from '../../../utils/isEmpty';

const WorkerInstInfo = () => {
	const { worker_id, resource_id, instance_id } = useParams();

	const dispatch = useDispatch();

	const { worker_info } = useSelector(state => state.workers);

	const { worker_instance_loading, worker_instance, worker_instance_commands_loading, worker_instance_commands, worker_instance_errors } = useSelector(state => state.workerInstance);

	const { searching_jobs, jobs_found, job_info, jobs_errors } = useSelector(state => state.jobs);

	const [showCleanJobsModal, setShowCleanJobsModal] = useState(false);
	const [createJobShow, setCreateJobShow] = useState(false);
	const [searchBy, setSearchBy] = useState('');
	const [jobId, setJobId] = useState('');
	const [pageTitle, setPageTitle] = useState(process.env.REACT_APP_NAME);

	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(9);
	const [limit] = useState(10);

	useEffect(() => {
		dispatch(get_worker_info(worker_id));
		dispatch(get_worker_instance_info(instance_id));
		dispatch(get_worker_instance_data(instance_id));
		dispatch(get_worker_instance_config(instance_id));

		dispatch(get_worker_events(worker_id));
		
		dispatch(clear_job());
	}, []);

	useEffect(() => {
		const commandsFilters = { sort: true, skip: start, limit: limit };
		dispatch(get_worker_instance_commands(instance_id, commandsFilters));
	}, [start]);

	useEffect(() => {
		if (!isEmpty(worker_info)) {
			setPageTitle(`${process.env.REACT_APP_NAME} - ${worker_info?.name}`);
		}
	}, [worker_info]);

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	if (worker_instance_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading worker info' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container' fluid>
				<ToastAlert/>

				<RemovedJobModal />

				<WorkerInstCleanModal
					jobsType='worker'
					show={showCleanJobsModal}
					onHide={() => setShowCleanJobsModal(false)}
					actionFunction={(jobsToClean, closeModalRef) => dispatch(worker_instance_clean(instance_id, jobsToClean, closeModalRef))}
				/>

				<WorkerJobCreate
					show={createJobShow}
					onHide={() => setCreateJobShow(false)}
					resource={worker_id}
					instanceInfo={worker_instance.info}
				/>

				
				<Row>
					<Col className='text-start'>
						<Card.Link style={{ textDecoration: 'none' }} href={`/worker/${worker_id}/resource/${resource_id}`}>
							<i className='bi bi-chevron-left ms-1 me-2'></i> Return to {worker_info.name}
						</Card.Link>
					</Col>
					<Col xs={6}><h2 className='text-center'>Worker Instance</h2></Col>
					<Col></Col>
				</Row>
				<Container>
				<WorkerInstance workerInstance={worker_instance.info} showLink={false} />

				<hr/>
				<h3 className='text-center pt-3'>Worker Configuration</h3>
				{errorExists(worker_instance_errors, 'worker_instance_config')
					?	<Error type='warning' msg={worker_instance_errors['worker_instance_config'].msg} />
					:	<WorkerInstConfig config={worker_instance.config} />
				}

				<hr/>
				<h3 className='text-center pt-3'>Worker Instance Status</h3>
				<Tabs
					defaultActiveKey='actions'
					id='justify-tab-example'
					className='mt-3'
					justify
				>
					<Tab eventKey='actions' title='Actions'>
						<Container>
							<Row>
								<Col xs={9}>
									<ActionButtons
										isHelper={false}
										startFunction={() => dispatch(worker_instance_start(instance_id))}
										stopFunction={() => dispatch(worker_instance_stop(instance_id))}
										endFunction={() => dispatch(worker_instance_end(instance_id))}
									/>
								</Col>
								<Col className='text-center mt-3' xs={3}>
									<ActionButton
										title='Clean worker'
										variant='#fd7e14'
										icon='eraser'
										actionFunction={() => setShowCleanJobsModal(true)}
									/>
								</Col>
							</Row>
						</Container>
					</Tab>
					<Tab eventKey='history' title='History'>
						<WorkerInstHistory />
					</Tab>
				</Tabs>

				<hr/>
				<h2 className='text-center'>Data</h2>
				<Tabs
					defaultActiveKey='current'
					id='justify-tab-example'
					className='mt-3'
					justify
				>
					<Tab eventKey='current' title='Current'>
						<Container className='p-0 mt-4'>
							{Object.keys(worker_instance.data).map((key, idx) => (
								<WorkersInstanceData key={idx} title={key} data={worker_instance.data[key]} />
							))}
						</Container>
					</Tab>
					<Tab eventKey='history' title='History'>
						<WorkerInstDataHistory />
					</Tab>
				</Tabs>

				<hr/>
				<h2 className='text-center'>Commands</h2>
				<Commands
					tableLoading={worker_instance_commands_loading}
					dataCount={worker_instance_commands.count}
					tableData={worker_instance_commands.commands}
					start={start}
					setStart={setStart}
					end={end}
					setEnd={setEnd}
					limit={limit}
				/>

				<hr/>
				<h2 className='text-center'>Jobs</h2>
				<Row className='text-center mt-3'>
					<Col>
						<Button className='submit-btn' onClick={() => { setCreateJobShow(true); }}>
							<i className='bi bi-plus-circle me-2'></i> Create Job
						</Button>
					</Col>
					<Col>
						<Button style={{ backgroundColor: '#712cf9', border: '#712cf9'}} onClick={() => setSearchBy(prevState => prevState === 'id' ? '' : 'id')}>
							<i className='bi bi-fingerprint me-2'></i> Search Job ID
						</Button>
					</Col>
					<Col>
						<Button variant='secondary' onClick={() => setSearchBy(prevState => prevState === 'data' ? '' : 'data')}>
							<i className='bi bi-search me-2'></i> Search Job Data
						</Button>
					</Col>
				</Row>
				{searchBy === 'data' &&
					<div className='mt-3'>
						<JobsSearch
							instance={true}
							resource={instance_id}
						/>
						<div className='mt-4'>
							{!searching_jobs
								?	errorExists(jobs_errors, 'search_job_by_data')
									?	<Error title='Search Jobs' type='danger' msg={jobs_errors['search_job_by_data'].msg} />
									:	jobs_found.length > 0 &&
										<Card className='shadow mb-5 bg-body rounded card-border'>
											<Card.Body>
												<Card.Title className='text-center' as='h5'>Jobs Found</Card.Title>
												<hr/>
												<Row>
													{jobs_found.map((job, idx) => (
														<Col key={idx} xs={jobs_found?.length > 1 ? 6 : 12}>
															<JobInfo title='Job' jobsType='workers' jobInfo={job} displayLogs />
														</Col>
													))}
												</Row>
											</Card.Body>
										</Card>
								:	null
							}
						</div>
					</div>
				}
				{searchBy === 'id' &&
					<div className='mt-3'>
						<p className='text-center pt-3'><i className='bi bi-search me-2'></i>Search Job by Id</p>
						<InputGroup className='mb-3'>
							<InputGroup.Text id='basic-addon1'>Job ID</InputGroup.Text>
							<Form.Control
								placeholder='Type job id'
								aria-label='Type job id'
								aria-describedby='basic-addon2'
								onChange={(e) => setJobId(e.target.value)}
							/>
							<Button 
								style={{ backgroundColor: '#712cf9', border: '#712cf9'}} id='search-job' 
								onClick={() => dispatch(get_job_info('workers', instance_id, jobId))}
							>
								<i className='bi bi-search me-2'></i> Search
							</Button>
						</InputGroup>
						{errorExists(jobs_errors, 'get_job_info')
							?	<Error title='Job Info' type='danger' msg={jobs_errors['get_job_info'].msg} />
							:	<JobInfo title='Job Info' jobsType='workers' jobInfo={job_info} instanceId={instance_id} displayLogs />
						}
					</div>
				}

				<Stopwatch />

				<JobsWaiting jobsType='workers'/>

				<JobCurrentLast jobsType='workers' />

				<JobsHistory jobsType='workers'/>

				<JobsFailed jobsType='workers'/>
				</Container>
			</Container>
		);
	}
}

export default WorkerInstInfo;
