import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Button, Card, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import ConfirmDialog from '../../../utils/ConfirmDialog';
import StatusBadge from '../../../utils/StatusBadge';
import StatusUpdate from '../../../utils/StatusUpdate';

// Actions
import { get_version_status_history, version_delete, version_status_update } from '../../../../actions/modelsActions';
import { getModelStatusDesc } from '../../../../utils/getStatusDesc';

const Version = (props) => {
	const { version, updateVersion } = props;

	const dispatch = useDispatch();

	const { model_status_history_loading, version_status_history, versions_errors } = useSelector(state => state.models);

	const [confirmDialog, setConfirmDialog] = useState({ show: false, title: '', function: null });
	const [statusUpdateModal, setStatusUpdateModal] = useState(false);

	const deleteVersion = () => {
		setConfirmDialog({
			show: true,
			title: 'delete this version',
			function: () => dispatch(version_delete(version._id.$oid, { model: version.model.$oid }))
		});
	}

	const updateVersionStatus = () => {
		setStatusUpdateModal(true);
		dispatch(get_version_status_history(version._id.$oid));
	}

	return (
		<div>
			<ConfirmDialog 
				show={confirmDialog.show}
				confirmDialogTitle={confirmDialog.title}
				onHide={() => setConfirmDialog({ ...confirmDialog, show: false })}
				confirmFunction={confirmDialog.function}
			/>

			<StatusUpdate
				show={statusUpdateModal}
				onHide={() => setStatusUpdateModal(false)}
				loading={model_status_history_loading}
				statusHistory={version_status_history}
				statusDescriptions={(status) => getModelStatusDesc(status)}
				statusQty={9}
				changeStatus={(status) => dispatch(version_status_update(version._id.$oid, status, version.model.$oid))}
			/>

			<Card className='shadow mb-4 bg-body rounded card-border'>
				<Container>
					<Row>
						<Col>
							<Card.Body className='ps-0'>
								<Card.Title as='h6'>{version.name}</Card.Title>
								<Card.Subtitle className='mb-2 text-muted' as='p'>{version.description}</Card.Subtitle>
								<Card.Text>
									<strong>Id:</strong> {version._id.$oid}
								</Card.Text>
							</Card.Body>
						</Col>
						<Col className='d-flex align-items-center justify-content-between' md={4}>
							<Button className='jobs-actions' onClick={updateVersionStatus}>
								<StatusBadge status={getModelStatusDesc(version.status)} />
							</Button>
						</Col>
						<Col className='d-flex align-items-center justify-content-between' md='auto'>
							<div className='pe-4'>
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip id='button-tooltip-2'>Edit</Tooltip>}
								>
									<Button type='button' className='jobs-actions me-2' onClick={() => updateVersion(version)}>
										<i className='bi bi-pencil-fill fs-5'></i>
									</Button>
								</OverlayTrigger>
							</div>
							<div>
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip id='button-tooltip-2'>Delete</Tooltip>}
								>
									<Button type='button' className='jobs-actions me-2' onClick={deleteVersion}>
										<i className='bi bi-trash-fill fs-5'></i>
									</Button>
								</OverlayTrigger>
							</div>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
}

Version.propTypes = {
	version: PropTypes.object.isRequired,
	updateVersion: PropTypes.func.isRequired
}

export default Version;
