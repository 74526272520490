import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

// React Bootstrap
import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../utils/StatusBadge';
import JobData from './JobData';

// Actions
import { retry_job } from '../../../actions/jobsActions'; 

// Utils
import isEmpty from '../../../utils/isEmpty';
import { getJobsStatusDesc } from '../../../utils/getStatusDesc';
import { epochToLocalDate } from '../../../utils/dateConverter';
import findWorkerEvent from '../../../utils/findWorkerEvent';

const JobInfo = (props) => {
	const { title, jobsType, jobInfo, instanceId, displayLogs } = props;

	const { worker_id, helper_id, instance_id } = useParams();

	const dispatch = useDispatch();

	const { worker_events } = useSelector(state => state.workers);

	const setLogsLink = (jobInfo) => {
		let logsLink = '';

		if (jobsType === 'workers') {
			logsLink = `/worker/${worker_id}/instance/${instance_id ? instance_id : instanceId}/job/${jobInfo?.header?.id}`;
		}
		else if (jobsType === 'helpers') {
			logsLink = `/helper/${helper_id}/instance/${instance_id ? instance_id : instanceId}/job/${jobInfo?.header?.id}`;
		}

		return logsLink;
	}

	if (isEmpty(jobInfo)) {
		return null;
	}
	else {
		return (
			<Card className='shadow mb-5 bg-body rounded card-border'>
				<Card.Body>
					<Container className='wrapper-container px-0'>
						<Col className='title'>
							<Card.Title className='text-center' as='h5'>{title}</Card.Title>
							<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {jobInfo.header.id}</Card.Subtitle>
						</Col>
						<Col className='icons-btns'>
							<Container className='d-flex align-items-center justify-content-end px-0'>
								{jobsType === 'workers' &&
									<OverlayTrigger
										placement='bottom'
										overlay={<Tooltip id='button-tooltip-2'>Retry</Tooltip>}
									>
										<Button type='button' className='logs-link me-3' 
											onClick={() => dispatch(retry_job(
												instance_id ? instance_id : instanceId,
												jobInfo.header.id, 
												jobInfo.header.status >= 5 && jobInfo.header.status <= 7 ? 'fails' : 'history'
											))}
										>
											<i className='bi bi-arrow-repeat'></i>
										</Button>
									</OverlayTrigger>
								}
								{displayLogs &&
									<OverlayTrigger
										placement='bottom'
										overlay={<Tooltip id='button-tooltip-2'>View Logs</Tooltip>}
									>
										<Link className='logs-link me-2' to={setLogsLink(jobInfo)}>
											<i className='bi bi-card-text'></i>
										</Link>
									</OverlayTrigger>
								}
							</Container>
						</Col>
					</Container>
					<hr className='mt-2'/>
					<Row>
						<h5>Header</h5>
						<Col>
							<p className='m-0'><strong>Source:</strong> {jobInfo.header.source}</p>
							<p className='m-0'><strong>Type:</strong> {findWorkerEvent(worker_events, jobInfo.header.type)}</p>
							<p className='m-0'><strong>Status:</strong> <StatusBadge status={getJobsStatusDesc(jobInfo.header.status)} /> </p>
							<p className='m-0'><strong>Failed:</strong> {epochToLocalDate(jobInfo.header.failed * 1000)}</p>
						</Col>
						<Col>
							<p className='m-0'><strong>Process:</strong> {jobInfo.header.process}</p>
							<p className='m-0'><strong>Date:</strong> {epochToLocalDate(jobInfo.header.date * 1000)}</p>
							<p className='m-0'><strong>Start:</strong> {epochToLocalDate(jobInfo.header.start * 1000)}</p>
							<p className='m-0'><strong>End:</strong> {epochToLocalDate(jobInfo.header.end * 1000)}</p>
						</Col>
					</Row>
					{!isEmpty(jobInfo.data) &&
						<Fragment>
							<hr/>
							<JobData jobData={jobInfo.data} />
						</Fragment>
					}
				</Card.Body>
			</Card>
		);
	}
}

JobInfo.propTypes = {
	title: PropTypes.string.isRequired,
	jobsType: PropTypes.string,
	jobInfo: PropTypes.object.isRequired,
	instanceId: PropTypes.string,
	displayLogs: PropTypes.bool.isRequired
}

export default JobInfo;
