import React from 'react';

// React Bootstrap
import { Container, Row, Col, Card } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../../utils/StatusBadge';

// Utils
import { getServiceTypeDesc, getWorkerStatusDesc } from '../../../../utils/getStatusDesc';

const Service = (props) => {
	const { service } = props;

	return (
		<Card className='shadow mb-4 bg-body rounded card-border' style={{ cursor: 'pointer' }}
			onClick={() => window.open(`/services/${service._id.$oid}/details`, '_self')}
		>
			<Container>
				<Row>
					<Col>
						<Card.Body className='ps-0'>
							<Card.Title as='h6'>{service.name}</Card.Title>
							<Card.Subtitle className='mb-2 text-muted' as='p'>{service.description}</Card.Subtitle>
							<Card.Text>
								<strong>Id:</strong> {service._id.$oid} - <strong>Alias:</strong> {service.alias} - <StatusBadge status={getWorkerStatusDesc(service.status)} /> - <strong>Type:</strong> {getServiceTypeDesc(service.service_type)}
							</Card.Text>
						</Card.Body>
					</Col>
				</Row>
			</Container>
		</Card>
	);
}

Service.propTypes = {
	service: PropTypes.object.isRequired
}

export default Service;
