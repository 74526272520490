import {
	SERVICES_LOADING_TRUE,
	SERVICES_LOADING_FALSE,
	LOAD_SERVICES_INFO_GET,
	LOAD_SERVICES_INFO_ERROR,
	SERVICE_STATE_GET,
	SERVICE_STATE_ERROR,
	ALL_SERVICES_GET,
	ALL_SERVICES_ERROR,
	SERVICE_INFO_GET,
	SERVICE_INFO_ERROR,
	SERVICE_INSTANCES_LOADING,
	SERVICE_INSTANCES_GET,
	SERVICE_INSTANCES_ERROR,
} from '../actions/types';

const initialState = {
	services_loading: false,
	load_services_info: { load: {}, data: {}, services: [] },
	service_state: { service: {}, instances: [] },
	all_services: { count: 0, services: [] },
	service_info: {},
	service_instances_loading: false,
	service_instances: { count: 0, instances: [] },
	services_errors: {}
}

export default function servicesReducer(state = initialState, action){
	switch(action.type){
		case SERVICES_LOADING_TRUE:
			return {
				...state,
				services_loading: true
			}
		case SERVICES_LOADING_FALSE:
			return {
				...state,
				services_loading: false
			}
		case LOAD_SERVICES_INFO_GET:
			delete state.services_errors.load_services_info
			return {
				...state,
				load_services_info: {
					load: action.payload.load,
					data: action.payload.data,
					services: action.payload.services.sort((a, b) => a.service.name.localeCompare(b.service.name))
				}
			}
		case SERVICE_STATE_GET:
			delete state.services_errors.service_state_info
			return {
				...state,
				service_state: {
					service: action.payload.service,
					instances: action.payload.instances.sort((a, b) => a.info.name.localeCompare(b.info.name))
				}
			}
		case ALL_SERVICES_GET:
			return {
				...state,
				all_services: {
					count: action.payload.count,
					services: action.payload.services.sort((a, b) => a.name.localeCompare(b.name))
				}
			}
		case SERVICE_INFO_GET:
			return {
				...state,
				service_info: action.payload
			}
		case SERVICE_INSTANCES_LOADING:
			return {
				...state,
				service_instances_loading: action.payload
			}
		case SERVICE_INSTANCES_GET:
			return {
				...state,
				service_instances: {
					count: action.payload.count,
					instances: action.payload.instances
				}
			}
		case LOAD_SERVICES_INFO_ERROR:
		case SERVICE_STATE_ERROR:
		case ALL_SERVICES_ERROR:
		case SERVICE_INFO_ERROR:
		case SERVICE_INSTANCES_ERROR:
			return {
				...state,
				services_errors: {
					...state.services_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
