import React, { Fragment } from 'react';

// React Bootstrap
import { Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import formatString from '../../../utils/formatString';

const JobData = (props) => {
	const { jobData } = props;

	const parseData = (key, data) => {
		let jobData = data;

		if (jobData === null) {
			jobData = 'Null'
		}
		else if (typeof jobData !== 'string') {
			if (typeof jobData === 'number') {
				if (key === 'timestamp') jobData = epochToLocalDate(jobData);
			}
			else if (typeof jobData === 'boolean') {
				if (jobData) {
					jobData = 'True';
				}
				else {
					jobData = 'False';
				}
			}
		}

		return jobData;
	}

	return (
		<Fragment>
			<Row>
				<h5>Data</h5>
				<Col>
					{Object.keys(jobData).map((key, idx) => (
						<Fragment key={idx}>
							{typeof jobData[key] !== 'object'
								?	<p className='m-0'><b>{formatString(key)}:</b> {parseData(key, jobData[key])}</p>
								: jobData[key] === null
									?	<p className='m-0'><b>{formatString(key)}:</b> {parseData(key, jobData[key])}</p>
									:	Object.keys(jobData[key]).map((innerKey, innerIdx) => (
											<Fragment key={innerIdx}>
												<hr/>
												<p className='m-0'><b>{formatString(innerKey)}:</b> {parseData(innerKey, jobData[key][innerKey])}</p>
											</Fragment>
										))
							}
						</Fragment>
					))}
				</Col>
			</Row>
		</Fragment>
	)
}

JobData.propTypes = {
	jobData: PropTypes.object.isRequired
}

export default JobData;
