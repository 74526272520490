import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Container } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../../utils/LoadingSpinner';
import ServiceLoad from '../services/ServiceLoad';
import { LoadData } from './LoadData';

// Actions
import { get_load_info } from '../../../../actions/loadActions';

const LoadInfo = () => {
	const { load_id } = useParams();

	const dispatch = useDispatch();

	const { load_loading, load_info } = useSelector(state => state.load);

	useEffect(() => {
		dispatch(get_load_info(load_id));
	}, []);

	if (load_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading info' />
		)
	}
	else {
		return (
			<Container className='mt-3'>
				<h2 className='text-center'>Load Info</h2>

				<ServiceLoad load={load_info} showLink={false} />
				<hr/>

				<h3 className='text-center pt-3'>Load Data</h3>
				<LoadData />
			</Container>
		)
	}
}

export default LoadInfo;
