import {
	WORKERS_LOADING_TRUE,
	WORKERS_LOADING_FALSE,
	ALL_WORKER_RESOURCES_GET,
	ALL_WORKER_RESOURCES_ERROR,
	WORKER_INFO_GET,
	WORKER_INFO_ERROR,
	WORKER_INSTANCES_LOADING_TRUE,
	WORKER_INSTANCES_LOADING_FALSE,
	WORKER_INSTANCES_GET,
	WORKER_INSTANCES_ERROR,
	WORKER_START,
	WORKER_START_ERROR,
	WORKER_STOP,
	WORKER_STOP_ERROR,
	WORKER_END,
	WORKER_END_ERROR,
	WORKER_EVENTS_LOADING_TRUE,
	WORKER_EVENTS_GET,
	WORKER_EVENTS_ERROR,
	WORKER_JOB_CREATE_LOADING_TRUE,
	WORKER_JOB_CREATE_SUCCESS,
	WORKER_JOB_CREATE_ERROR,
	WORKER_COMMANDS_LOADING,
	WORKER_COMMANDS_GET,
	WORKER_COMMANDS_ERROR
} from '../actions/types';

const initialState = {
	workers_loading: false,
	all_worker_resources: { count: 0, workers: [] },
	worker_info: {},
	worker_instances_loading: false,
	worker_instances: { count: 0, instances: [] },
	worker_events_loading: false,
	worker_events: [],
	worker_job_create_loading: false,
	worker_job_create_success: false,
	worker_commands_loading: false,
	worker_commands: { count: 0, commands: [] },
	workers_errors: {}
}

export default function workersReducer(state = initialState, action){
	switch(action.type){
		case WORKERS_LOADING_TRUE:
			return {
				...state,
				workers_loading: true
			}
		case WORKERS_LOADING_FALSE:
			return {
				...state,
				workers_loading: false
			}
		case ALL_WORKER_RESOURCES_GET:
			return {
				...state,
				all_worker_resources: {
					count: action.payload.count,
					workers: action.payload.workers.sort((a, b) => a.name.localeCompare(b.name))
				}
			}
		case WORKER_INFO_GET:
			return {
				...state,
				worker_info: action.payload
			}
		case WORKER_INSTANCES_LOADING_TRUE:
			return {
				...state,
				worker_instances_loading: true
			}
		case WORKER_INSTANCES_LOADING_FALSE:
			return {
				...state,
				worker_instances_loading: false
			}
		case WORKER_INSTANCES_GET:
			return {
				...state,
				worker_instances: {
					count: action.payload.count,
					instances: action.payload.instances
				}
			}
		case WORKER_EVENTS_LOADING_TRUE:
			return {
				...state,
				worker_events_loading: true
			}
		case WORKER_EVENTS_GET:
			return {
				...state,
				worker_events_loading: false,
				worker_events: action.payload
			}
		case WORKER_JOB_CREATE_LOADING_TRUE:
			return {
				...state,
				worker_job_create_loading: true
			}
		case WORKER_JOB_CREATE_SUCCESS:
			delete state.workers_errors.create_worker_job
			return {
				...state,
				worker_job_create_loading: false,
				worker_job_create_success: action.payload
			}
		case WORKER_COMMANDS_LOADING:
			return {
				...state,
				worker_commands_loading: action.payload
			}
		case WORKER_COMMANDS_GET:
			delete state.workers_errors.worker_commands
			return {
				...state,
				worker_commands: {
					count: action.payload.count,
					commands: action.payload.commands
				}
			}
		case ALL_WORKER_RESOURCES_ERROR:
		case WORKER_INFO_ERROR:
		case WORKER_INSTANCES_ERROR:
		case WORKER_START_ERROR:
		case WORKER_STOP_ERROR:
		case WORKER_END_ERROR:
		case WORKER_EVENTS_ERROR:
		case WORKER_JOB_CREATE_ERROR:
		case WORKER_COMMANDS_ERROR:
			return {
				...state,
				worker_events_loading: false,
				worker_job_create_loading: false,
				workers_errors: {
					...state.workers_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
