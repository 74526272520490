import React from 'react';

// React Bootstrap
import { Container, Row, Col, Button } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

const ActionButton = (props) => {
	const { title, variant, icon, actionFunction } = props;

	return (
		<Button style={{ backgroundColor: variant, border: variant }} onClick={actionFunction}>
			<i className={`bi bi-${icon}-fill me-1`}></i> {title}
		</Button>
	);
}

ActionButton.propTypes = {
	title: PropTypes.string.isRequired,
	variant: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	actionFunction: PropTypes.func.isRequired
}

const ActionButtons = (props) => {
	const { isHelper, startFunction, stopFunction, endFunction } = props;

	return (
		<Container className='text-center mt-3'>
			<Row>
				<Col>
					<ActionButton
						title={`Start ${isHelper ? 'helper' : 'worker'}`}
						variant='#198754'
						icon='play'
						actionFunction={startFunction}
					/>
				</Col>
				<Col>
					<ActionButton
						title={`Stop ${isHelper ? 'helper' : 'worker'}`}
						variant='#0d6efd'
						icon='pause'
						actionFunction={stopFunction}
					/>
				</Col>
				<Col>
					<ActionButton
						title={`End ${isHelper ? 'helper' : 'worker'}`}
						variant='#dc3545'
						icon='stop'
						actionFunction={endFunction}
					/>
				</Col>
			</Row>
		</Container>
	);
}

ActionButtons.propTypes = {
	isHelper: PropTypes.bool.isRequired,
	startFunction: PropTypes.func.isRequired,
	stopFunction: PropTypes.func.isRequired,
	endFunction: PropTypes.func.isRequired
}

export { ActionButtons, ActionButton };
