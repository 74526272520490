import React, { Fragment } from 'react';

// Packages
import { Container, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../utils/StatusBadge';
import TablePagination from '../../utils/TablePagination';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getWorkerCommandDesc, getWorkerSpeedDesc } from '../../../utils/getStatusDesc';

const Commands = (props) => {
	const { tableLoading, dataCount, tableData, start, setStart, end, setEnd, limit } = props;

	const nPages = Math.ceil(dataCount / limit);

	return (
		<div style={{ marginTop: '2rem' }}>
			<Table responsive>
				<thead style={{backgroundColor: '#e7f1ff'}}>
					<tr>
						<th>Id</th>
						<th>Command</th>
						<th>Owner</th>
						<th>Token</th>
						<th>Speed</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{tableLoading 
						? <tr>
								<td className='text-center' colSpan={9}>
									<Container className='d-flex align-items-center justify-content-center' style={{ height: '5rem' }}>
										<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
									</Container>
								</td>
							</tr>
						: tableData.length > 0
							?	tableData.map((command) => (
								<Fragment key={command?._id?.$oid}>
									<tr>
										<td>{command?._id?.$oid}</td>
										<td><StatusBadge status={getWorkerCommandDesc(command?.command)} /></td>
										<td>{command?.owner?.$oid}</td>
										<td>{command?.token?.$oid}</td>
										<td>{getWorkerSpeedDesc(command?.speed)}</td>
										<td>{epochToLocalDate(command?.date?.$date)}</td>
									</tr>
								</Fragment>
							))
							:	<tr>
									<td className='text-center' colSpan={9}>No commands</td>
								</tr>
					}
				</tbody>
			</Table>
			<TablePagination
				count={dataCount}
				arrayLength={tableData.length}
				nPages={nPages}
				start={start}
				setStart={setStart}
				end={end}
				setEnd={setEnd}
				limit={limit}
			/>
		</div>
	)
}

Commands.propTypes = {
	tableLoading: PropTypes.bool.isRequired,
	dataCount: PropTypes.number.isRequired,
	tableData: PropTypes.array.isRequired,
	start: PropTypes.number.isRequired,
	setStart: PropTypes.func.isRequired,
	end: PropTypes.number.isRequired,
	setEnd: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired,
}

export default Commands;
