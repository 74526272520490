import React from 'react';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

const InstanceData = (props) => {
	const { data } = props;

	return (
		<Container className='mt-3'>
			<Card.Title className='text-center' as='h5'>Instance Data</Card.Title>

			<Container className='p-0'>
				<Row>
					<Col className='text-center'><strong>Num. of processes:</strong> {data.n_processes}</Col>
				</Row>

				<Row>
					<Col>
						<p className='text-center m-0 p-0'><strong>Min process time:</strong> {parseFloat(data.min_process_time).toFixed(2)} sec.</p>
						<p className='text-center m-0 p-0'><strong>Max process time:</strong> {parseFloat(data.max_process_time).toFixed(2)} sec.</p>
					</Col>
					<Col>
						<p className='text-center m-0 p-0'>
							<strong>Sum process times:</strong> {parseFloat(data.sum_process_times).toFixed(2)} sec.
						</p>
						<p className='text-center m-0 p-0'>
							<strong>Average process time:</strong> {parseFloat(data.average_process_time).toFixed(2)} sec.
						</p>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}

InstanceData.propTypes = {
	data: PropTypes.object.isRequired
}

export default InstanceData;
