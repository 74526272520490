import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Col, Container, Spinner } from 'react-bootstrap';

// Components
import VersionForm from './VersionForm';
import Version from './Version';

// Actions
import { get_all_model_versions } from '../../../../actions/modelsActions';
import isEmpty from '../../../../utils/isEmpty';

const Versions = (props) => {
	const { modelInfo } = props;

	const dispatch = useDispatch();

	const { versions_loading, all_model_versions } = useSelector(state => state.models);

	const [modalShow, setModalShow] = useState(false);
	const [update, setUpdate] = useState(false);
	const [versionToUpdate, setVersionToUpdate] = useState({});

	useEffect(() => {
		if (!isEmpty(modelInfo)) {
			dispatch(get_all_model_versions({ model: modelInfo?._id?.$oid }))
		}
	}, [modelInfo]);

	const onHide = () => {
		setModalShow(false);
		setUpdate(false);
		setVersionToUpdate({});
	}

	const createVersion = () => {
		setModalShow(true);
		setUpdate(false);
		setVersionToUpdate({});
	}

	const updateVersion = (version) => {
		setModalShow(true);
		setUpdate(true);
		setVersionToUpdate(version);
	}

	return (
		<div>
			<VersionForm
				show={modalShow}
				onHide={onHide}
				modelInfo={modelInfo}
				update={update}
				versionToUpdate={versionToUpdate}
			/>

			<hr className='mt-4'/>
			<Container className='wrapper-container px-0 mb-3'>
				<Col className='title'>
					<h3 className='text-center'>Model Versions</h3>
				</Col>
				<Col className='icons-btns'>
					<Container className='d-flex align-items-center justify-content-end px-0'>
						<Button className='submit-btn' onClick={createVersion}>
							<strong>Create</strong>
						</Button>
					</Container>
				</Col>
			</Container>
			
			{versions_loading
				?	<Container className='d-flex align-items-center justify-content-center' style={{ height: '500px' }}>
						<p style={{ marginBottom: 0, fontSize: '1.3rem' }}>Loading versions</p>
						<div className='spinner-margin'>
							<Spinner animation='border' style={{ color: '#004982' }} />
						</div>
					</Container>
				:	<Container className='mb-3 p-0' fluid>
						{all_model_versions.count > 0
							?	all_model_versions.versions.map((version, idx) => (
									<Version
										key={idx}
										version={version}
										updateVersion={updateVersion}
									/>
								))
							: <p className='text-center'>No versions found</p>
						}
					</Container>
			}
		</div>
	)
}

export default Versions;
