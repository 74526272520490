import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../utils/LoadingSpinner';
import WorkerResource from './WorkerResource';
import WorkerActionModal from './WorkerActionModal';
import WorkerInstance from './WorkerInstance';
import SelectButton from '../../utils/SelectButton';
import { ActionButtons } from '../../utils/ActionButtons';
import StatusBadge from '../../utils/StatusBadge';
import Error from '../../utils/Error';
import ConfirmDialog from '../../utils/ConfirmDialog';
import WorkerJobCreate from './WorkerJobCreate';
import JobsSearch from '../jobs/JobsSearch';
import JobsFound from '../jobs/JobsFound';
import WorkerInstanceJob from './WorkerInstanceJob';
import Commands from '../commands/CommandsTable';

// Hooks
import useDidMountEffect from '../../../hooks/useDidMountEffect';

// Actions
import { get_organizations } from '../../../actions/organizationActions';
import { get_worker_info, get_worker_instances, get_worker_events, worker_start, worker_stop, worker_end, get_worker_commands } from '../../../actions/workersActions';
import { search_job_worker_instance } from '../../../actions/jobsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getWorkerStateDesc, getWorkerTypeDesc } from '../../../utils/getStatusDesc';
import errorExists from '../../../utils/errorExists';
import { createSpeedOptions } from '../../../utils/createOptions';

const WorkerInfo = () => {
	const { worker_id, resource_id } = useParams();

	const dispatch = useDispatch();

	const { organizations } = useSelector(state => state.organization);

	const { workers_loading, worker_info, worker_commands_loading, worker_commands, worker_instances_loading, worker_instances } = useSelector(state => state.workers);

	const { searching_jobs, jobs_found, jobs_errors, job_worker_instance } = useSelector(state => state.jobs);

	const [modalShow, setModalShow] = useState(false);
	const [modalInfo, setModalInfo] = useState({ title: '', variant: '', actionFunction: null });

	const [organization, setOrganization] = useState('');
	const [speed, setSpeed] = useState('');

	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
	const [confirmFunction, setConfirmFunction] = useState(null);
	const [createJobShow, setCreateJobShow] = useState(false);

	const [searchBy, setSearchBy] = useState('');
	const [jobId, setJobId] = useState('');

	const [pageTitle, setPageTitle] = useState(process.env.REACT_APP_NAME);

	const [instancesCount, setInstancesCount] = useState({ total: 0, available: 0, working: 0, stopped: 0, ended: 0 });

	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(9);
	const [limit] = useState(10);

	useEffect(() => {
		dispatch(get_organizations());
		
		dispatch(get_worker_info(worker_id));
		dispatch(get_worker_instances({ worker: worker_id, resource: resource_id }));

		dispatch(get_worker_events(worker_id));
	}, []);

	useEffect(() => {
		const commandsFilters = { worker: worker_id, sort: true, skip: start, limit: limit };
		dispatch(get_worker_commands(commandsFilters));
	}, [start]);

	useEffect(() => {
		if (!isEmpty(worker_info)) {
			setPageTitle(`${process.env.REACT_APP_NAME} - ${worker_info?.name}`);
		}
	}, [worker_info]);

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	const setSuccessModal = () => {
		setModalShow(true);
		setModalInfo({
			title: 'Start worker', variant: 'success', actionFunction: worker_start
		});
	}

	const setStopModal = () => {
		setModalShow(true);
		setModalInfo({
			title: 'Stop worker', variant: 'primary', actionFunction: worker_stop
		});
	}

	const setEndModal = () => {
		setModalShow(true);
		setModalInfo({
			title: 'End worker', variant: 'danger', actionFunction: worker_end
		});
	}

	useDidMountEffect(() => {
		let workerFilters = { worker: worker_id, resource: resource_id, organization: organization };
		
		if (speed !== '') {
			workerFilters = { ...workerFilters, speed: speed };
		}

		dispatch(get_worker_instances(workerFilters));
	}, [organization, speed]);

	useEffect(() => {
		if (worker_instances?.instances?.length > 0) {
			const total = worker_instances?.instances?.filter(inst => !isEmpty(inst));
			const available = worker_instances?.instances?.filter(inst => inst?.state === '1');
			const working = worker_instances?.instances?.filter(inst => inst?.state === '2');
			const stopped = worker_instances?.instances?.filter(inst => inst?.state === '3');
			const ended = worker_instances?.instances?.filter(inst => inst?.state === '4');

			setInstancesCount({ total: total?.length, available: available?.length, working: working?.length, stopped: stopped?.length, ended: ended?.length });
		}
	}, [worker_instances]);

	if (workers_loading && worker_instances_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading worker info' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container'>
				<ConfirmDialog 
					show={showConfirmDialog}
					confirmDialogTitle={confirmDialogTitle}
					onHide={() => setShowConfirmDialog(false)}
					confirmFunction={() => dispatch(confirmFunction)}
				/>

				<WorkerJobCreate
					show={createJobShow}
					onHide={() => setCreateJobShow(false)}
					resource={worker_id}
				/>

				<h2 className='text-center'>{worker_info.name}</h2>
				<p className='text-center text-muted'>{worker_info.description}</p>

				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Card.Title className='text-center' as='h5'>Worker Information</Card.Title>
						<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {worker_info._id?.$oid}</Card.Subtitle>
						<hr/>						
						<Container className='p-0'>
							<Row>
								<Col className='text-center'><strong>Alias:</strong> {worker_info.alias}</Col>
								<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(worker_info.date?.$date)}</Col>
							</Row>
							<hr/>
							<Row>
								<Col className='text-center'><strong>Status:</strong> <StatusBadge status={getWorkerStateDesc(worker_info.status)} /></Col>
								<Col className='text-center'><strong>Worker type:</strong> {getWorkerTypeDesc(worker_info.worker_type)}</Col>
							</Row>
						</Container>

						<WorkerResource
							title='Worker Resource'
							workerResource={worker_info.resource !== undefined ? worker_info.resource : {}}
						/>
					</Card.Body>
				</Card>

				{worker_instances.count > 0
					?	<Fragment>
							<hr/>
							<h3 className='text-center pt-3'>Worker Actions</h3>
							<ActionButtons
								isHelper={false}
								startFunction={setSuccessModal}
								stopFunction={setStopModal}
								endFunction={setEndModal}
							/>

							<WorkerActionModal
								isHelper={false}
								show={modalShow}
								onHide={() => setModalShow(false)}
								modalInfo={modalInfo}
								workerId={worker_id}
								resourceId={resource_id}
								createSpeedOptions={createSpeedOptions}
								confirmDialogHandlers={{
									setShowConfirmDialog: setShowConfirmDialog,
									setConfirmDialogTitle: setConfirmDialogTitle,
									setConfirmFunction: setConfirmFunction
								}}
							/>
						</Fragment>
					:	null
				}

				<hr className='mt-4'/>
				<h3 className='text-center'>Worker Jobs</h3>
				<Row className='text-center mt-3'>
					<Col>
						<Button className='submit-btn' onClick={() => { setCreateJobShow(true); }}>
							<i className='bi bi-plus-circle me-2'></i> Create Job
						</Button>
					</Col>
					<Col>
						<Button style={{ backgroundColor: '#712cf9', border: '#712cf9'}} onClick={() => setSearchBy(prevState => prevState === 'id' ? '' : 'id')}>
							<i className='bi bi-fingerprint me-2'></i> Search Job ID
						</Button>
					</Col>
					<Col>
						<Button variant='secondary' onClick={() => setSearchBy(prevState => prevState === 'data' ? '' : 'data')}>
							<i className='bi bi-search me-2'></i> Search Job Data
						</Button>
					</Col>
				</Row>
				{searchBy === 'data' &&
					<div className='mt-3'>
						<JobsSearch
							instance={false}
							resource={worker_info._id?.$oid}
						/>
						<div className='mt-4'>
							{!searching_jobs
								?	errorExists(jobs_errors, 'search_job_by_data')
									?	<Error title='Search Jobs' type='danger' msg={jobs_errors['search_job_by_data'].msg} />
									:	<JobsFound jobsFound={jobs_found} />
								:	null
							}
						</div>
					</div>
				}
				{searchBy === 'id' &&
					<div className='mt-3'>
						<p className='text-center pt-3'><i className='bi bi-search me-2'></i>Search Job by Id</p>
						<InputGroup className='mb-3'>
							<InputGroup.Text id='basic-addon1'>Job ID</InputGroup.Text>
							<Form.Control
								placeholder='Type job id'
								aria-label='Type job id'
								aria-describedby='basic-addon2'
								onChange={(e) => setJobId(e.target.value)}
							/>
							<Button 
								style={{ backgroundColor: '#712cf9', border: '#712cf9'}} id='search-job' 
								onClick={() => dispatch(search_job_worker_instance({ worker: worker_id, job: jobId }))}
							>
								<i className='bi bi-search me-2'></i> Search
							</Button>
						</InputGroup>
						{errorExists(jobs_errors, 'job_worker_instance')
							?	<Error title='Worker instance job' type='danger' msg={jobs_errors['job_worker_instance'].msg} />
							:	job_worker_instance.map((data, idx) => (
									<WorkerInstanceJob key={idx} job_worker_instance={data} />
								))
						}
					</div>
				}

				<hr className='mt-4'/>
				<h3 className='text-center'>Worker Commands</h3>
				<Commands
					tableLoading={worker_commands_loading}
					dataCount={worker_commands.count}
					tableData={worker_commands.commands}
					start={start}
					setStart={setStart}
					end={end}
					setEnd={setEnd}
					limit={limit}
				/>
				
				<hr className='mt-4'/>
				<h3 className='text-center pt-3'>Worker Instances</h3>
				<p className='text-center pt-3'><i className='bi bi-filter ms-1 me-2'></i>Filters</p>
				<Row className='mb-3'>
					<Col lg='6' md='6' sm='12'>
						<Row className='align-items-center'>
							<Col className='text-center' lg='2'>Organization</Col>
							<Col>
								<SelectButton
									options={organizations}
									name='organization'
									value={organization}
									onChange={(e) => setOrganization(e !== null ? e.value : '')}
								/>
							</Col>
						</Row>
					</Col>
					<Col lg='6' md='6' sm='12'>
						<Row className='align-items-center'>
							<Col className='text-center' lg='2'>Speed</Col>
							<Col>
								<SelectButton
									options={createSpeedOptions()}
									name='speed'
									value={speed}
									onChange={(e) => setSpeed(e !== null ? e.value : '')}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				{worker_instances.count > 0
					?	<Fragment>
							{worker_instances_loading
								? <Container className='d-flex align-items-center justify-content-center' style={{ height: '533px' }}>
										<p className='spinner-text'>Loading</p>
										<div className='spinner-margin'>
											<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
										</div>
									</Container>
								:	<Fragment>
										<p className='text-center pt-3'><i className='bi bi-bar-chart-line ms-1 me-2'></i>Stats</p>
										<p className='text-center'>
											<span className='fw-bold'>Total:</span> {instancesCount.total}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Available:</span> {instancesCount.available}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Working:</span> {instancesCount.working}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Stopped:</span> {instancesCount.stopped}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Ended:</span> {instancesCount.ended}
										</p>
										{worker_instances.instances.map((workerInstance, idx) => (
											!isEmpty(workerInstance) &&
												<WorkerInstance 
													key={idx}
													workerInstance={workerInstance}
													showLink={true}
													workerId={worker_info._id?.$oid}
													resourceId={worker_info.resource?._id?.$oid}
												/>
										))}
									</Fragment>	
							}
						</Fragment>
					:	<Error type='warning' msg='No worker instances found.' />
				}
			</Container>
		);
	}
}

export default WorkerInfo;
