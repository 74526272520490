import React, { useEffect, useState } from 'react';

// React Bootstrap
import { Container, Modal, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import { Data } from './LoadData';
import ServiceData from '../services/ServiceData';
import StatusBadge from '../../../utils/StatusBadge';
import InstanceOption from '../../instances/InstanceOption';
import InstanceMachine from '../../instances/InstanceMachine';

// Utils
import isEmpty from '../../../../utils/isEmpty';
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getServiceStatusDesc, getServiceTypeDesc } from '../../../../utils/getStatusDesc';

const LoadInstanceModal = props => {
	const { show, onHide, instance } = props;

	const [instanceData, setInstanceData] = useState({});

	useEffect(() => {
		let instanceData = {
			n_processes: instance.n_processes,
			min_process_time: instance.min_process_time,
			max_process_time: instance.max_process_time,
			sum_process_times: instance.sum_process_times,
			average_process_time: instance.average_process_time,
		};

		setInstanceData(instanceData);
	}, [instance]);

	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop='static'
			keyboard={false}
			size='xl'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Instance</Modal.Title>
			</Modal.Header>
			<Modal.Body className='custom-container'>
				<Container className='p-0 mb-5'>
					<Data title='Data' data={instanceData} />
				</Container>

				<ServiceData serviceData={instance.service === undefined ? {} : instance.service} mainPage={false} />

				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Card.Title className='text-center' as='h5'>{instance?.instance?.name}</Card.Title>
						<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{instance?.instance?.description}</Card.Subtitle>
						<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {instance?.instance?._id?.$oid}</Card.Subtitle>
						<hr/>						
						<Container className='p-0'>
							<Row>
								<Col className='text-center'>
									<strong>Public address:</strong> {instance?.instance?.public_address === null || isEmpty(instance?.instance?.public_address) ? 'None' : instance?.instance?.public_address}
								</Col>
								<Col className='text-center'>
									<strong>Private address:</strong> {instance?.instance?.private_address === null || isEmpty(instance?.instance?.private_address) ? 'None' : instance?.instance?.private_address}
								</Col>
								<Col className='text-center'>
									<strong>Internal address:</strong> {instance?.instance?.internal_address === null || isEmpty(instance?.instance?.internal_address) ? 'None' : instance?.instance?.internal_address}
								</Col>
							</Row>
							<hr/>
							<Row>
								<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(instance?.instance?.date?.date)}</Col>
							</Row>
							<Row>
								<Col>
									<p className='text-center m-0 p-0'><strong>Status:</strong> <StatusBadge status={getServiceStatusDesc(instance?.instance?.status)} /></p>
									<p className='text-center m-0 p-0'><strong>Weight:</strong> {instance?.instance?.weight}</p>
								</Col>
								<Col>
									<p className='text-center m-0 p-0'><strong>Instance idx:</strong> {instance?.instance?.instance_idx}</p>
									<p className='text-center m-0 p-0'><strong>Instance type:</strong> {getServiceTypeDesc(instance?.instance?.instance_type)}</p>
								</Col>
							</Row>
							<hr/>
							<Row>
								{instance?.instance?.options !== null &&
									<Col>
										<InstanceOption
											option_id={instance?.instance?.options?.$oid === undefined ? '' : instance?.instance?.options?.$oid}
										/>
									</Col>
								}
								{instance?.instance?.machine !== null &&
									<Col>
										<InstanceMachine
											machine_id={instance?.instance?.machine?.$oid === undefined ? '' : instance?.instance?.machine?.$oid}
										/>
									</Col>
								}
							</Row>
						</Container>
					</Card.Body>
				</Card>
			</Modal.Body>
		</Modal>
	)
}

LoadInstanceModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	instance: PropTypes.object.isRequired
}

export default LoadInstanceModal;
