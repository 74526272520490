import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Table } from 'react-bootstrap';

// Components
import StatusBadge from '../../utils/StatusBadge';
import Pagination from '../../utils/Pagination';

import { get_worker_instance_status_history } from '../../../actions/workerInstanceActions';

// Utils
import { getWorkerInstanceStatusDesc } from '../../../utils/getStatusDesc';
import { epochToLocalDate } from '../../../utils/dateConverter';

const WorkerInstHistory = () => {
  const { instance_id } = useParams();

  const dispatch = useDispatch();

  const { worker_instance_status_history } = useSelector(state => state.workerInstance);
  
  // TODO: Erick: mmm nada mas que eso va a mostrar desde el primero
  // TODO: Erick: me gustaria mas que imprimiera al reves
  // TODO: Erick: dejame pensar, agregale la tabla y ponle una nota de que deberia de mostrarlos al reves

  const [skip, setSkip] = useState(0);
	const [limit] = useState(10);

  const nPages = Math.ceil(worker_instance_status_history.count / limit);

  useEffect(() => {
    dispatch(get_worker_instance_status_history(instance_id, skip, limit));
  }, [skip]);

  return (
    <Fragment>
      <Table className='mt-4' responsive>
        <thead style={{backgroundColor: '#e7f1ff'}}>
          <tr>
            <th>Id</th>
            <th>Prev Status</th>
            <th>Current Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {worker_instance_status_history.count > 0
            ?	worker_instance_status_history.history.map((status) => (
                <tr key={status._id.$oid}>
                  <td>{status._id.$oid}</td>
                  <td><StatusBadge status={getWorkerInstanceStatusDesc(status.prev_status)} /></td>
                  <td><StatusBadge status={getWorkerInstanceStatusDesc(status.current_status)} /></td>
                  <td>{epochToLocalDate(status.date.$date)}</td>
                </tr>
              ))
            :	<tr>
                <td className='text-center' colSpan={4}>No history</td>
              </tr>
          }
        </tbody>
      </Table>
      <Pagination
        count={worker_instance_status_history.count}
        arrayLength={worker_instance_status_history.history.length}
        nPages={nPages}
        skip={skip}
        setSkip={setSkip}
        limit={limit}
      />
    </Fragment>
  );
}

export default WorkerInstHistory;
