import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Actions
import { get_instance_machines } from '../../../actions/instanceActions';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';

const InstanceMachine = (props) => {
	const { machine_id } = props;

	const dispatch = useDispatch();

	const { instance_machines } = useSelector(state => state.instance);

	const [machine, setMachine] = useState({});

	useEffect(() => {
		dispatch(get_instance_machines());
	}, []);

	useEffect(() => {
		if (instance_machines.count > 0) {
			const machineFound = instance_machines.machines.find(machine => machine._id.$oid === machine_id);
			setMachine(machineFound);
		}
	}, [instance_machines]);

	return (
		<Container className='mt-3'>
			<h5 className='text-center'>Machine</h5>

			<Card className='mb-4'>
				<Card.Body>
					<Card.Title as='h6'>{machine?.name}</Card.Title>
					<Card.Subtitle className='mb-2 text-muted' as='p'>{machine?.description}</Card.Subtitle>
						<Container>
							<Row>
								<Col>
									<Row>
										<p className='m-0 p-0'><strong>Id:</strong> {machine?._id?.$oid}</p>
									</Row>
									<Row>
										<p className='m-0 p-0'><strong>Date:</strong> {epochToLocalDate(machine?.date?.$date)}</p>
									</Row>
								</Col>
							</Row>
						</Container>
				</Card.Body>
			</Card>
		</Container>
	);
}

InstanceMachine.propTypes = {
	machine_id: PropTypes.string.isRequired
}

export default InstanceMachine;
