import {
	MODELS_LOADING,
	MODEL_CREATE_LOADING,
	MODEL_CREATE_SUCCESS,
	MODEL_CREATE_ERROR,
	ALL_MODELS_GET,
	ALL_MODELS_ERROR,
	MODEL_INFO_GET,
	MODEL_INFO_ERROR,
	MODEL_UPDATE_ERROR,
	MODEL_STATUS_HISTORY_LOADING,
	MODEL_STATUS_HISTORY_GET,
	MODEL_STATUS_HISTORY_ERROR,
	VERSIONS_LOADING,
	VERSION_CREATE_LOADING,
	VERSION_CREATE_SUCCESS,
	VERSION_CREATE_ERROR,
	ALL_MODEL_VERSIONS_GET,
	ALL_MODEL_VERSIONS_ERROR,
	VERSION_UPDATE_ERROR,
	VERSION_STATUS_HISTORY_GET,
	VERSION_STATUS_HISTORY_ERROR
} from '../actions/types';

const initialState = {
	models_loading: false,
	model_create_loading: false,
	model_create_succes: false,
	all_models: { count: 0, models: [] },
	model_info: {},
	model_status_history_loading: false,
	model_status_history: { count: 0, updates: [] },
	models_errors: {},
	// Versions
	versions_loading: false,
	version_create_loading: false,
	version_create_succes: false,
	all_model_versions: { count: 0, versions: [] },
	version_status_history: { count: 0, updates: [] },
	version_update_succes: false,
	versions_errors: {},
}

export default function modelsReducer (state = initialState, action){
	switch (action.type){
		case MODELS_LOADING:
			return {
				...state,
				models_loading: action.payload
			}
		case MODEL_CREATE_LOADING:
			return {
				...state,
				model_create_loading: action.payload
			}
		case MODEL_CREATE_SUCCESS:
			return {
				...state,
				model_create_loading: false,
				model_create_succes: action.payload
			}
		case ALL_MODELS_GET:
			return {
				...state,
				all_models: {
					count: action.payload.count,
					models: action.payload.models.sort((a, b) => a.name.localeCompare(b.name))
				}
			}
		case MODEL_INFO_GET:
			return {
				...state,
				model_info: action.payload
			}
		case MODEL_STATUS_HISTORY_LOADING:
			return {
				...state,
				model_status_history_loading: action.payload
			}
		case MODEL_STATUS_HISTORY_GET:
			return {
				...state,
				model_status_history: {
					count: action.payload.count,
					updates: action.payload.updates
				}
			}
		case MODEL_CREATE_ERROR:
		case ALL_MODELS_ERROR:
		case MODEL_INFO_ERROR:
		case MODEL_UPDATE_ERROR:
		case MODEL_STATUS_HISTORY_ERROR:
			return {
				...state,
				models_errors: {
					...state.models_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		case VERSIONS_LOADING:
			return {
				...state,
				versions_loading: action.payload
			}
		case VERSION_CREATE_LOADING:
			return {
				...state,
				version_create_loading: action.payload
			}
		case VERSION_CREATE_SUCCESS:
			return {
				...state,
				version_create_loading: false,
				version_create_succes: action.payload
			}
		case ALL_MODEL_VERSIONS_GET:
			return {
				...state,
				all_model_versions: {
					count: action.payload.count,
					versions: action.payload.versions.sort((a, b) => a.date.$date - b.date.$date)
				}
			}
		case VERSION_STATUS_HISTORY_GET:
			return {
				...state,
				version_status_history: {
					count: action.payload.count,
					updates: action.payload.updates
				}
			}
		case VERSION_CREATE_ERROR:
		case ALL_MODEL_VERSIONS_ERROR:
		case VERSION_UPDATE_ERROR:
		case VERSION_STATUS_HISTORY_ERROR:
			return {
				...state,
				versions_errors: {
					...state.versions_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
