import React, { Fragment } from 'react';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../utils/StatusBadge';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getHelperInstanceStatusDesc, getHelperStateDesc } from '../../../utils/getStatusDesc';

const HelperInstance = (props) => {
	const { helperInstance, showLink, helperId, resourceId } = props;

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>{helperInstance.name}</Card.Title>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {helperInstance._id}</Card.Subtitle>
				<hr/>
				<Container className='p-0'>
					<Row>
						<Col className='text-center'><strong>Organization:</strong> {helperInstance.organization}</Col>
						<Col className='text-center'><strong>Runtime:</strong> {helperInstance.runtime}</Col>
					</Row>
					<hr/>
					<Row>
						<Col className='text-center'><strong>Mongo app name:</strong> {helperInstance.mongo_app_name}</Col>
						<Col className='text-center'><strong>Resource:</strong> {helperInstance.resource}</Col>
					</Row>
					<hr/>
					<Row>
						<Col className='text-center'>
							<strong>Start:</strong> {helperInstance.start !== 'None' ? epochToLocalDate(helperInstance.start) : 'None'}
						</Col>
						<Col className='text-center'>
							<strong>Stop:</strong> {helperInstance.stop !== 'None' ? epochToLocalDate(helperInstance.stop) : 'None'}
						</Col>
						<Col className='text-center'>
							<strong>End:</strong> {helperInstance.end !== 'None' ? epochToLocalDate(helperInstance.end) : 'None'}
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col>
							<p className='text-center m-0 p-0'><strong>Last resource:</strong> {helperInstance.last_resource}</p>
							<p className='text-center m-0 p-0'><strong>Current resource:</strong> {helperInstance.current_resource}</p>
						</Col>
						<Col>
							<p className='text-center m-0 p-0'><strong>Last time:</strong> {epochToLocalDate(helperInstance.last_time * 1000)}</p>
							<p className='text-center m-0 p-0'><strong>Current time:</strong> {helperInstance.current_time}</p>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col>
							<p className='text-center m-0 p-0'><strong>Last status:</strong> <StatusBadge status={getHelperInstanceStatusDesc(helperInstance.last_status)} /></p>
							<p className='text-center m-0 p-0'><strong>Force start:</strong> {helperInstance.force_start}</p>
						</Col>
						<Col>
							<p className='text-center m-0 p-0'><strong>State:</strong> <StatusBadge status={getHelperStateDesc(helperInstance.state)} /></p>
							<p className='text-center m-0 p-0'><strong>Events timeout:</strong> {helperInstance.events_timeout}</p>
						</Col>
					</Row>

					{showLink &&
						<Fragment>
							<hr/>
							<Row className='text-end'>
								<Card.Link style={{ textDecoration: 'none' }} href={`/helper/${helperId}/resource/${resourceId}/instance/${helperInstance._id}`}>
									More info <i className='bi bi-chevron-right ms-2 me-1'></i>
								</Card.Link>
							</Row>
						</Fragment>
					}
				</Container>
			</Card.Body>
		</Card>
	);
}

HelperInstance.propTypes = {
	helperInstance: PropTypes.object.isRequired,
	showLink: PropTypes.bool.isRequired,
	helperId: PropTypes.string,
	resourceId: PropTypes.string
}

export default HelperInstance;
