import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Actions
import { search_job_by_data } from '../../../actions/jobsActions';

const JobsSearch = (props) => {
	const { instance, resource } = props;

	const dispatch = useDispatch();

	const { searching_jobs } = useSelector(state => state.jobs);
	
	const [jobData, setJobData] = useState([]);
	const [multipleJobs, setMultipleJobs] = useState('false');

	const onChangeEventData = (e, idx) => {
		const data = [ ...jobData ];
		data[idx][e.target.name] = e.target.value;
		setJobData(data);
	}

	const removeKey = (idx) => {
		const data = [ ...jobData ];
		data.splice(idx, 1);
		setJobData(data);
	}

	const searchWorkerJob = (e) => {
		e.preventDefault();

		let job_data = {};
		jobData.map((item) => { job_data[`${item.key}`] = item.value; });

		let reqBody = {
			worker: resource,
			job_data: job_data,
			multiple_jobs: multipleJobs === 'true' ? true : false
		};

		dispatch(search_job_by_data(instance, reqBody));
	}

	return (
		<Form className='mb-4'>
			<Form.Group>
				<p className='text-center pt-3'><i className='bi bi-search me-2'></i>Search Job by Data</p>
				{jobData.map((item, idx) => (
					<Row className='mb-2' key={idx}>
						<Col xs={5}>
							<Form.Control
								type='text' placeholder='Key' name='key' 
								value={item.key} onChange={e => onChangeEventData(e, idx)}
							/>
						</Col>
						<Col xs={5}>
							<Form.Control
								type='text' placeholder='Value' name='value' 
								value={item.value} onChange={e => onChangeEventData(e, idx)}
							/>
						</Col>
						<Col className='text-center' xs={2}>
							<Button className='btn-remove-key fs-5' onClick={() => removeKey(idx)}>
								<i className='bi bi-dash-circle bi-bold'></i>
							</Button>
						</Col>
					</Row>
				))}
				<Col className='text-center'>
					<Button className='simple-btn' onClick={() => setJobData([...jobData, { key: '', value: '' } ])}>Add Data Key</Button>
				</Col>
			</Form.Group>
			<Form.Group className='text-center mt-3'>
				<Form.Label className='me-3 p-0'>Multiple Jobs</Form.Label>
				<Form.Check
					inline label='True' name='multipleJobs' type='radio' id='radio-input-true'
					checked={multipleJobs === 'true'} onChange={() => setMultipleJobs('true')}
				/>
				<Form.Check
					inline label='False' name='multipleJobs' type='radio' id='radio-input-false'
					checked={multipleJobs === 'false'} onChange={() => setMultipleJobs('false')}
				/>
			</Form.Group>
			<div className='d-grid gap-2 col-3 mx-auto'>
				<Button onClick={searchWorkerJob}>
					{searching_jobs
						?	<Fragment>
								<Spinner className='me-2' as='span' animation='border' size='sm' role='status' aria-hidden='true' />
								Searching
							</Fragment>
						:	'Search'
					}
				</Button>
			</div>
		</Form>
	)
}

JobsSearch.propTypes = {
	resource: PropTypes.string.isRequired,
	instance: PropTypes.bool.isRequired
}

export default JobsSearch;
