import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

// React Bootstrap
import { Table, Container, Spinner, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import Pagination from '../../../utils/Pagination';

// Actions
import { get_load_data } from '../../../../actions/loadActions';

// Utils
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getLoadDataTypeDesc } from '../../../../utils/getStatusDesc';
import isEmpty from '../../../../utils/isEmpty';

const Data = (props) => {
	const { title, data } = props;

	return ( 
		<Card className='shadow mb-2 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title>{title}</Card.Title>
				<hr/>
				<Row>
					<Col>
						<p className='m-0 p-0'><strong>Num. of processes:</strong> {data.n_processes}</p>
						<p className='m-0 p-0'><strong>Min process time:</strong> {parseFloat(data.min_process_time).toFixed(4)} sec.</p>
						<p className='m-0 p-0'><strong>Max process time:</strong> {parseFloat(data.max_process_time).toFixed(4)} sec.</p>
						<p className='m-0 p-0'><strong>Sum process times:</strong> {parseFloat(data.sum_process_times).toFixed(4)} sec.</p>
						<p className='m-0 p-0'><strong>Average process time:</strong> {parseFloat(data.average_process_time).toFixed(4)} sec.</p>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}

Data.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired
}

const LoadData = () => {
	const { load_id } = useParams();

	const dispatch = useDispatch();

	const { load_data } = useSelector(state => state.load);

	const [showAllData, setShowAllData] = useState(false);
	const [selectedData, setSelectedData] = useState({});
	const [skip, setSkip] = useState(0);
	const [limit] = useState(10);

	const [loading] = useState(false);

	const nPages = Math.ceil(load_data.count / limit);

	useEffect(() => {
		dispatch(get_load_data(load_id, { skip, limit }));
	}, [skip]);

	const selectRow = (rowId) => {
		let dataFound = {};
		
		if (!isEmpty(selectedData)) {
			if (rowId !== selectedData.General._id.$oid) {
				dataFound = load_data.data.find(data => data.General._id.$oid === rowId);
				if (!isEmpty(dataFound)) {
					setSelectedData(dataFound);
					setShowAllData(true);
				}
			}
			else {
				setShowAllData(false);
				setSelectedData({});
			}
		}
		else {
			dataFound = load_data.data.find(data => data.General._id.$oid === rowId);
			if (!isEmpty(dataFound)) {
				setSelectedData(dataFound);
				setShowAllData(true);
			}
		}
	}

	return (
		<Fragment>
			<Table className='mt-4 pb-5 mb-5' responsive>
				<thead style={{backgroundColor: '#e7f1ff'}}>
					<tr>
						<th>Id</th>
						<th>Type</th>
						<th>Process</th>
						<th>Start</th>
						<th>End</th>
					</tr>
				</thead>
				<tbody>
					{loading 
						? <tr>
								<td className='text-center' colSpan={9}>
									<Container className='d-flex align-items-center justify-content-center' style={{ height: '5rem' }}>
										<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
									</Container>
								</td>
							</tr>
						: load_data.data.length > 0
							?	load_data.data.map((data) => (
								<Fragment key={data.General._id.$oid}>
									<tr style={{ cursor: 'pointer' }} onClick={() => selectRow(data.General._id.$oid)}>
										<td>{data.General._id.$oid}</td>
										<td>{getLoadDataTypeDesc(data.General.type)}</td>
										<td>{data.General.process.$oid}</td>
										<td>{epochToLocalDate(data.General.start.$date)}</td>
										<td>{epochToLocalDate(data.General.end.$date)}</td>
									</tr>
									{showAllData && data.General._id.$oid === selectedData.General._id.$oid
										?	<tr>
												<td colSpan={9}>
													<Container>
														<Row>
															<Col xs={11}>
																<Row>
																	{Object.keys(selectedData).map((key, idx) => (
																		key !== 'General' &&
																			<Col key={idx} xs={4}>
																				<Data title={key} data={selectedData[key]} />
																			</Col>
																	))}
																</Row>
															</Col>
															<Col className='d-flex align-items-center justify-content-end ps-0'>
																<OverlayTrigger
																	placement='bottom'
																	overlay={<Tooltip id='button-tooltip-2'>View Instances</Tooltip>}
																>
																	<Link className='logs-link me-2' to={`/load/${load_id}/process/${data.General.process.$oid}`}>
																		<i className='bi bi-pc-display'></i>
																	</Link>
																</OverlayTrigger>
															</Col>
														</Row>
													</Container>
												</td>
											</tr>
										: null
									}
								</Fragment>
							))
							:	<tr>
									<td className='text-center' colSpan={5}>No data</td>
								</tr>
					}
				</tbody>
			</Table>
			<Pagination
				count={load_data.count}
				arrayLength={load_data.data.length}
				nPages={nPages}
				skip={skip}
				setSkip={setSkip}
				limit={limit}
			/>
		</Fragment>
	)
}

export { LoadData, Data };
