import {
	SERVICE_INSTANCE_LOADING,
	SERVICE_INSTANCE_INFO_GET,
	SERVICE_INSTANCE_INFO_ERROR
} from '../actions/types';

const initialState = {
	service_instance_loading: false,
	service_instance_info: {},
	service_instance_errors: {}
}

export default function serviceInstanceReducer(state = initialState, action){
	switch(action.type){
		case SERVICE_INSTANCE_LOADING:
			return {
				...state,
				service_instance_loading: action.payload
			}
		case SERVICE_INSTANCE_INFO_GET:
			delete state.service_instance_errors.service_instance_info
			return {
				...state,
				service_instance_info: action.payload
			}
		case SERVICE_INSTANCE_INFO_ERROR:
			return {
				...state,
				service_instance_errors: {
					...state.service_instance_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
