import {
	HELPERS_LOADING,
	ALL_HELPERS_GET,
	ALL_HELPERS_ERROR,
	HELPER_INFO_GET,
	HELPER_INFO_ERROR,
	HELPER_INSTANCES_LOADING,
	HELPER_INSTANCES_GET,
	HELPER_INSTANCES_ERROR,
	HELPER_START,
	HELPER_START_ERROR,
	HELPER_STOP,
	HELPER_STOP_ERROR,
	HELPER_END,
	HELPER_END_ERROR,
	HELPER_COMMANDS_LOADING,
	HELPER_COMMANDS_GET,
	HELPER_COMMANDS_ERROR
} from '../actions/types';

const initialState = {
	helpers_loading: false,
	all_helpers: { count: 0, helpers: [] },
	helper_info: {},
	helper_instances_loading: false,
	helper_instances: { count: 0, instances: [] },
	helper_commands_loading: false,
	helper_commands: { count: 0, commands: [] },
	helpers_errors: {}
}

export default function helpersReducer(state = initialState, action){
	switch(action.type){
		case HELPERS_LOADING:
			return {
				...state,
				helpers_loading: action.payload
			}
		case ALL_HELPERS_GET:
			return {
				...state,
				all_helpers: {
					count: action.payload.count,
					helpers: action.payload.helpers.sort((a, b) => a.name.localeCompare(b.name))
				}
			}
		case HELPER_INFO_GET:
			return {
				...state,
				helper_info: action.payload
			}
		case HELPER_INSTANCES_LOADING:
			return {
				...state,
				helper_instances_loading: action.payload
			}
		case HELPER_INSTANCES_GET:
			return {
				...state,
				helper_instances: {
					count: action.payload.count,
					instances: action.payload.instances
				}
			}
		case HELPER_COMMANDS_LOADING:
			return {
				...state,
				helper_commands_loading: action.payload
			}
		case HELPER_COMMANDS_GET:
			delete state.helpers_errors.helper_commands
			return {
				...state,
				helper_commands: {
					count: action.payload.count,
					commands: action.payload.commands
				}
			}
		case ALL_HELPERS_ERROR:
		case HELPER_INFO_ERROR:
		case HELPER_INSTANCES_ERROR:
		case HELPER_START_ERROR:
		case HELPER_STOP_ERROR:
		case HELPER_END_ERROR:
		case HELPER_COMMANDS_ERROR:
			return {
				...state,
				helpers_errors: {
					...state.helpers_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
