import React from 'react';

// Packages
import PropTypes from 'prop-types';

const ReversePagination = (props) => {
	const { count, arrayLength, nPages, start, setStart, end, setEnd, limit } = props;
	
	const firstPage = () => {
		setStart(-10);
		setEnd(-1);
	}

	const previousPage = () => {
		if (start !== 0) {
			setStart(prevState => prevState - limit);
			setEnd(prevState => prevState - limit);
		}
	}

	const nextPage = () => {
		if (start + arrayLength !== count) {
			setStart(prevState => prevState + limit);
			setEnd(prevState => prevState + limit);
		}
	}

	const lastPage = () => {
		setStart(nPages * limit);
		setEnd((nPages * limit) - (limit + 1));
	}

	return (
		<nav aria-label='Page navigation example'>
			<ul className='pagination justify-content-end'>
				<li className='page-item d-flex align-items-center'>
					<p className='rows-count my-0 me-3'>
						{count.toLocaleString('es-MX') === 0 ? 0 : (end * -1).toLocaleString('es-MX')} - {((end * -1) + arrayLength - 1).toLocaleString('es-MX')} of {count.toLocaleString('es-MX')}
					</p>
				</li>
				<li className='page-item'>
					<button className='btn page-link' type='button' aria-label='First' onClick={firstPage} disabled={end === -1}>
						<i className='bi bi-chevron-bar-left bi-bold'></i>
					</button>
				</li>
				<li className='page-item'>
					<button className='btn page-link' type='button' aria-label='Previous' onClick={previousPage} disabled={end === -1}>
						<i className='bi bi-chevron-left bi-bold'></i>
					</button>
				</li>
				<li className='page-item'>
					<button className='btn page-link' type='button' aria-label='Next' onClick={nextPage} disabled={(start * -1) >= count}>
						<i className='bi bi-chevron-right bi-bold'></i>
					</button>
				</li>
				<li className='page-item'>
					<button className='btn page-link' type='button' aria-label='Last' onClick={lastPage} disabled={(start * -1) >= count}>
						<i className='bi bi-chevron-bar-right bi-bold'></i>
					</button>
				</li>
			</ul>
		</nav>
	);
}

ReversePagination.propTypes = {
	count: PropTypes.number.isRequired,
	arrayLength: PropTypes.number.isRequired,
	nPages: PropTypes.number.isRequired,
	start: PropTypes.number.isRequired,
	setStart: PropTypes.func.isRequired,
	end: PropTypes.number.isRequired,
	setEnd: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired
}

export default ReversePagination;
