import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Container, Modal, Button } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import SelectButton from '../../utils/SelectButton';

const WorkerActionModal = (props) => {
	const { isHelper, show, onHide, modalInfo, workerId, resourceId, createSpeedOptions, confirmDialogHandlers } = props;

	const dispatch = useDispatch();

	const { organizations } = useSelector(state => state.organization);

	const [organization, setOrganization] = useState('');
	const [speed, setSpeed] = useState(0);

	useEffect(() => {
		setOrganization('');
		setSpeed(0);
	}, [show]);

	const actionWorker = () => {
		let workerActionInfo = {
			...(isHelper ? { helper: workerId } : { worker: workerId }),
			resource: resourceId,
			...(organization.length > 0 && { organization: organization }),
			...(speed > 0 && { speed: speed })
		}

		confirmDialogHandlers.setShowConfirmDialog(true);
		confirmDialogHandlers.setConfirmDialogTitle(modalInfo.title);
		confirmDialogHandlers.setConfirmFunction(() => modalInfo.actionFunction(workerActionInfo));
		onHide();
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{modalInfo.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container className='mb-3 p-0' fluid>
					Select an organization:
					<SelectButton
						options={organizations}
						name='organization'
						value={organization}
						onChange={(e) => setOrganization(e !== null ? e.value : '')}
					/>
				</Container>
				{!isHelper &&
					<Container className='mb-3 p-0' fluid>
						Select the speed:
						<SelectButton
							options={createSpeedOptions()}
							name='speed'
							value={speed}
							onChange={(e) => setSpeed(e !== null ? e.value : 0)}
						/>
					</Container>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={onHide}>
					Cancel
				</Button>
				<Button variant={modalInfo.variant} onClick={actionWorker}>{modalInfo.title}</Button>
			</Modal.Footer>
		</Modal>
	);
}

WorkerActionModal.propTypes = {
	isHelper: PropTypes.bool.isRequired,
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	modalInfo: PropTypes.object.isRequired,
	workerId: PropTypes.string.isRequired,
	resourceId: PropTypes.string.isRequired,
	createSpeedOptions: PropTypes.func
}

export default WorkerActionModal;
