const findWorkerEvent = (events, type) => {
  const eventFound = events.find(event => event.value === type);
  
  if (eventFound !== undefined) {
    return eventFound.name;
  }

  return 'None';
}

export default findWorkerEvent;
