// SERVICE_STATUS_NONE = 0
// SERVICE_STATUS_CREATED = 1
// SERVICE_STATUS_AVAILABLE = 2
// SERVICE_STATUS_DOWN = 3
// SERVICE_STATUS_MAINTENANCE = 4
// SERVICE_STATUS_DEPRECATED = 5
// SERVICE_STATUS_REMOVED = 6
export const getServiceStatusDesc = (status) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:		desc = 'None';					break;
		case 1:		desc = 'Created';				break;
		case 2:		desc = 'Available';			break;
		case 3:		desc = 'Down';					break;
		case 4:		desc = 'Maintenance';		break;
		case 5:		desc = 'Deprecated';		break;
		case 6:		desc = 'Removed';				break;
		default:	desc = 'None';					break;
	}

	return desc;
}

// SERVICE_TYPE_NONE = 0
// SERVICE_TYPE_CORE = 1
// SERVICE_TYPE_RECON = 2
// SERVICE_TYPE_WEB = 3
// SERVICE_TYPE_EXTRA = 4
export const getServiceTypeDesc = (type) => {
	let desc = '';

	switch (parseInt(type)) {
		case 0:		desc = 'None';		break;
		case 1:		desc = 'Core';		break;
		case 2:		desc = 'Recon';		break;
		case 3:		desc = 'Web';			break;
		case 4:		desc = 'Extra';		break;
		default:	desc = 'None';		break;
	}

	return desc;
}

// WORKER_STATUS_NONE = 0
// WORKER_STATUS_CREATED = 1
// WORKER_STATUS_AVAILABLE = 2
// WORKER_STATUS_MAINTENANCE = 3
// WORKER_STATUS_DEPRECATED = 4
// WORKER_STATUS_REMOVED = 5
export const getWorkerStatusDesc = (status) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:		desc = 'None';				break;
		case 1:		desc = 'Created';			break;
		case 2:		desc = 'Available';		break;
		case 3:		desc = 'Maintenance';	break;
		case 4:		desc = 'Deprecated';	break;
		case 5:		desc = 'Removed';			break;
		default:	desc = 'None';				break;
	}

	return desc;
}

// WORKER_STATE_NONE = 0
// WORKER_STATE_AVAILABLE = 1
// WORKER_STATE_WORKING = 2
// WORKER_STATE_STOPPED = 3
// WORKER_STATE_ENDED = 4
// WORKER_STATE_DISABLED = 5
export const getWorkerStateDesc = (status) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:		desc = 'None';				break;
		case 1:		desc = 'Available';		break;
		case 2:		desc = 'Working';			break;
		case 3:		desc = 'Stopped';			break;
		case 4:		desc = 'Ended';				break;
		case 5:		desc = 'Disabled';		break;
		default:	desc = 'None';				break;
	}

	return desc;
}

// WORKER_TYPE_NONE = 0
// WORKER_TYPE_CORE = 1
// WORKER_TYPE_RECON = 2
// WORKER_TYPE_WEB = 3
// WORKER_TYPE_OTHER = 4
export const getWorkerTypeDesc = (type) => {
	let desc = '';

	switch (parseInt(type)) {
		case 0:		desc = 'None';		break;
		case 1:		desc = 'Core';		break;
		case 2:		desc = 'Recon';		break;
		case 3:		desc = 'Web';			break;
		case 4:		desc = 'Extra';		break;
		default:	desc = 'None';		break;
	}

	return desc;
}

// WORKER_INSTANCE_STATUS_NONE = 0
// WORKER_INSTANCE_STATUS_READY = 1
// WORKER_INSTANCE_STATUS_AVAILABLE = 2
// WORKER_INSTANCE_STATUS_WORKING = 3
// WORKER_INSTANCE_STATUS_STOPPED = 4
// WORKER_INSTANCE_STATUS_ENDED = 5
// WORKER_INSTANCE_STATUS_MAINTENANCE = 6
// WORKER_INSTANCE_STATUS_DEPRECATED = 7
// WORKER_INSTANCE_STATUS_REMOVED = 8
export const getWorkerInstanceStatusDesc = (status) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:		desc = 'None';					break;
		case 1:		desc = 'Ready';					break;
		case 2:		desc = 'Available';			break;
		case 3:		desc = 'Working';				break;
		case 4:		desc = 'Stopped';				break;
		case 5:		desc = 'Ended';					break;
		case 6:		desc = 'Maintenance';		break;
		case 7:		desc = 'Deprecated';		break;
		case 8:		desc = 'Removed';				break;
		default:	desc = 'None';					break;
	}

	return desc;
}

// WORKER_SPEED_NONE = 0
// WORKER_SPEED_GENERAL = 1
// WORKER_SPEED_COMMON = 2
// WORKER_SPEED_PRIORITY = 3
// WORKER_SPEED_BACKGROUND = 4
// WORKER_SPEED_BACKUP = 5
export const getWorkerSpeedDesc = (speed) => {
	let desc = '';

	switch (parseInt(speed)) {
		case 0:		desc = 'None';				break;
		case 1:		desc = 'General';			break;
		case 2:		desc = 'Common';			break;
		case 3:		desc = 'Priority';		break;
		case 4:		desc = 'Background';	break;
		case 5:		desc = 'Backup';			break;
		default:	desc = 'None';				break;
	}

	return desc;
}

// JOB_STATUS_NONE = 0
// JOB_STATUS_CREATED = 1
// JOB_STATUS_WAITING = 2
// JOB_STATUS_PROCESSING = 3
// JOB_STATUS_GOOD = 4
// JOB_STATUS_INCOMPLETE = 5
// JOB_STATUS_FAILED = 6
// JOB_STATUS_ERROR = 7
export const getJobsStatusDesc = (speed) => {
	let desc = '';

	switch (parseInt(speed)) {
		case 0:		desc = 'None';				break;
		case 1:		desc = 'Created';			break;
		case 2:		desc = 'Waiting';			break;
		case 3:		desc = 'Processing';	break;
		case 4:		desc = 'Good';				break;
		case 5:		desc = 'Incomplete';	break;
		case 6:		desc = 'Failed';			break;
		case 7:		desc = 'Error';				break;
		default:	desc = 'None';				break;
	}

	return desc;
}

// LOG_TYPE_NONE = 0
// LOG_TYPE_DEBUG = 1
// LOG_TYPE_INFO = 2
// LOG_TYPE_WARNING = 3
// LOG_TYPE_ERROR = 4
// LOG_TYPE_CRITICAL = 5
export const getLogTypeDesc = (type) => {
	let desc = '';

	switch (parseInt(type)) {
		case 0:		desc = 'NONE';			break;
		case 1:		desc = 'DEBUG';			break;
		case 2:		desc = 'INFO';			break;
		case 3:		desc = 'WARNING';		break;
		case 4:		desc = 'ERROR';			break;
		case 5:		desc = 'CRITICAL';	break;
		default:	desc = 'NONE';			break;
	}

	return desc;
}

// WORKER_CONFIGURATION_NONE = 0
// WORKER_CONFIGURATION_TEMPLATE = 1
// WORKER_CONFIGURATION_INSTANCE = 2
export const getWorkerConfigTypeDesc = (type) => {
	let desc = '';

	switch (parseInt(type)) {
		case 0:		desc = 'None';			break;
		case 1:		desc = 'Template';	break;
		case 2:		desc = 'Instance';	break;
		default:	desc = 'None';			break;
	}

	return desc;
}

// LOAD_DATA_TYPE_NONE = 0
// LOAD_DATA_TYPE_GENERAL = 1
// LOAD_DATA_TYPE_WORK = 2
// LOAD_DATA_TYPE_RECON = 3
// LOAD_DATA_TYPE_OPTICAL = 4
export const getLoadDataTypeDesc = (type) => {
	let desc = '';

	switch (parseInt(type)) {
		case 0:		desc = 'None';			break;
		case 1:		desc = 'General';		break;
		case 2:		desc = 'Work';			break;
		case 3:		desc = 'Recon';			break;
		case 4:		desc = 'Optical';		break;
		default:	desc = 'None';			break;
	}

	return desc;
}

// WORKER_MESSAGE_TYPE_NONE = 0
// WORKER_MESSAGE_TYPE_GLOBAL = 1
// WORKER_MESSAGE_TYPE_SINGLE = 2
// WORKER_MESSAGE_TYPE_CUSTOM = 3
export const getWorkerMessageTypeDesc = (type) => {
	let desc = '';

	switch (parseInt(type)) {
		case 0:		desc = 'None';			break;
		case 1:		desc = 'Global';		break;
		case 2:		desc = 'Single';		break;
		case 3:		desc = 'Custom';		break;
		default:	desc = 'None';			break;
	}

	return desc;
}

// RECON_STATUS_NONE = 0
// RECON_STATUS_CREATED = 1
// RECON_STATUS_REVIEW = 2
// RECON_STATUS_WAITING = 3
// RECON_STATUS_PROCESSING = 4
// RECON_STATUS_GOOD = 5
// RECON_STATUS_INCOMPLETE = 6
// RECON_STATUS_BAD = 7
// RECON_STATUS_REMOVED = 8
// RECON_STATUS_ERROR = 9
export const getModelStatusDesc = (type) => {
	let desc = '';

	switch (parseInt(type)) {
		case 0:		desc = 'None';			 break;
		case 1:		desc = 'Created';		 break;
		case 2:		desc = 'Review';		 break;
		case 3:		desc = 'Waiting';		 break;
		case 4:		desc = 'Processing'; break;
		case 5:		desc = 'Good';		   break;
		case 6:		desc = 'Incomplete'; break;
		case 7:		desc = 'Bad';		     break;
		case 8:		desc = 'Removed';		 break;
		case 9:		desc = 'Error';		   break;
		default:	desc = 'None';			 break;
	}

	return desc;
}

// HELPER_INSTANCE_STATUS_NONE = 0
// HELPER_INSTANCE_STATUS_READY = 1
// HELPER_INSTANCE_STATUS_AVAILABLE = 2
// HELPER_INSTANCE_STATUS_WORKING = 3
// HELPER_INSTANCE_STATUS_STOPPED = 4
// HELPER_INSTANCE_STATUS_ENDED = 5
// HELPER_INSTANCE_STATUS_MAINTENANCE = 6
// HELPER_INSTANCE_STATUS_DEPRECATED = 7
// HELPER_INSTANCE_STATUS_REMOVED = 8
export const getHelperInstanceStatusDesc = (status) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:		desc = 'None';					break;
		case 1:		desc = 'Ready';					break;
		case 2:		desc = 'Available';			break;
		case 3:		desc = 'Working';				break;
		case 4:		desc = 'Stopped';				break;
		case 5:		desc = 'Ended';					break;
		case 6:		desc = 'Maintenance';		break;
		case 7:		desc = 'Deprecated';		break;
		case 8:		desc = 'Removed';				break;
		default:	desc = 'None';					break;
	}

	return desc;
}

// HELPER_STATE_NONE = 0
// HELPER_STATE_AVAILABLE = 1
// HELPER_STATE_WORKING = 2
// HELPER_STATE_STOPPED = 3
// HELPER_STATE_ENDED = 4
// HELPER_STATE_DISABLED = 5
export const getHelperStateDesc = (status) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:		desc = 'None';				break;
		case 1:		desc = 'Available';		break;
		case 2:		desc = 'Working';			break;
		case 3:		desc = 'Stopped';			break;
		case 4:		desc = 'Ended';				break;
		case 5:		desc = 'Disabled';		break;
		default:	desc = 'None';				break;
	}

	return desc;
}

// HELPER_CONFIGURATION_NONE = 0
// HELPER_CONFIGURATION_TEMPLATE = 1
// HELPER_CONFIGURATION_INSTANCE = 2
export const getHelperConfigTypeDesc = (type) => {
	let desc = '';

	switch (parseInt(type)) {
		case 0:		desc = 'None';			break;
		case 1:		desc = 'Template';	break;
		case 2:		desc = 'Instance';	break;
		default:	desc = 'None';			break;
	}

	return desc;
}

// WORKERS_COMMANDS_NONE = 0
// WORKERS_COMMANDS_START = 1
// WORKERS_COMMANDS_STOP = 2
// WORKERS_COMMANDS_END = 3
export const getWorkerCommandDesc = (command) => {
	let desc = '';

	switch (parseInt(command)) {
		case 0:		desc = 'None';	 break;
		case 1:		desc = 'Start';  break;
		case 2:		desc = 'Stop';	 break;
		case 3:		desc = 'End';	   break;
		default:	desc = 'None';	 break;
	}

	return desc;
}

// SERVICE_INSTANCE_TYPE_NONE = 0
// SERVICE_INSTANCE_TYPE_LOCAL = 1
// SERVICE_INSTANCE_TYPE_CLOUD = 2
// SERVICE_INSTANCE_TYPE_OTHER = 3
export const getServiceInstanceDesc = (command) => {
	let desc = '';

	switch (parseInt(command)) {
		case 0:		desc = 'None';	 break;
		case 1:		desc = 'Local';  break;
		case 2:		desc = 'Cloud';	 break;
		case 3:		desc = 'Other';	 break;
		default:	desc = 'None';	 break;
	}

	return desc;
}

// REQUEST_METHOD_DELETE = 0
// REQUEST_METHOD_GET = 1
// REQUEST_METHOD_HEAD = 2
// REQUEST_METHOD_POST = 3
// REQUEST_METHOD_PUT = 4
// REQUEST_METHOD_CONNECT = 5
// REQUEST_METHOD_OPTIONS = 6
// REQUEST_METHOD_TRACE = 7
// REQUEST_METHOD_UNDEFINED = 8
export const getRequestMethodDesc = (command) => {
	let desc = '';

	switch (parseInt(command)) {
		case 0:		desc = 'DELETE';    break;
		case 1:		desc = 'GET';       break;
		case 2:		desc = 'HEAD';      break;
		case 3:		desc = 'POST';      break;
		case 4:		desc = 'PUT';       break;
		case 5:		desc = 'CONNECT';   break;
		case 6:		desc = 'OPTIONS';   break;
		case 7:		desc = 'TRACE';     break;
		case 8:		desc = 'UNDEFINED'; break;
		default:	desc = 'None';      break;
	}

	return desc;
}

// HANDLER_OUTPUT_NONE = 0
// HANDLER_OUTPUT_TEXT = 1
// HANDLER_OUTPUT_JSON = 2
// HANDLER_OUTPUT_FILE = 3
// HANDLER_OUTPUT_OTHER = 4
export const getHandlerOutputDesc = (command) => {
	let desc = '';

	switch (parseInt(command)) {
		case 0:    desc = 'None';    break;
		case 1:    desc = 'Text';    break;
		case 2:    desc = 'JSON';    break;
		case 3:    desc = 'File';    break;
		case 4:    desc = 'Other';   break;
		default:   desc = 'None';    break;
	}

	return desc;
}

// SERVICE_ERROR_NONE = 0
// SERVICE_ERROR_BAD_REQUEST = 1
// SERVICE_ERROR_MISSING_VALUES = 2
// SERVICE_ERROR_BAD_USER = 3
// SERVICE_ERROR_UNAUTHORIZED = 4
// SERVICE_ERROR_NOT_FOUND = 5
// SERVICE_ERROR_SERVER_ERROR = 6
export const getRequestServiceErrorDesc = (command) => {
	let desc = '';

	switch (parseInt(command)) {
		case 0:    desc = 'None';             break;
		case 1:    desc = 'Bad Request';      break;
		case 2:    desc = 'Missing Values';   break;
		case 3:    desc = 'Bad User';         break;
		case 4:    desc = 'Unauthorized';     break;
		case 5:    desc = 'Not Found';        break;
		case 6:    desc = 'Server Error';     break;
		default:   desc = 'None';             break;
	}

	return desc;
}
