import {
	MESSAGES_LOADING_TRUE,
	MESSAGES_LOADING_FALSE,
	ALL_MESSAGES_GET,
	ALL_MESSAGES_GET_ERROR,
	UPDATE_MESSAGE_LOADING_TRUE,
	UPDATE_MESSAGE_LOADING_FALSE,
	CREATE_MESSAGE,
	CREATE_MESSAGE_ERROR,
	UPDATE_MESSAGE,
	UPDATE_MESSAGE_ERROR
} from '../actions/types';

const initialState = {
	messages_loading: false,
	all_messages: { count: 0, messages: [] },
	update_message_loading: false,
	create_message_success: false,
	update_message_success: false,
	messages_errors: {}
}

export default function messagesReducer(state = initialState, action){
	switch(action.type){
		case MESSAGES_LOADING_TRUE:
			return {
				...state,
				messages_loading: true
			}
		case MESSAGES_LOADING_FALSE:
			return {
				...state,
				messages_loading: false
			}
		case ALL_MESSAGES_GET:
			delete state.messages_errors.all_messages
			return {
				...state,
				all_messages: {
					count: action.payload.count,
					messages: action.payload.messages
				}
			}
		case UPDATE_MESSAGE_LOADING_TRUE:
			return {
				...state,
				update_message_loading: true
			}
		case UPDATE_MESSAGE_LOADING_FALSE:
			return {
				...state,
				update_message_loading: false
			}
		case CREATE_MESSAGE:
			delete state.messages_errors.create_message
			return {
				...state,
				create_message_success: action.payload
			}
		case UPDATE_MESSAGE:
			delete state.messages_errors.update_message
			return {
				...state,
				update_message_success: action.payload
			}
		case ALL_MESSAGES_GET_ERROR:
		case CREATE_MESSAGE_ERROR:
		case UPDATE_MESSAGE_ERROR:
			return {
				...state,
				messages_errors: {
					...state.messages_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
