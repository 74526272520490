const createSpeedOptions = () => {
	const workerSpeed = ['General', 'Common', 'Priority', 'Background', 'Backup'];
	let speedOptions = [];

	workerSpeed.forEach((option, idx) => {
		speedOptions.push({ name: option, value: (idx + 1) });
	});

	return speedOptions;
}

export { createSpeedOptions };
