import React, { useEffect, useState } from 'react';

// Packages
import PropTypes from 'prop-types';

const Alert = (props) => {
	const { type, msg } = props;

	const [icon, setIcon] = useState('');

	useEffect(() => {
		switch (type) {
			case 'success':
				setIcon('bi bi-check-circle-fill text-success me-2');
			break;
			case 'danger':
				setIcon('bi bi-exclamation-triangle-fill text-danger me-2');
			break;
			case 'info':
				setIcon('bi bi-info-circle-fill text-info me-2');
			break;
			default:
				break;
		}
	}, [type]);

	return ( 
		<div className={`alert alert-${type}`} role='alert'>
			<i className={icon}></i> {msg}
		</div>
	);
}

Alert.prototypes = {
	type: PropTypes.string.isRequired,
	msg: PropTypes.string.isRequired
}

export default Alert;
