import Axios from 'axios';

import {
	LOAD_LOADING_TRUE,
	LOAD_LOADING_FALSE,
	LOAD_INFO_GET,
	LOAD_INFO_ERROR,
	LOAD_DATA_GET,
	LOAD_DATA_ERROR,
	LOAD_DATA_INSTANCES_GET,
	LOAD_DATA_INSTANCES_ERROR
} from './types';

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}=${filters[f]}&`;
	})

	return query_params;
}

export const load_loading_true = () => dispatch => {
	dispatch({
		type: LOAD_LOADING_TRUE
	})
}

export const load_loading_false = () => dispatch => {
	dispatch({
		type: LOAD_LOADING_FALSE
	})
}

export const get_load_info = (load_instance) => dispatch => {
	dispatch(load_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/load/info?instance=${load_instance}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: LOAD_INFO_GET,
			payload: res.data
		});
		dispatch(load_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOAD_INFO_ERROR,
			payload: { type: 'load_info', msg: err.message }
		});
		dispatch(load_loading_false());
	})
}

export const get_load_data = (load_instance, filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/load/data?load_type=1&instance=${load_instance}&`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: LOAD_DATA_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOAD_DATA_ERROR,
			payload: { type: 'load_data', msg: err.message }
		});
	})
}

export const get_load_data_instances = (process_id) => dispatch => {
	dispatch(load_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/load/data/instances?process=${process_id}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: LOAD_DATA_INSTANCES_GET,
			payload: res.data
		});
		dispatch(load_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOAD_DATA_INSTANCES_ERROR,
			payload: { type: 'load_data_instances', msg: err.message }
		});
		dispatch(load_loading_false());
	})
}
