import Axios from 'axios';

import {
	JOBS_LOADING_TRUE,
	JOBS_LOADING_FALSE,
	JOB_INFO_LOADING_TRUE,
	JOB_INFO_LOADING_FALSE,
	JOB_INFO_GET,
	JOB_INFO_GET_ERROR,
	JOBS_WAITING_LOADING_TRUE,
	JOBS_WAITING_LOADING_FALSE,
	JOBS_WAITING_GET,
	JOBS_WAITING_GET_ERROR,
	JOB_CURRENT_GET,
	JOB_CURRENT_GET_ERROR,
	JOB_LAST_GET,
	JOB_LAST_GET_ERROR,
	JOBS_HISTORY_LOADING_TRUE,
	JOBS_HISTORY_LOADING_FALSE,
	JOBS_HISTORY_GET,
	JOBS_HISTORY_GET_ERROR,
	JOBS_FAILED_LOADING_TRUE,
	JOBS_FAILED_LOADING_FALSE,
	JOBS_FAILED_GET,
	JOBS_FAILED_GET_ERROR,
	JOB_REMOVED,
	JOB_WORKER_INSTANCE_SEARCH,
	JOB_WORKER_INSTANCE_SEARCH_ERROR,
	SEARCH_JOB_LOADING_TRUE,
	SEARCH_JOB,
	SEARCH_JOB_ERROR
} from './types';

import { alert_remove, alert_set } from './alertActions';

const create_query_params = (filters) => {
	let query_params = '';

	Object.keys(filters).forEach((f) => {
		query_params = query_params + `${f}=${filters[f]}&`;
	})

	return query_params;
}

export const jobs_loading_true = () => dispatch => {
	dispatch({
		type: JOBS_LOADING_TRUE
	})
}

export const jobs_loading_false = () => dispatch => {
	dispatch({
		type: JOBS_LOADING_FALSE
	})
}

export const get_job_info = (jobsType, instance_id, job_id) => dispatch => {
	dispatch({ type: JOB_INFO_LOADING_TRUE });
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/${jobsType}/${instance_id}/jobs/search?job=${job_id}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: JOB_INFO_GET,
			payload: res.data
		});
		dispatch({ type: JOB_INFO_LOADING_FALSE });
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: JOB_INFO_GET_ERROR,
			payload: { type: 'get_job_info', msg: err.response.data?.error }
		});
		dispatch({ type: JOB_INFO_LOADING_FALSE });
	})
}

export const clear_job = () => dispatch => {
	dispatch({
		type: JOB_INFO_GET,
		payload: {}
	})
}

const parseElementsToJSON = (resData) => {
	resData.elements.forEach((element, idx) => {
		resData.elements[idx] = JSON.parse(resData.elements[idx]);
	});
	return resData;
}

export const get_jobs_waiting = (jobsType, instance_id, start, end) => dispatch => {
	dispatch({ type: JOBS_WAITING_LOADING_TRUE })
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/${jobsType}/${instance_id}/waiting?start=${start}&end=${end}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: JOBS_WAITING_GET,
			payload: parseElementsToJSON(res.data)
		});
		dispatch({ type: JOBS_WAITING_LOADING_FALSE })
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: JOBS_WAITING_GET_ERROR,
			payload: { type: 'jobs_waiting', msg: err.message }
		});
		dispatch({ type: JOBS_WAITING_LOADING_FALSE })
	})
}

const removeSuccess = (resData, type) => dispatch => {
	if (resData.hasOwnProperty('removed')) {
		dispatch(alert_set('#198754', 'success', `You have removed ${resData.removed} ${type} jobs.`));
		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
	}
	else {
		dispatch({
			type: JOB_REMOVED,
			payload: { jobInfo: resData, show: true }
		});
	}
}

const removeError = (errMsg) => dispatch => {
	dispatch(alert_set('#dc3545', 'danger', `Error when removing job(s). — ${errMsg}`));
	setTimeout(() => {
		dispatch(alert_remove());
	}, 4000);
}

export const remove_jobs_waiting = (instance_id, data) => dispatch => {
	// console.log(data)
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/waiting/remove`;

	Axios.delete(url, { data: data })
	.then((res) => {
		// console.log(res.data);
		dispatch(removeSuccess(res.data, 'waiting'));

		dispatch(get_jobs_waiting('workers', instance_id, 0, 9));
	}).catch((err) => {
		// console.log(err);
		dispatch(removeError(err.response.data.error));
	})
}

export const get_job_current = (jobsType, instance_id) => dispatch => {
	dispatch(jobs_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/${jobsType}/${instance_id}/current`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: JOB_CURRENT_GET,
			payload: res.data
		});
		dispatch(jobs_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: JOB_CURRENT_GET_ERROR,
			payload: { type: 'job_current', msg: err.response.data.error }
		});
		dispatch(jobs_loading_false());
	})
}

export const get_job_last = (jobsType, instance_id) => dispatch => {
	dispatch(jobs_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/${jobsType}/${instance_id}/last`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: JOB_LAST_GET,
			payload: res.data
		});
		dispatch(jobs_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: JOB_LAST_GET_ERROR,
			payload: { type: 'job_last', msg: err.response.data.error }
		});
		dispatch(jobs_loading_false());
	})
}

export const get_jobs_history = (jobsType, instance_id, start, end) => dispatch => {
	dispatch({ type: JOBS_HISTORY_LOADING_TRUE })
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/${jobsType}/${instance_id}/history?start=${start}&end=${end}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: JOBS_HISTORY_GET,
			payload: parseElementsToJSON(res.data)
		});
		dispatch({ type: JOBS_HISTORY_LOADING_FALSE })
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: JOBS_HISTORY_GET_ERROR,
			payload: { type: 'jobs_history', msg: err.response.data.error }
		});
		dispatch({ type: JOBS_HISTORY_LOADING_FALSE })
	})
}

export const remove_jobs_history = (instance_id, data) => dispatch => {
	// console.log(data)
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/history/remove`;

	Axios.delete(url, { data: data })
	.then((res) => {
		// console.log(res.data);
		dispatch(removeSuccess(res.data, 'history'));

		dispatch(get_jobs_history('workers', instance_id, -10, -1));
	}).catch((err) => {
		// console.log(err);
		dispatch(removeError(err.response.data.error));
	})
}

export const get_jobs_failed = (jobsType, instance_id, start, end) => dispatch => {
	dispatch({ type: JOBS_FAILED_LOADING_TRUE })
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/${jobsType}/${instance_id}/fails?start=${start}&end=${end}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: JOBS_FAILED_GET,
			payload: parseElementsToJSON(res.data)
		});
		dispatch({ type: JOBS_FAILED_LOADING_FALSE })
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: JOBS_FAILED_GET_ERROR,
			payload: { type: 'jobs_failed', msg: err.response.data.error }
		});
		dispatch({ type: JOBS_FAILED_LOADING_FALSE })
	})
}

export const remove_jobs_failed = (instance_id, data) => dispatch => {
	// console.log(data)
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/fails/remove`;

	Axios.delete(url, { data: data })
	.then((res) => {
		// console.log(res.data);
		dispatch(removeSuccess(res.data, 'failed'));

		dispatch(get_jobs_failed('workers', instance_id, -10, -1));
	}).catch((err) => {
		// console.log(err);
		dispatch(removeError(err.response.data.error));
	})
}

export const retry_job = (instance_id, job_id, type) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance_id}/${type}/retry?job=${job_id}`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch(alert_set('#0d6efd', 'info', `Processing job ${job_id}.`));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
	}).catch((err) => {
		// console.log(err);
		dispatch(alert_set('#dc3545', 'danger', `${err.message} — ${err.response.data.error}`));

		setTimeout(() => {
			dispatch(alert_remove());
		}, 4000);
	})
}

export const close_removed_job_modal = () => dispatch => {
	dispatch({
		type: JOB_REMOVED,
		payload: { jobInfo: {}, show: false }
	});
}

export const search_job_worker_instance = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/jobs/search?`;
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		console.log(res.data)
		dispatch({
			type: JOB_WORKER_INSTANCE_SEARCH,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: JOB_WORKER_INSTANCE_SEARCH_ERROR,
			payload: { type: 'job_worker_instance', msg: err.response.data?.error }
		});
	})
}

export const search_job_by_data = (instance, jobInfo) => dispatch => {
	dispatch({ type: SEARCH_JOB_LOADING_TRUE });
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/workers/${instance ? jobInfo.worker + '/' : ''}jobs/search/data`;

	if (instance) delete jobInfo.worker;

	Axios.post(url, jobInfo)
	.then((res) => {
		// console.log(res.data)
		let payload = null;

		if (instance) {
			payload = [];
			(jobInfo.multiple_jobs) ? payload = res.data : payload.push(res.data);
		}
		else {
			payload = res.data;

			payload.map((instanceJobs) => {
				let matchArray = [];
				if (!jobInfo.multiple_jobs) {
					matchArray.push(instanceJobs.match);
					instanceJobs.match = matchArray;
				}
			})
		}
		dispatch({
			type: SEARCH_JOB,
			payload: payload
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SEARCH_JOB_ERROR,
			payload: { type: 'search_job_by_data', msg: err.response.data?.error }
		});
	})
}
