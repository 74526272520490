import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Container, Modal, Button, Form } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import SelectButton from '../../utils/SelectButton';
import Alert from '../../utils/Alert';

const JobsRemoveModal = (props) => {
	const { show, onHide, title, actionFunction, count } = props;
	
	const { instance_id } = useParams();
	
	const dispatch = useDispatch();
	
	const [removeBy, setRemoveBy] = useState('');	
	const [jobId, setJobId] = useState('');
	const [range, setRange] = useState(0);
	const [alert, setAlert] = useState({ display: false, type: '', msg: '' });
	
	const closeModal = () => {
		setRemoveBy('');
		onHide();
	}
	
	const removeJobs = () => {
		if (removeBy === 'jobId') {
			if (jobId === '') {
				setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
				return;
			}
			setAlert({ display: false, type: '', msg: '' });
			
			dispatch(actionFunction(instance_id, { job: jobId }));
		}
		else if (removeBy === 'range'){
			if (range <= 0) {
				setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
				return;
			}
			setAlert({ display: false, type: '', msg: '' });
			
			if (range > count) {
				dispatch(actionFunction(instance_id, { start: count, end: count }));
			}
			dispatch(actionFunction(instance_id, { start: range, end: count }));
		}

		closeModal();
	}
	
	return (
		<Modal
			show={show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{alert.display &&
					<Alert type={alert.type} msg={alert.msg} />
				}
				<Container className='mb-3 p-0' fluid>
					Remove by:
					<SelectButton
						options={[
							{name: 'Job Id', value: 'jobId'},
							{name: 'Range', value: 'range'}
						]}
						name='removeBy'
						value={removeBy}
						onChange={(e) => setRemoveBy(e !== null ? e.value : '')}
					/>
				</Container>
				
				{removeBy === 'jobId' &&
					<Form>
						<Form.Group className='mb-3' controlId='jobId.ControlInput1'>
							<Form.Label>Job ID</Form.Label>
							<Form.Control
								type='text'
								placeholder='Type job id'
								onChange={(e) => setJobId(e.target.value)}
							/>
						</Form.Group>
					</Form>
				}
				
				{removeBy === 'range' &&
					<Form>
						<Form.Group className='mb-3' controlId='range.ControlInput1'>
							<Form.Label>Range</Form.Label>
							<Form.Control
								type='number'
								placeholder='Type range'
								min={0} max={count}
								onChange={(e) => setRange(parseInt(e.target.value))}
							/>
						</Form.Group>
					</Form>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={closeModal}>
					Cancel
				</Button>
				<Button variant='danger' onClick={removeJobs} disabled={removeBy === ''}>
					Remove
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

JobsRemoveModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	actionFunction: PropTypes.func.isRequired,
	count: PropTypes.number.isRequired
}

export default JobsRemoveModal;
