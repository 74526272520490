import React, { useState } from 'react';

// React Bootstrap
import { Container, Row, Col, Card, Button, Collapse, Accordion } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../../utils/StatusBadge';

// Utils
import { getServiceStatusDesc, getServiceTypeDesc } from '../../../../utils/getStatusDesc';

const Service = (props) => {
	const { service } = props;

	const [open, setOpen] = useState(false);

	return (
		<Card className='shadow mb-4 bg-body rounded card-border'>
			<Container>
				<Row>
					<Col style={{ cursor: 'pointer' }}
						onClick={() => window.open(`/load/service/${service.service._id.$oid}`, '_self')}
					>
						<Card.Body className='ps-0'>
							<Card.Title as='h6'>{service.service.name}</Card.Title>
							<Card.Subtitle className='mb-2 text-muted' as='p'>
								{service.service.description} - <StatusBadge status={getServiceStatusDesc(service.service.status)} /> - # of instances: {service.instances.length}
							</Card.Subtitle>
						</Card.Body>
					</Col>
					{service.instances.length > 0 &&
						<Col className='d-flex align-items-center' md='auto'>
							<Button
								className='jobs-actions'
								onClick={() => setOpen(!open)}
								aria-controls='example-collapse-text'
								aria-expanded={open}
							>
								View instances
							</Button>
						</Col>
					}
				</Row>
				<Collapse in={open}>
					<Accordion className='mb-4'>
						{service.instances.map((instance, idx) => (
							<Accordion.Item key={idx} eventKey={idx}>
								<Accordion.Header>{instance.info.name}</Accordion.Header>
								<Accordion.Body>
									<Container>
										<Row>
											<Col>
												<p className='m-0 p-0'><strong>Id:</strong> {instance.info._id}</p>
												<p className='m-0 p-0'><strong>Type:</strong> {getServiceTypeDesc(instance.info.type)}</p>
											</Col>
											<Col>
												<p className='m-0 p-0'><strong>Status:</strong> <StatusBadge status={getServiceStatusDesc(instance.info.status)} /></p>
												<p className='m-0 p-0'><strong>Weight:</strong> {instance.info.weight}</p>
											</Col>
										</Row>
									</Container>
								</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</Collapse>
			</Container>
		</Card>
	);
}

Service.propTypes = {
	service: PropTypes.object.isRequired
}

export default Service;
