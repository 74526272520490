import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Col, Container, Form, Row } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../../utils/LoadingSpinner';
import Service from './Service';

// Actions
import { get_organizations } from '../../../../actions/organizationActions';
import { get_all_services } from '../../../../actions/servicesActions';

const Services = () => {
	const dispatch = useDispatch();

	const { services_loading, all_services } = useSelector(state => state.services);

	const [allService, setAllService] = useState([]);
	const [serviceSearch, setServiceSearch] = useState('');

	useEffect(() => {
		dispatch(get_organizations());
		dispatch(get_all_services());
	}, []);

	useEffect(() => {
		setAllService(all_services.services);
	}, [all_services]);

	useEffect(() => {
		const servicesFound = all_services.services.filter(service => service.name.toLowerCase().includes(serviceSearch.toLowerCase()));
		setAllService(servicesFound);
	}, [serviceSearch]);

	return (
		<Container className='mt-3'>
			<h3 className='text-center'>Services</h3>

			<Form onSubmit={(e) => e.preventDefault()}>
				<Form.Group as={Row} className='mb-3' controlId='serviceName'>
					<Form.Label column sm='2' className='text-center'>
						<i className='bi bi-search me-2'></i> Service Name
					</Form.Label>
					<Col sm='10'>
						<Form.Control
							type='text'
							placeholder='Type service name.'
							onChange={(e) => setServiceSearch(e.target.value)}
						/>
					</Col>
				</Form.Group>
			</Form>
			<p className='text-muted'>Total services: {all_services.count}</p>
			
			<Container className='custom-container'>
				{services_loading
					?	<LoadingSpinner loadingMsg='Loading services' />
					:	<Container>
							{allService.length > 0
								?	allService.map((service, idx) => (
										<Service key={idx} service={service} />
									))
								: serviceSearch !== ''
									?	<p className='text-center'>No matches</p>
									: <p className='text-center'>No services found</p>
							}
						</Container>
				}
			</Container>
		</Container>
	);
}

export default Services;
