import {
	JOBS_LOADING_TRUE,
	JOBS_LOADING_FALSE,
	JOB_INFO_LOADING_TRUE,
	JOB_INFO_LOADING_FALSE,
	JOB_INFO_GET,
	JOB_INFO_GET_ERROR,
	JOBS_WAITING_LOADING_TRUE,
	JOBS_WAITING_LOADING_FALSE,
	JOBS_WAITING_GET,
	JOBS_WAITING_GET_ERROR,
	JOB_CURRENT_GET,
	JOB_CURRENT_GET_ERROR,
	JOB_LAST_GET,
	JOB_LAST_GET_ERROR,
	JOBS_HISTORY_LOADING_TRUE,
	JOBS_HISTORY_LOADING_FALSE,
	JOBS_HISTORY_GET,
	JOBS_HISTORY_GET_ERROR,
	JOBS_FAILED_LOADING_TRUE,
	JOBS_FAILED_LOADING_FALSE,
	JOBS_FAILED_GET,
	JOBS_FAILED_GET_ERROR,
	JOB_REMOVED,
	JOB_WORKER_INSTANCE_SEARCH,
	JOB_WORKER_INSTANCE_SEARCH_ERROR,
	SEARCH_JOB_LOADING_TRUE,
	SEARCH_JOB,
	SEARCH_JOB_ERROR
} from '../actions/types';

const initialState = {
	jobs_loading: false,
	job_search_loading: false,
	job_info: {},
	jobs_waiting_loading: false,
	jobs_waiting: {},
	job_current: {},
	job_last: {},
	jobs_history_loading: false,
	jobs_history: {},
	jobs_failed_loading: false,
	jobs_failed: {},
	job_removed: { jobInfo: {}, show: false },
	job_worker_instance: [],
	searching_jobs: false,
	jobs_found: [],
	jobs_errors: {}
}

export default function jobsReducer(state = initialState, action){
	switch(action.type){
		case JOBS_LOADING_TRUE:
			return {
				...state,
				jobs_loading: true
			}
		case JOBS_LOADING_FALSE:
			return {
				...state,
				jobs_loading: false
			}
		case JOB_INFO_LOADING_TRUE:
			return {
				...state,
				job_search_loading: true
			}
		case JOB_INFO_LOADING_FALSE:
			return {
				...state,
				job_search_loading: false
			}
		case JOB_INFO_GET:
			delete state.jobs_errors.get_job_info
			return {
				...state,
				job_info: action.payload
			}
		case JOBS_WAITING_LOADING_TRUE:
			return {
				...state,
				jobs_waiting_loading: true
			}
		case JOBS_WAITING_LOADING_FALSE:
			return {
				...state,
				jobs_waiting_loading: false
			}
		case JOBS_WAITING_GET:
			delete state.jobs_errors.jobs_waiting
			return {
				...state,
				jobs_waiting: action.payload
			}
		case JOB_CURRENT_GET:
			delete state.jobs_errors.job_current
			return {
				...state,
				job_current: action.payload
			}
		case JOB_LAST_GET:
			delete state.jobs_errors.job_last
			return {
				...state,
				job_last: action.payload
			}
		case JOBS_HISTORY_LOADING_TRUE:
			return {
				...state,
				jobs_history_loading: true
			}
		case JOBS_HISTORY_LOADING_FALSE:
			return {
				...state,
				jobs_history_loading: false
			}
		case JOBS_HISTORY_GET:
			delete state.jobs_errors.jobs_history
			return {
				...state,
				jobs_history: action.payload
			}
		case JOBS_FAILED_LOADING_TRUE:
			return {
				...state,
				jobs_failed_loading: true
			}
		case JOBS_FAILED_LOADING_FALSE:
			return {
				...state,
				jobs_failed_loading: false
			}
		case JOBS_FAILED_GET:
			delete state.jobs_errors.jobs_failed
			return {
				...state,
				jobs_failed: action.payload
			}
		case JOB_REMOVED:
			return {
				...state,
				job_removed: {
					jobInfo: action.payload.jobInfo,
					show: action.payload.show
				}
			}
		case SEARCH_JOB_LOADING_TRUE:
			return {
				...state,
				searching_jobs: true
			}
		case JOB_WORKER_INSTANCE_SEARCH:
			delete state.jobs_errors.job_worker_instance
			return {
				...state,
				job_worker_instance: action.payload
			}
		case SEARCH_JOB:
			delete state.jobs_errors.search_job_by_data
			return {
				...state,
				searching_jobs: false,
				jobs_found: action.payload
			}
		case JOB_INFO_GET_ERROR:
		case JOBS_WAITING_GET_ERROR:
		case JOB_CURRENT_GET_ERROR:
		case JOB_LAST_GET_ERROR:
		case JOBS_HISTORY_GET_ERROR:
		case JOBS_FAILED_GET_ERROR:
		case JOB_WORKER_INSTANCE_SEARCH_ERROR:
		case SEARCH_JOB_ERROR:
			return {
				...state,
				searching_jobs: false,
				jobs_errors: {
					...state.jobs_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
