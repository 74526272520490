import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Container, Table } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../../utils/LoadingSpinner';
import LoadInstanceModal from './LoadInstanceModal';

// Actions
import { get_load_data_instances } from '../../../../actions/loadActions';

// Utils
import { epochToLocalDate } from '../../../../utils/dateConverter';

const LoadDataInstances = () => {
	const { process_id } = useParams();

	const dispatch = useDispatch();

	const { load_loading, load_data_instances } = useSelector(state => state.load);

	const [modalShow, setModalShow] = useState(false);
	const [selectedInstance, setselectedInstance] = useState({});

	useEffect(() => {
		dispatch(get_load_data_instances(process_id));
	}, []);

	if (load_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading data instances' />
		)
	}
	else {
		return (
			<Container className='mt-3'>
				<LoadInstanceModal 
					show={modalShow}
					onHide={() => setModalShow(false)}
					instance={selectedInstance}
				/>

				<h2 className='text-center'>Load Data Instances</h2>
				<p className='text-muted mt-2'>Total instances: {load_data_instances.count}</p>

				<Table className='mt-2 pb-5 mb-5' responsive>
				<thead style={{backgroundColor: '#e7f1ff'}}>
					<tr>
						<th>Id</th>
						<th>Service</th>
						<th>Instance</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{load_data_instances.count > 0
						?	load_data_instances.instances.map((instance) => (
							<tr key={instance._id.$oid} style={{ cursor: 'pointer' }} 
								onClick={() => { setselectedInstance(instance); setModalShow(true); }}
							>
								<td>{instance._id.$oid}</td>
								<td>{instance.service.name}</td>
								<td>{instance.instance.name}</td>
								<td>{epochToLocalDate(instance.date.$date)}</td>
							</tr>
						))
						:	<tr>
								<td className='text-center' colSpan={5}>No data</td>
							</tr>
					}
				</tbody>
				</Table>
			</Container>
		)
	}
}

export default LoadDataInstances;
