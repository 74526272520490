import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../utils/LoadingSpinner';
import Worker from './Worker';
import WorkerJobCreate from './WorkerJobCreate';

// Actions
import { get_organizations } from '../../../actions/organizationActions';
import { get_all_worker_resources } from '../../../actions/workersActions';

const Workers = () => {
	const dispatch = useDispatch();

	const { workers_loading, all_worker_resources } = useSelector(state => state.workers);

	const [allWorkerResources, setAllWorkerResources] = useState([]);
	const [workerSearch, setWorkerSearch] = useState('');
	const [modalShow, setModalShow] = useState(false);

	useEffect(() => {
		dispatch(get_organizations());
		dispatch(get_all_worker_resources());
	}, []);

	useEffect(() => {
		setAllWorkerResources(all_worker_resources.workers);
	}, [all_worker_resources]);

	useEffect(() => {
		const workersFound = all_worker_resources.workers.filter(worker => worker.name.toLowerCase().includes(workerSearch.toLowerCase()));
		setAllWorkerResources(workersFound);
	}, [workerSearch]);

	return (
		<Container className='mt-3'>
			<WorkerJobCreate
				show={modalShow}
				onHide={() => setModalShow(false)}
				resource={null}
			/>

			<Container className='wrapper-container px-0 mb-3'>
				<Col className='title'>
					<h3 className='text-center'>Workers</h3>
				</Col>
				<Col className='icons-btns'>
					<Container className='d-flex align-items-center justify-content-end px-0'>
						<Button className='submit-btn' onClick={() => { setModalShow(true); }}>
							<strong>Create Job</strong>
						</Button>
					</Container>
				</Col>
			</Container>

			<Form onSubmit={(e) => e.preventDefault()}>
				<Form.Group as={Row} className='mb-3' controlId='workerName'>
					<Form.Label column sm='2' className='text-center'>
						<i className='bi bi-search me-2'></i> Worker Name
					</Form.Label>
					<Col sm='10'>
						<Form.Control
							type='text'
							placeholder='Type worker name'
							onChange={(e) => setWorkerSearch(e.target.value)}
						/>
					</Col>
				</Form.Group>
			</Form>
			<p className='text-muted'>Total workers: {all_worker_resources.count}</p>
			
			<Container className='custom-container'>
				{workers_loading
					?	<LoadingSpinner loadingMsg='Loading workers' />
					:	<Container>
							{allWorkerResources.length > 0
								?	allWorkerResources.map((worker, idx) => (
										<Worker key={idx} worker={worker} />
									))
								: workerSearch !== ''
									?	<p className='text-center'>No matches</p>
									: <p className='text-center'>No workers found</p>
							}
						</Container>
				}
			</Container>
		</Container>
	);
}

export default Workers;
