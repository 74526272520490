import Axios from 'axios';

import {
	REQUESTS_LOADING,
	ALL_REQUESTS_GET,
	ALL_REQUESTS_ERROR,
	REQUEST_INFO_LOADING,
	REQUEST_INFO_GET,
	REQUEST_INFO_ERROR
} from './types';

// Utils
import create_query_params from '../utils/create_query_params';

export const get_all_requests = (filters) => dispatch => {
	dispatch({
		type: REQUESTS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/requests?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: ALL_REQUESTS_GET,
			payload: res.data
		});

		dispatch({
			type: REQUESTS_LOADING,
			payload: false
		});
	}).catch((err) => {
		dispatch({
			type: ALL_REQUESTS_ERROR,
			payload: { type: 'all_requests', msg: err.message }
		});

		dispatch({
			type: REQUESTS_LOADING,
			payload: false
		});
	})
}

export const get_request_info = (requestId) => dispatch => {
	dispatch({
		type: REQUEST_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/requests/${requestId}/info`;

	Axios.get(url)
	.then((res) => {
		dispatch({
			type: REQUEST_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: REQUEST_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: REQUEST_INFO_ERROR,
			payload: { type: 'request_info', msg: err.message }
		});

		dispatch({
			type: REQUEST_INFO_LOADING,
			payload: false
		});
	})
}
