import React, { Fragment, useState, useEffect } from 'react';

// React Bootstrap
import { Container, Row, Col, Card } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

const Error = (props) => {
	const { title, type, msg } = props;

	const [icon, setIcon] = useState('');

	useEffect(() => {
		switch (type) {
			case 'danger':
				setIcon('bi-exclamation-circle-fill');
			break;
			case 'warning':
				setIcon('bi-exclamation-triangle-fill');
			break;
			default:
				setIcon('');
			break;
		}
	}, [type]);

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				{title !== undefined &&
					<Fragment>
						<Card.Title className='text-center' as='h5'>{title}</Card.Title>
						<hr/>
					</Fragment>
				}
				<Container className='mt-3'>
					<Row>
						<Col className='text-center'>
							<i className={`bi ${icon} text-${type}`} style={{ fontSize: '2rem' }}></i>
							<p><b>Error: </b>{msg}</p>
						</Col>
					</Row>
				</Container>
			</Card.Body>
		</Card>
	);
}

Error.propTypes = {
	title: PropTypes.string,
	type: PropTypes.string.isRequired,
	msg: PropTypes.string.isRequired
}

export default Error;
