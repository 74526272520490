import Axios from 'axios';

import {
	SERVICES_LOADING_TRUE,
	SERVICES_LOADING_FALSE,
	LOAD_SERVICES_INFO_GET,
	LOAD_SERVICES_INFO_ERROR,
	SERVICE_STATE_GET,
	SERVICE_STATE_ERROR,
	ALL_SERVICES_GET,
	ALL_SERVICES_ERROR,
	SERVICE_INFO_GET,
	SERVICE_INFO_ERROR,
	SERVICE_INSTANCES_LOADING,
	SERVICE_INSTANCES_GET,
	SERVICE_INSTANCES_ERROR,
} from './types';

// Utils
import create_query_params from '../utils/create_query_params';

export const services_loading_true = () => dispatch => {
	dispatch({
		type: SERVICES_LOADING_TRUE
	})
}

export const services_loading_false = () => dispatch => {
	dispatch({
		type: SERVICES_LOADING_FALSE
	})
}

export const get_load_services_info = () => dispatch => {
	dispatch(services_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + '/api/controller/load/state';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: LOAD_SERVICES_INFO_GET,
			payload: res.data
		});
		dispatch(services_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOAD_SERVICES_INFO_ERROR,
			payload: { type: 'load_services_info', msg: err.message }
		});
		dispatch(services_loading_false());
	})
}

export const get_service_state = (service_id) => dispatch => {
	dispatch(services_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/controller/services/${service_id}/state`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: SERVICE_STATE_GET,
			payload: res.data
		});
		dispatch(services_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SERVICE_STATE_ERROR,
			payload: { type: 'service_state', msg: err.message }
		});
		dispatch(services_loading_false());
	})
}

export const get_all_services = () => dispatch => {
	dispatch(services_loading_true());

	let url = process.env.REACT_APP_SERVER_URL + '/api/resources/services?limit=0';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ALL_SERVICES_GET,
			payload: res.data
		});
		
		dispatch(services_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_SERVICES_ERROR,
			payload: { type: 'all_services', msg: err.message }
		});
		
		dispatch(services_loading_false());
	})
}

export const get_service_info = (service_id) => dispatch => {
	dispatch(services_loading_true());
	let url = process.env.REACT_APP_SERVER_URL + `/api/resources/services/${service_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: SERVICE_INFO_GET,
			payload: res.data
		});
		dispatch(services_loading_false());
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SERVICE_INFO_ERROR,
			payload: { type: 'service_info', msg: err.message }
		});
		dispatch(services_loading_false());
	})
}

export const get_service_instances = (filters) => dispatch => {
	// console.log(filters)
	dispatch({
		type: SERVICE_INSTANCES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/resources/instances?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: SERVICE_INSTANCES_GET,
			payload: res.data
		});
		
		dispatch({
			type: SERVICE_INSTANCES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SERVICE_INSTANCES_ERROR,
			payload: { type: 'service_instances', msg: err.message }
		});
		
		dispatch({
			type: SERVICE_INSTANCES_LOADING,
			payload: false
		});
	})
}

