import React from 'react';

// Packages
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import JobCurrentLast from '../jobs/JobCurrentLast';
import JobsHistory from '../jobs/JobsHistory';
import JobsFailed from '../jobs/JobsFailed';

const Jobs = (props) => {
	const { jobsType } = props;

	return (
		<Container className='mt-3 custom-p-container ps-0 pe-0' fluid>
			<JobCurrentLast jobsType={jobsType} />
			<JobsHistory jobsType={jobsType} />
			<JobsFailed jobsType={jobsType} />
		</Container>
	)
}

Jobs.propTypes = {
	jobsType: PropTypes.string.isRequired
}

export default Jobs;
