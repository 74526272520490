import React, { useRef, useState } from 'react';

// Packages
import { Button, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const WorkerInstCleanModal = (props) => {
	const { jobsType, show, onHide, actionFunction } = props;

	const initialJobsToClean = { jobs_queue: false, jobs_history: false, jobs_fails: false };
	const [jobsToClean, setJobsToClean] = useState(initialJobsToClean);

	const closeModalRef = useRef();

	const closeModal = () => {
		onHide();
		setJobsToClean(initialJobsToClean);
	}

	return (
		<Modal
			show={show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Clean Jobs</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<div className="mb-3">
						{jobsType !== 'helper' && (
							<>
								<Form.Check
									id={`default-checkbox`}
									type='checkbox'
									label='Jobs queue'
									value={jobsToClean.jobs_queue}
									onChange={(e) => setJobsToClean({ ...jobsToClean, jobs_queue: e.target.checked })}
								/>
								<figcaption className='blockquote-footer ms-4 mt-1'>Removes all waiting to be processed jobs in related {jobsType} instance queue. Be aware that if another instance uses the same queue it will be affected.</figcaption>
							</>
						)}
						<Form.Check
							id={`disabled-default-checkbox`}
							type='checkbox'
							label='Jobs history'
							value={jobsToClean.jobs_history}
							onChange={(e) => setJobsToClean({ ...jobsToClean, jobs_history: e.target.checked })}
						/>
						<figcaption className='blockquote-footer ms-4 mt-1'>Removes the selected {jobsType} instance past success jobs history.</figcaption>
						<Form.Check
							id={`disabled-default-checkbox`}
							type='checkbox'
							label='Jobs fails'
							value={jobsToClean.jobs_fails}
							onChange={(e) => setJobsToClean({ ...jobsToClean, jobs_fails: e.target.checked })}
						/>
						<figcaption className='blockquote-footer ms-4 mt-1'>Removes the selected {jobsType} instance past failed jobs history.</figcaption>
					</div>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='outline-secondary' onClick={closeModal} ref={closeModalRef}>Cancel</Button>
				<Button className='submit-btn' onClick={() => actionFunction(jobsToClean, closeModalRef)}>Confirm</Button>
			</Modal.Footer>
		</Modal>
	)
}

WorkerInstCleanModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	actionFunction: PropTypes.func.isRequired
}

export default WorkerInstCleanModal;
