import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Card, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

// Components
import JobsTable from './JobsTable';
import TablePagination from '../../utils/TablePagination';
import JobsRemoveModal from './JobsRemoveModal';

// Actions
import { get_jobs_waiting, remove_jobs_waiting } from '../../../actions/jobsActions';

const JobsWaiting = (props) => {
	const { jobsType } = props;

	const { instance_id } = useParams();

	const dispatch = useDispatch();

	const { jobs_waiting_loading, jobs_waiting } = useSelector(state => state.jobs);

	const [modalShow, setModalShow] = useState(false);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(9);
	const [limit] = useState(10);
	const [reloadDate, setReloadDate] = useState(moment().format('L - LTS'));
	
	const nPages = Math.ceil(jobs_waiting.size / limit); 

	useEffect(() => {
		dispatch(get_jobs_waiting(jobsType, instance_id, start, end));
	}, [start]);
	
	return (
		<Card className='shadow mt-2 mb-5 bg-body rounded card-border'>
			<JobsRemoveModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				title='Remove Waiting Jobs'
				actionFunction={remove_jobs_waiting}
				count={jobs_waiting.size === undefined ? 0 : jobs_waiting.size}
			/>

			<Card.Body>
				<Card.Title className='text-center' as='h5'>Waiting Jobs</Card.Title>
				<Row className='mb-3 align-items-center'>
					<Col className='text-start' xs='5'>
						<strong>Queue:</strong> {jobs_waiting.queue}
					</Col>
					<Col className='text-center' xs='1'>
						<strong>Size:</strong> {jobs_waiting.size?.toLocaleString('es-MX')}
					</Col>
					<Col className='text-center' xs='4'>
						<strong>Last Jobs Refresh:</strong> {reloadDate}
					</Col>
					<Col className='text-end' xs='2'>
						<OverlayTrigger
							placement='bottom'
							overlay={<Tooltip id='button-tooltip-2'>Refresh</Tooltip>}
						>
							<Button type='button' className='jobs-actions me-3'
								onClick={() => { setReloadDate(moment().format('L - LTS')); dispatch(get_jobs_waiting(jobsType, instance_id, 0, 9)) }}
							>
								<i className='bi bi-arrow-clockwise bi-bold fs-5'></i>
							</Button>
						</OverlayTrigger>
						<OverlayTrigger
							placement='bottom'
							overlay={<Tooltip id='button-tooltip-2'>Remove Jobs</Tooltip>}
						>
							<Button type='button' className='jobs-actions me-2' onClick={() => setModalShow(true)} 
								disabled={jobs_waiting?.elements?.length === 0}
							>
								<i className='bi bi-trash-fill fs-5'></i>
							</Button>
						</OverlayTrigger>
					</Col>
				</Row>

				<JobsTable 
					loading={jobs_waiting_loading}
					jobsType={jobsType}
					jobs={jobs_waiting.elements === undefined ? [] : jobs_waiting.elements}
					noJobsMsg='No waiting jobs found'
				/>
				<TablePagination
					count={jobs_waiting.size === undefined ? 0 : jobs_waiting.size}
					arrayLength={jobs_waiting.elements === undefined ? 0 : jobs_waiting.elements.length}
					nPages={nPages}
					start={start}
					setStart={setStart}
					end={end}
					setEnd={setEnd}
					limit={limit}
				/>
			</Card.Body>
		</Card>
	);
}

JobsWaiting.propTypes = {
	jobsType: PropTypes.string.isRequired
}

export default JobsWaiting;
