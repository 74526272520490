import React from 'react';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../../utils/StatusBadge';
import InstanceOption from '../../instances/InstanceOption';
import InstanceMachine from '../../instances/InstanceMachine';

// Utils
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getServiceInstanceDesc, getServiceStatusDesc } from '../../../../utils/getStatusDesc';
import isEmpty from '../../../../utils/isEmpty';

const ServiceInstance = (props) => {
	const { serviceInstance, showLink, serviceId } = props;

	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>{serviceInstance?.name}</Card.Title>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{serviceInstance?.description}</Card.Subtitle>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {serviceInstance?._id?.$oid}</Card.Subtitle>
				<hr/>						
				<Container className='p-0'>
					<Row>
						<Col className='text-center'>
							<strong>Public address:</strong> {serviceInstance?.public_address === null || isEmpty(serviceInstance?.public_address) ? 'None' : serviceInstance?.public_address}
						</Col>
						<Col className='text-center'>
							<strong>Private address:</strong> {serviceInstance?.private_address === null || isEmpty(serviceInstance?.private_address) ? 'None' : serviceInstance?.private_address}
						</Col>
						<Col className='text-center'>
							<strong>Internal address:</strong> {serviceInstance?.internal_address === null || isEmpty(serviceInstance?.internal_address) ? 'None' : serviceInstance?.internal_address}
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(serviceInstance?.date?.date)}</Col>
					</Row>
					<Row>
						<Col>
							<p className='text-center m-0 p-0'><strong>Status:</strong> <StatusBadge status={getServiceStatusDesc(serviceInstance?.status)} /></p>
							<p className='text-center m-0 p-0'><strong>Weight:</strong> {serviceInstance?.weight}</p>
						</Col>
						<Col>
							<p className='text-center m-0 p-0'><strong>Instance idx:</strong> {serviceInstance?.instance_idx}</p>
							<p className='text-center m-0 p-0'><strong>Instance type:</strong> {getServiceInstanceDesc(serviceInstance?.instance_type)}</p>
						</Col>
					</Row>
					<hr/>
					<Row>
						{(serviceInstance?.options !== undefined && serviceInstance?.options !== null) &&
							<Col>
								<InstanceOption
									option_id={serviceInstance?.options?._id?.$oid === undefined ? '' : serviceInstance?.options?._id?.$oid}
								/>
							</Col>
						}
						{serviceInstance?.machine !== null &&
							<Col>
								<InstanceMachine
									machine_id={serviceInstance?.machine?.$oid === undefined ? '' : serviceInstance?.machine?.$oid}
								/>
							</Col>
						}
					</Row>
					{showLink &&
						<Row className='text-end'>
							<Card.Link style={{ textDecoration: 'none' }} href={`/services/${serviceId}/instance/${serviceInstance?._id?.$oid}`}>
								More info <i className='bi bi-chevron-right ms-2 me-1'></i>
							</Card.Link>
						</Row>
					}
				</Container>
			</Card.Body>
		</Card>
	);
}

ServiceInstance.propTypes = {
	serviceInstance: PropTypes.object.isRequired,
	showLink: PropTypes.bool.isRequired,
	serviceId: PropTypes.string
}

export default ServiceInstance;
