import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

// Components
import Alert from '../../utils/Alert';
import SelectButton from '../../utils/SelectButton';

// Actions
import { get_worker_events, create_worker_job } from '../../../actions/workersActions';

// Utils
import errorExists from '../../../utils/errorExists';
import { createSpeedOptions } from '../../../utils/createOptions';

// Icons
import checkAnimation from '../../../static/icons/checkAnimation.json';

const WorkerJobCreate = (props) => {
	const { show, onHide, resource, instanceInfo } = props;

	const dispatch = useDispatch();

	const { organizations } = useSelector(state => state.organization);

	const {
		all_worker_resources,
		worker_events_loading,
		worker_events,
		worker_job_create_loading,
		worker_job_create_success,
		workers_errors
	} = useSelector(state => state.workers);
	
	const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);
	
	const initialWorkerJobInfo = {
		organization: instanceInfo?.organization !== 'None' ? instanceInfo?.organization : null,
		resource: resource,
		event_type: 0,
		event_data: {},
		speed: parseInt(instanceInfo?.speed) || 0,
		worker_custom_queue: null
};
	const [workerJobInfo, setWorkerJobInfo] = useState(initialWorkerJobInfo);

	const [eventData, setEventData] = useState([]);

	const [events, setEvents] = useState(worker_events);

	const closeModalRef = useRef();
	const lottieRef	= useRef();

	useEffect(() => {
		if (workerJobInfo.resource !== null) {
			dispatch(get_worker_events(workerJobInfo.resource));
		}
		else {
			setEvents([]);
			setWorkerJobInfo({ ...workerJobInfo, event_type: 0 });
		}
	}, [workerJobInfo.resource]);

	useEffect(() => {
		let workerEventOptions = [];

		if (worker_events.length > 0) {
			worker_events.forEach((event) => {
				workerEventOptions.push({ name: event.name, value: event.value });
			});
		}

		setEvents(workerEventOptions);
	}, [worker_events]);

	const closeModal = () => {
		onHide();
		setAlert(initialAlert);
		setWorkerJobInfo(initialWorkerJobInfo);
		setEventData([]);
	}

	const onChangeEventData = (e, idx) => {
		const data = [ ...eventData ];
		data[idx][e.target.name] = e.target.value;
		setEventData(data);
	}

	const removeKey = (idx) => {
		const data = [ ...eventData ];
		data.splice(idx, 1);
		setEventData(data);
	}

	const validateWorkerJobInfo = workerJobInfo.resource === null || workerJobInfo.event_type === 0 || workerJobInfo.speed === 0;

	const createWorkerJob = (e) => {
		e.preventDefault();

		if (validateWorkerJobInfo) {
			setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
			return;
		}
		setAlert(initialAlert);

		let event_data = {};
		eventData.map((item) => { event_data[`${item.key}`] = item.value; });

		let reqBody = { ...workerJobInfo };
		reqBody.event_data = event_data;

		dispatch(create_worker_job(reqBody, closeModalRef));
	}

	return (
		<Modal
			show={show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Create Worker Job</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{alert.display && <Alert type={alert.type} msg={alert.msg} />}
				{errorExists(workers_errors, 'create_worker_job') &&
					<Alert type='danger' msg={workers_errors['create_worker_job'].msg} />
				}

				<Form>
					{resource === null &&
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Resource</p>
							<SelectButton
								options={all_worker_resources?.workers}
								name='resource'
								value={workerJobInfo.resource}
								onChange={(e) => setWorkerJobInfo({ ...workerJobInfo, resource: e ? e.value : null })}
							/>
						</Container>
					}
					{instanceInfo?.organization === undefined &&
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Organization <span className='text-muted'>(optional)</span></p>
							<SelectButton
								options={organizations}
								name='organization'
								value={workerJobInfo.organization}
								onChange={(e) => setWorkerJobInfo({ ...workerJobInfo, organization: e ? e.value : null })}
							/>
						</Container>
					}
					<Row className='mb-3'>
						<Col>
							<p className='mb-2'>Event Type</p>
							<SelectButton
								options={events}
								name='event_type'
								value={workerJobInfo.resource ? workerJobInfo.event_type : null}
								onChange={(e) => setWorkerJobInfo({ ...workerJobInfo, event_type: e ? e?.value : 0 })}
								loading={worker_events_loading}
							/>
						</Col>
						{instanceInfo?.speed === undefined &&
							<Col>
								<p className='mb-2'>Speed</p>
								<SelectButton
									options={createSpeedOptions()}
									name='speed'
									value={workerJobInfo.speed}
									onChange={(e) => setWorkerJobInfo({ ...workerJobInfo, speed: e ? e?.value : 0 })}
								/>
							</Col>
						}
					</Row>
					<Form.Group className='mb-3'>
						<Form.Label>Worker custom queue <span className='text-muted'>(optional)</span></Form.Label>
						<Form.Control
							type='text'
							placeholder='Type worker custom queue'
							onChange={(e) => setWorkerJobInfo({ ...workerJobInfo, worker_custom_queue: e.target.value })}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Data <span className='text-muted'>(optional)</span></Form.Label>
						{eventData.map((item, idx) => (
							<Row className='mb-2' key={idx}>
								<Col xs={5}>
									<Form.Control
										type='text'
										placeholder='Key'
										name='key' 
										value={item.key}
										onChange={e => onChangeEventData(e, idx)}
									/>
								</Col>
								<Col xs={5}>
									<Form.Control
										type='text'
										placeholder='Value'
										name='value' 
										value={item.value}
										onChange={e => onChangeEventData(e, idx)}
									/>
								</Col>
								<Col className='text-center' xs={2}>
									<Button className='btn-remove-key fs-5' onClick={() => removeKey(idx)}>
										<i className='bi bi-dash-circle bi-bold'></i>
									</Button>
								</Col>
							</Row>
						))}
						<Col>
							<Button className='simple-btn' onClick={() => setEventData([...eventData, { key: '', value: '' } ])}>
								Add Data Key
							</Button>
						</Col>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='outline-secondary' onClick={closeModal} ref={closeModalRef}>Cancel</Button>
				<Button 
					className={worker_job_create_success ? 'success-btn' : 'submit-btn'}
					onClick={(e) => createWorkerJob(e)}
				>
					{worker_job_create_success
						?	<Lottie
								loop={false}
								lottieRef={lottieRef}
								animationData={checkAnimation}
								style={{ height: '24px' }}
							/>
						:	worker_job_create_loading
							?	<Fragment>
									<Spinner className='me-2' as='span' animation='border' size='sm' role='status' aria-hidden='true' />
									Creating
								</Fragment>
							:	'Create'
					}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

WorkerJobCreate.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	resource: PropTypes.string,
	instanceInfo: PropTypes.object
}

export default WorkerJobCreate;
