import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Container, Col, Button } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../utils/LoadingSpinner';
import Message from './Message';
import MessagesFormModal from './MessagesFormModal';
import ToastAlert from '../../utils/ToastAlert';

// Actions
import { get_all_messages } from '../../../actions/messagesActions';

const Messages = () => {
	const dispatch = useDispatch();

	const { messages_loading, all_messages } = useSelector(state => state.messages);

	const [modalShow, setModalShow] = useState(false);
	const [create, setCreate] = useState(false);
	const [messageId, setMessageId] = useState('');

	useEffect(() => {
		dispatch(get_all_messages({}));
	}, []);

	return (
		<Container className='mt-3'>
			<ToastAlert/>

			<MessagesFormModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				create={create}
				messageId={messageId}
			/>

			<Container className='wrapper-container px-0'>
				<Col className='title'>
					<h3 className='text-center'>Messages</h3>
					<p className='text-muted'>Total worker messages: {all_messages.count}</p>
				</Col>
				<Col className='icons-btns'>
					<Container className='d-flex align-items-center justify-content-end px-0'>
						<Button className='submit-btn' onClick={() => { setCreate(true); setModalShow(true); }}>
							<strong>Create</strong>
						</Button>
					</Container>
				</Col>
			</Container>

			{/* TODO: add filters { worker, message_type, skip, limit } */}

			<Container className='custom-container'>
				{messages_loading
					?	<LoadingSpinner loadingMsg='Loading messages' />
					:	<Container>
							{all_messages.count > 0
								?	all_messages.messages.map((message, idx) => (
										<Message
											key={idx}
											message={message}
											showModal={() => {
												setCreate(false);
												setModalShow(true);
												setMessageId(message._id.$oid);
											}}
										/>
									))
								: <p className='text-center'>No messages found</p>
							}
						</Container>
				}
			</Container>
		</Container>
	)
}

export default Messages;
