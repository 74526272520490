import React, { Fragment } from 'react';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../../utils/StatusBadge';

// Utils
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getServiceStatusDesc, getServiceTypeDesc } from '../../../../utils/getStatusDesc';
import isEmpty from '../../../../utils/isEmpty';

const ServiceLoad = (props) => {
	const { load, showLink } = props;
	
	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>{load.name}</Card.Title>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{load.description}</Card.Subtitle>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {load._id}</Card.Subtitle>
				<hr/>
				<Container className='p-0'>
					<Row>
						<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(load.date * 1000)}</Col>
						<Col className='text-center'><strong>Start Time:</strong> {epochToLocalDate(load.start_time * 1000)}</Col>
					</Row>
					<hr/>
					<Row>
						<Col className='text-center'><strong>Public:</strong> {isEmpty(load.public) ? 'None' : load.public}</Col>
						<Col className='text-center'><strong>Private:</strong> {isEmpty(load.private) ? 'None' : load.private}</Col>
						<Col className='text-center'><strong>Internal:</strong> {load.internal}</Col>
					</Row>
					<hr/>
					<Row>
						<Col>
							<p className='text-center m-0 p-0'><strong>Status:</strong> <StatusBadge status={getServiceStatusDesc(load.status)} /></p>
							<p className='text-center m-0 p-0'><strong>Type:</strong> {getServiceTypeDesc(load.type)}</p>
							<p className='text-center m-0 p-0'>
								<strong>Failed requests:</strong> {isEmpty(load.failed_requests) ? 'None' : load.failed_requests}
							</p>
						</Col>
						<Col>
							<p className='text-center m-0 p-0'>
								<strong>Weight:</strong> {isEmpty(load.weight) ? 'None' : load.weight}
							</p>
							<p className='text-center m-0 p-0'>
								<strong>Available:</strong> {isEmpty(load.available) ? 'None' : load.available}
							</p>
							<p className='text-center m-0 p-0'>
								<strong>Down:</strong> {isEmpty(load.down) ? 'None' : load.down}
							</p>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col className='text-center'><strong>Service:</strong> {load.service}</Col>
						<Col className='text-center'><strong>Options:</strong> {load.options}</Col>
						<Col className='text-center'><strong>Machine:</strong> {load.machine}</Col>
					</Row>
				</Container>
				{showLink &&
					<Fragment>
						<hr/>
						<Row className='text-end'>
							<Card.Link style={{ textDecoration: 'none' }} href={`/load/${load._id}`}>
								More info <i className='bi bi-chevron-right ms-2 me-1'></i>
							</Card.Link>
						</Row>
					</Fragment>
				}
			</Card.Body>
		</Card>
	);
}

ServiceLoad.propTypes = {
	load: PropTypes.object.isRequired,
	showLink: PropTypes.bool.isRequired
}

export default ServiceLoad;
