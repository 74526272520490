import {
	LOAD_LOADING_TRUE,
	LOAD_LOADING_FALSE,
	LOAD_INFO_GET,
	LOAD_INFO_ERROR,
	LOAD_DATA_GET,
	LOAD_DATA_ERROR,
	LOAD_DATA_INSTANCES_GET,
	LOAD_DATA_INSTANCES_ERROR
} from '../actions/types';

const initialState = {
	load_loading: false,
	load_info: {},
	load_data: { count: 0, data: [] },
	load_data_instances: { count: 0, instances: [] },
	load_errors: {}
}

export default function loadReducer(state = initialState, action){
	switch(action.type){
		case LOAD_LOADING_TRUE:
			return {
				...state,
				load_loading: true
			}
		case LOAD_LOADING_FALSE:
			return {
				...state,
				load_loading: false
			}
		case LOAD_INFO_GET:
			delete state.load_errors.load_info
			return {
				...state,
				load_info: action.payload
			}
		case LOAD_DATA_GET:
			delete state.load_errors.load_data
			return {
				...state,
				load_data: {
					count: action.payload.count,
					data: action.payload.data
				}
			}
		case LOAD_DATA_INSTANCES_GET:
			delete state.load_errors.load_data_instances
			return {
				...state,
				load_data_instances: {
					count: action.payload.length,
					instances: action.payload.sort((a, b) => a.instance.name.localeCompare(b.instance.name))
				}
			}
		case LOAD_INFO_ERROR:
		case LOAD_DATA_ERROR:
		case LOAD_DATA_INSTANCES_ERROR:
			return {
				...state,
				load_errors: {
					...state.load_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
