import React from 'react';

// React Bootstrap
import { Container, Card, Row, Col } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import InstanceData from './InstanceData';
import InstanceOption from './InstanceOption';
import InstanceMachine from './InstanceMachine';
import StatusBadge from '../../utils/StatusBadge';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getServiceStatusDesc, getServiceTypeDesc } from '../../../utils/getStatusDesc';
import isEmpty from '../../../utils/isEmpty';
import isObjectId from '../../../utils/isObjectId';

const InstanceInfo = (props) => {
	const { instance } = props;
	
	return (
		<Card className='shadow mb-5 bg-body rounded card-border'>
			<Card.Body>
				<Card.Title className='text-center' as='h5'>{instance.info.name}</Card.Title>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{instance.info.description}</Card.Subtitle>
				<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {instance.info._id}</Card.Subtitle>
				<hr/>
				<Container className='p-0'>
					<Row>
						<Col className='text-center'><strong>Internal:</strong> {instance.info.internal}</Col>
						<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(instance.info.date * 1000)}</Col>
					</Row>

					<hr/>
					<Row>
						<Col>
							<p className='text-center m-0 p-0'><strong>Status:</strong> <StatusBadge status={getServiceStatusDesc(instance.info.status)} /></p>
							<p className='text-center m-0 p-0'><strong>Type:</strong> {getServiceTypeDesc(instance.info.type)}</p>
							<p className='text-center m-0 p-0'>
								<strong>Available:</strong> {isEmpty(instance.info.available) ? 'None' : instance.info.available}
							</p>
							<p className='text-center m-0 p-0'>
								<strong>Down:</strong> {isEmpty(instance.info.down) ? 'None' : instance.info.down}
							</p>
						</Col>
						<Col>
							<p className='text-center m-0 p-0'>
								<strong>Failed requests:</strong> {isEmpty(instance.info.failed_requests) ? 'None' : instance.info.failed_requests}
							</p>
							<p className='text-center m-0 p-0'>
								<strong>Private:</strong> {isEmpty(instance.info.private) ? 'None' : instance.info.private}
							</p>
							<p className='text-center m-0 p-0'>
								<strong>Public:</strong> {isEmpty(instance.info.public) ? 'None' : instance.info.public}
							</p>
							<p className='text-center m-0 p-0'>
								<strong>Weight:</strong> {isEmpty(instance.info.weight) ? 'None' : instance.info.weight}
							</p>
						</Col>
					</Row>

					<hr/>
					<InstanceData data={instance.data} />

					<hr/>
					<Row>
						{isObjectId(instance.info.options) &&
							<Col><InstanceOption option_id={instance.info.options} /></Col>
						}
						{isObjectId(instance.info.machine) &&
							<Col><InstanceMachine machine_id={instance.info.machine} /></Col>
						}
					</Row>
				</Container>
			</Card.Body>
		</Card>
	);
}

InstanceInfo.propTypes = {
	instance: PropTypes.object.isRequired
}

export default InstanceInfo;
