import {
	HELPER_INSTANCE_INFO_LOADING,
	HELPER_INSTANCE_INFO_GET,
	HELPER_INSTANCE_INFO_ERROR,
	HELPER_INSTANCE_DATA_GET,
	HELPER_INSTANCE_DATA_ERROR,
	HELPER_INSTANCE_DATA_HISTORY_GET,
	HELPER_INSTANCE_DATA_HISTORY_ERROR,
	HELPER_INSTANCE_CONFIG_GET,
	HELPER_INSTANCE_CONFIG_ERROR,
	HELPER_INSTANCE_START_ERROR,
	HELPER_INSTANCE_STOP_ERROR,
	HELPER_INSTANCE_END_ERROR,
	HELPER_INSTANCE_CLEAN_ERROR,
	HELPER_INSTANCE_STATUS_HISTORY_GET,
	HELPER_INSTANCE_STATUS_HISTORY_ERROR,
	HELPER_INSTANCE_COMMANDS_LOADING,
	HELPER_INSTANCE_COMMANDS_GET,
	HELPER_INSTANCE_COMMANDS_ERROR
} from '../actions/types';

const initialState = {
	helper_instance_loading: false,
	helper_instance: { info: {}, data: {}, config: {} },
	helper_instance_data_history: { count: 0, history: [] },
	helper_instance_status_history: { count: 0, history: [] },
	helper_instance_commands_loading: false,
	helper_instance_commands: { count: 0, commands: [] },
	helper_instance_errors: {}
}

export default function helperInstanceReducer(state = initialState, action){
	switch(action.type){
		case HELPER_INSTANCE_INFO_LOADING:
			return {
				...state,
				helper_instance_loading: action.payload
			}
		case HELPER_INSTANCE_INFO_GET:
			delete state.helper_instance_errors.helper_instance_info
			return {
				...state,
				helper_instance: {
					...state.helper_instance,
					info: action.payload
				}
			}
		case HELPER_INSTANCE_DATA_GET:
			delete state.helper_instance_errors.helper_instance_data
			return {
				...state,
				helper_instance: {
					...state.helper_instance,
					data: action.payload
				}
			}
		case HELPER_INSTANCE_DATA_HISTORY_GET:
			delete state.helper_instance_errors.helper_instance_data_history
			return {
				...state,
				helper_instance_data_history: {
					count: action.payload.count,
					history: action.payload.data
				}
			}
		case HELPER_INSTANCE_CONFIG_GET:
			delete state.helper_instance_errors.helper_instance_config
			return {
				...state,
				helper_instance: {
					...state.helper_instance,
					config: action.payload
				}
			}
		case HELPER_INSTANCE_STATUS_HISTORY_GET:
			delete state.helper_instance_errors.helper_instance_status_history
			return {
				...state,
				helper_instance_status_history: {
					count: action.payload.count,
					history: action.payload.history
				}
			}
		case HELPER_INSTANCE_COMMANDS_LOADING:
			return {
				...state,
				helper_instance_commands_loading: action.payload
			}
		case HELPER_INSTANCE_COMMANDS_GET:
			delete state.helper_instance_errors.helper_instance_commands
			return {
				...state,
				helper_instance_commands: {
					count: action.payload.count,
					commands: action.payload.commands
				}
			}
		case HELPER_INSTANCE_INFO_ERROR:
		case HELPER_INSTANCE_DATA_ERROR:
		case HELPER_INSTANCE_DATA_HISTORY_ERROR:
		case HELPER_INSTANCE_CONFIG_ERROR:
		case HELPER_INSTANCE_START_ERROR:
		case HELPER_INSTANCE_STOP_ERROR:
		case HELPER_INSTANCE_END_ERROR:
		case HELPER_INSTANCE_CLEAN_ERROR:
		case HELPER_INSTANCE_STATUS_HISTORY_ERROR:
		case HELPER_INSTANCE_COMMANDS_ERROR:
			return {
				...state,
				helper_instance_errors: {
					...state.helper_instance_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
