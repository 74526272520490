import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../../utils/LoadingSpinner';
import ServiceData from '../../load/services/ServiceData';
import ServiceInstance from './ServiceInstance';
import Error from '../../../utils/Error';
import RequestsTable from '../requests/RequestsTable';
import StatusBadge from '../../../utils/StatusBadge';
import RequestFilters from '../requests/RequestFilters';

// Actions
import { get_service_info, get_service_instances } from '../../../../actions/servicesActions';
import { get_all_requests, get_request_info } from '../../../../actions/requestsActions';
import { get_organizations } from '../../../../actions/organizationActions';

// Utils
import isEmpty from '../../../../utils/isEmpty';
import errorExists from '../../../../utils/errorExists';
import { getJobsStatusDesc, getRequestMethodDesc } from '../../../../utils/getStatusDesc';
import { epochToLocalDate, getLocalEpoch } from '../../../../utils/dateConverter';

const ServiceInfo = () => {
	const { service_id } = useParams();

	const dispatch = useDispatch();

	const { services_loading, service_info, service_instances_loading, service_instances } = useSelector(state => state.services);

	const { requests_loading, all_requests, request_info_loading, request_info, requests_errors } = useSelector(state => state.requests);

	const [instancesStats, setInstancesStats] = useState({ total: 0, available: 0, working: 0, stopped: 0, ended: 0 });
	const [requestId, setRequestId] = useState('');

	const searchReqsFiltersInitialState = {
		method: null,
		path: '',
		organization: null,
		token: '',
		user: '',
		status: null,
		good: false,
		bad: false,
		start: '',
		end: '',
		sort: false
	};
	const [searchReqsFilters, setSearchReqsFilters] = useState(searchReqsFiltersInitialState);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(9);
	const [limit] = useState(10);

	useEffect(() => {
		dispatch(get_service_info(service_id));
		dispatch(get_service_instances({ service: service_id }));
		dispatch(get_organizations());
	}, []);

	useEffect(() => {
		if (service_instances?.instances?.length > 0) {
			const total = service_instances?.instances?.filter(inst => !isEmpty(inst));
			const available = service_instances?.instances?.filter(inst => inst?.state === '2');

			setInstancesStats({ total: total?.length, available: available?.length });
		}
	}, [service_instances]);

	useEffect(() => {
		handleSearchRequest(false);
	}, [start]);

	const resetSearchReqsFilters = () => {
		setSearchReqsFilters(searchReqsFiltersInitialState);
	}

	const handleSearchRequest = (fromSearchButton) => {
		const filters = {
			service: service_id,
			...(searchReqsFilters.method !== undefined && searchReqsFilters.method !== null && { method: searchReqsFilters.method }),
			...(searchReqsFilters.path.length > 0 && { path: searchReqsFilters.path }),
			...(searchReqsFilters.organization !== undefined && searchReqsFilters.organization !== null && { organization: searchReqsFilters.organization }),
			...(searchReqsFilters.token.length > 0 && { token: searchReqsFilters.token }),
			...(searchReqsFilters.user.length > 0 && { user: searchReqsFilters.user }),
			...(searchReqsFilters.status !== undefined && searchReqsFilters.status !== null && { status: searchReqsFilters.status }),
			good: searchReqsFilters.good,
			bad: searchReqsFilters.bad,
			...(searchReqsFilters.start.length > 0 && { start: getLocalEpoch(searchReqsFilters.start) }),
			...(searchReqsFilters.end.length > 0 && { end: getLocalEpoch(searchReqsFilters.end) }),
			sort: searchReqsFilters.sort,
			skip: start,
			limit: limit,
		};

		if (fromSearchButton) {
			setStart(0);
		}
		
		dispatch(get_all_requests(filters));
	}

	if (services_loading && isEmpty(service_info)) {
		return (
			<LoadingSpinner loadingMsg='Loading service info' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container'>
				<h2 className='text-center'>{service_info?.name}</h2>
				<p className='text-center text-muted'>{service_info?.description}</p>

				<ServiceData serviceData={service_info} mainPage={true} />

				<hr className='mt-4'/>
				<h3 className='text-center'>Service Requests</h3>
				<div className='mt-3'>
					<p className='text-center pt-3'><i className='bi bi-search me-2'></i>Search Request by Id</p>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon1'>Request ID</InputGroup.Text>
						<Form.Control
							placeholder='Type request id'
							aria-label='Type request id'
							aria-describedby='basic-addon2'
							onChange={(e) => setRequestId(e.target.value)}
						/>
						<Button 
							style={{ backgroundColor: '#712cf9', border: '#712cf9'}} id='search-request' 
							onClick={() => dispatch(get_request_info(requestId))}
						>
							{request_info_loading ? <Spinner className='me-2' animation='border' size='sm' /> : <i className='bi bi-search me-2'></i>}
							Search
						</Button>
					</InputGroup>
					{errorExists(requests_errors, 'request_info')
						?	<Error title='Request Id' type='danger' msg={requests_errors['request_info'].msg} />
						:	!isEmpty(request_info) &&
							<Card className='shadow mb-5 bg-body rounded card-border'>
								<Card.Body>
									<Card.Title className='text-center' as='h5'>Request Found</Card.Title>
									<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{request_info?._id?.$oid}</Card.Subtitle>
									<hr/>
									<Row>
										<Col>
											<p className='text-center m-0 p-0'><strong>User:</strong> {request_info?.auth?.user?.username}</p>
										</Col>
										<Col>
											<p className='text-center m-0 p-0'><strong>Status:</strong> <StatusBadge status={getJobsStatusDesc(request_info?.status)} /></p>
										</Col>
									</Row>
									<hr/>
									<Row>
										<Col>
											<p className='text-center m-0 p-0'><strong>Method:</strong> {getRequestMethodDesc(request_info?.path?.method)}</p>
										</Col>
										<Col>
											<p className='text-center m-0 p-0'><strong>Url:</strong> {request_info?.path?.url}</p>
										</Col>
										<Col>
											<p className='text-center m-0 p-0'><strong>Date:</strong> {epochToLocalDate(request_info?.date?.$date)}</p>
										</Col>
									</Row>
									<hr/>
									<Row className='text-end'>
										<Card.Link style={{ textDecoration: 'none' }} href={`/requests/${request_info?._id?.$oid}/details`} target='_blank'>
											More info <i className='bi bi-chevron-right ms-2 me-1'></i>
										</Card.Link>
									</Row>
								</Card.Body>
							</Card>
					}
				</div>
				
				<p className='text-center pt-3'><i className='bi bi-filter ms-1 me-2'></i>Filters</p>
				<RequestFilters
					searchReqsFilters={searchReqsFilters}
					setSearchReqsFilters={setSearchReqsFilters}
					resetSearchReqsFilters={resetSearchReqsFilters}
					handleSearchRequest={handleSearchRequest}
				/>
				<RequestsTable
					tableLoading={requests_loading}
					dataCount={all_requests.count}
					tableData={all_requests.requests}
					start={start}
					setStart={setStart}
					end={end}
					setEnd={setEnd}
					limit={limit}
				/>

				<hr className='mt-4'/>
				<h3 className='text-center'>Service Instances</h3>
				{service_instances.count > 0
					?	<>
							{service_instances_loading
								? <Container className='d-flex align-items-center justify-content-center' style={{ height: '533px' }}>
										<p className='spinner-text'>Loading</p>
										<div className='spinner-margin'>
											<Spinner animation='border' style={{ width: '2.5rem', height: '2.5rem', color: '#004982' }} />
										</div>
									</Container>
								:	<>
										<p className='text-center pt-3'><i className='bi bi-bar-chart-line ms-1 me-2'></i>Stats</p>
										<p className='text-center'>
											<span className='fw-bold'>Total:</span> {instancesStats.total}&nbsp; &#8212; &nbsp;
											<span className='fw-bold'>Available:</span> {instancesStats.available}
										</p>
										{service_instances.instances.map((serviceInstance, idx) => (
											!isEmpty(serviceInstance) &&
												<ServiceInstance 
													key={idx}
													serviceInstance={serviceInstance}
													showLink={true}
													serviceId={service_info._id?.$oid}
												/>
										))}
									</>	
							}
						</>
					:	<Error type='warning' msg='No worker instances found.' />
				}
			</Container>
		);
	}
}

export default ServiceInfo;
