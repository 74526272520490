import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Button, Card, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

// Components
import ToastAlert from '../../../utils/ToastAlert';
import ConfirmDialog from '../../../utils/ConfirmDialog';
import ModelForm from './ModelForm';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import StatusBadge from '../../../utils/StatusBadge';
import Versions from '../versions/Versions';

// Actions
import { get_model_info, model_delete } from '../../../../actions/modelsActions';

// Utils
import isEmpty from '../../../../utils/isEmpty';
import { epochToLocalDate } from '../../../../utils/dateConverter';
import { getModelStatusDesc } from '../../../../utils/getStatusDesc';

const ModelInfo = () => {
	const { model_id } = useParams();

	const dispatch = useDispatch();

	const { models_loading, model_info } = useSelector(state => state.models);

	const [confirmDialog, setConfirmDialog] = useState({ show: false, title: '', function: null });

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [modalShow, setModalShow] = useState(false);
	const [update, setUpdate] = useState(false);
	const [modelToUpdate, setModelToUpdate] = useState({});

	const [pageTitle, setPageTitle] = useState(process.env.REACT_APP_NAME);

	useEffect(() => {
		dispatch(get_model_info(model_id));
	}, []);

	useEffect(() => {
		if (!isEmpty(model_info)) {
			setPageTitle(`${process.env.REACT_APP_NAME} - ${model_info?.name}`);

			setOrgFilters({
				organization: model_info.organization?.$oid,
				workspace: model_info.workspace?.$oid,
				module: model_info.module?.$oid
			});
		}
	}, [model_info]);

	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);

	const onHide = () => {
		setModalShow(false);
		setUpdate(false);
		setModelToUpdate({});
	}

	const updateModel = () => {
		setModalShow(true);
		setUpdate(true);
		setModelToUpdate(model_info);
	}

	const deleteModel = () => {
		setConfirmDialog({
			show: true,
			title: 'delete this model',
			function: () => dispatch(model_delete(model_info._id.$oid, {}, true))
		});
	}

	if (models_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading worker info' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container'>
				<ToastAlert />

				<ConfirmDialog 
					show={confirmDialog.show}
					confirmDialogTitle={confirmDialog.title}
					onHide={() => setConfirmDialog({ ...confirmDialog, show: false })}
					confirmFunction={confirmDialog.function}
				/>

				<ModelForm
					show={modalShow}
					onHide={onHide}
					orgFilters={orgFilters}
					update={update}
					modelToUpdate={modelToUpdate}
					single={true}
				/>

				<Container className='wrapper-container px-0 mb-3'>
					<Col className='title'>
						<h2 className='text-center'>{model_info.name}</h2>
						<p className='text-center text-muted'>{model_info.description}</p>
					</Col>
					<Col className='icons-btns'>
						<Container className='d-flex align-items-center justify-content-end px-0'>
							<div className='pe-4'>
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip id='button-tooltip-2'>Edit</Tooltip>}
								>
									<Button type='button' className='jobs-actions me-2' onClick={updateModel}>
										<i className='bi bi-pencil-fill fs-5'></i>
									</Button>
								</OverlayTrigger>
							</div>
							<div>
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip id='button-tooltip-2'>Delete</Tooltip>}
								>
									<Button type='button' className='jobs-actions me-2' onClick={deleteModel}>
										<i className='bi bi-trash-fill fs-5'></i>
									</Button>
								</OverlayTrigger>
							</div>
						</Container>
					</Col>
				</Container>
				<Card className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<Card.Title className='text-center' as='h5'>Model Information</Card.Title>
						<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {model_info._id?.$oid}</Card.Subtitle>
						<hr/>						
						<Container className='p-0'>
							<Row>
								<Col className='text-center'><strong>Organization:</strong> {model_info.organization?.$oid || 'No info'}</Col>
								<Col className='text-center'><strong>Workspace:</strong> {model_info.workspace?.$oid || 'No info'}</Col>
								<Col className='text-center'><strong>Module:</strong> {model_info.module?.$oid || 'No info'}</Col>
							</Row>
							<hr/>
							<Row>
								<Col className='text-center'><strong>Status:</strong> <StatusBadge status={getModelStatusDesc(model_info.status)} /></Col>
								<Col className='text-center'><strong>Date:</strong> {epochToLocalDate(model_info.date?.$date)}</Col>
							</Row>
						</Container>
					</Card.Body>
				</Card>

				<Versions
					modelInfo={model_info}
				/>
			</Container>
		);
	}
}

export default ModelInfo;
