import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Button, Card, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Components
import ConfirmDialog from '../../../utils/ConfirmDialog';
import StatusBadge from '../../../utils/StatusBadge';
import StatusUpdate from '../../../utils/StatusUpdate';

// Actions
import { get_model_status_history, model_delete, model_status_update } from '../../../../actions/modelsActions';

// Utils
import { getModelStatusDesc } from '../../../../utils/getStatusDesc';

const Model = (props) => {
	const { model, updateModel } = props;

	const dispatch = useDispatch();

	const { model_status_history_loading, model_status_history, models_errors } = useSelector(state => state.models);

	const [confirmDialog, setConfirmDialog] = useState({ show: false, title: '', function: null });
	const [statusUpdateModal, setStatusUpdateModal] = useState(false);

	const deleteModel = () => {
		setConfirmDialog({
			show: true,
			title: 'delete this model',
			function: () => dispatch(model_delete(model._id.$oid, {}, false))
		});
	}

	const updateModelStatus = () => {
		setStatusUpdateModal(true);
		dispatch(get_model_status_history(model._id.$oid));
	}

	return (
		<div>
			<ConfirmDialog 
				show={confirmDialog.show}
				confirmDialogTitle={confirmDialog.title}
				onHide={() => setConfirmDialog({ ...confirmDialog, show: false })}
				confirmFunction={confirmDialog.function}
			/>

			<StatusUpdate
				show={statusUpdateModal}
				onHide={() => setStatusUpdateModal(false)}
				loading={model_status_history_loading}
				statusHistory={model_status_history}
				statusDescriptions={(status) => getModelStatusDesc(status)}
				statusQty={9}
				changeStatus={(status) => dispatch(model_status_update(model._id.$oid, status))}
			/>

			<Card className='shadow mb-4 bg-body rounded card-border'>
				<Container>
					<Row>
						<Col>
							<Card.Body className='ps-0'>
								<Card.Title as='h6'>{model.name}</Card.Title>
								<Card.Subtitle className='mb-2 text-muted' as='p'>{model.description}</Card.Subtitle>
								<Card.Text>
									<strong>Id:</strong> {model._id.$oid} - <strong>Organization:</strong> {model?.organization?.$oid || 'No info'} - <strong>Workspace:</strong> {model?.workspace?.$oid || 'No info'} - <strong>Module:</strong> {model?.module?.$oid || 'No info'}
								</Card.Text>
							</Card.Body>
						</Col>
						<Col className='d-flex align-items-center justify-content-between' md={2}>
							<Button className='jobs-actions' onClick={updateModelStatus}>
								<StatusBadge status={getModelStatusDesc(model.status)} />
							</Button>
						</Col>
						<Col className='d-flex align-items-center justify-content-between' md='auto'>
							<div className='pe-4'>
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip id='button-tooltip-2'>Info</Tooltip>}
								>
									<Button type='button' className='jobs-actions me-2' onClick={() => window.open(`/models/${model._id.$oid}/info`, '_self')}>
										<i className='bi bi-eye-fill fs-5'></i>
									</Button>
								</OverlayTrigger>
							</div>
							<div className='pe-4'>
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip id='button-tooltip-2'>Edit</Tooltip>}
								>
									<Button type='button' className='jobs-actions me-2' onClick={() => updateModel(model)}>
										<i className='bi bi-pencil-fill fs-5'></i>
									</Button>
								</OverlayTrigger>
							</div>
							<div>
								<OverlayTrigger
									placement='top'
									overlay={<Tooltip id='button-tooltip-2'>Delete</Tooltip>}
								>
									<Button type='button' className='jobs-actions me-2' onClick={deleteModel}>
										<i className='bi bi-trash-fill fs-5'></i>
									</Button>
								</OverlayTrigger>
							</div>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
}

Model.propTypes = {
	model: PropTypes.object.isRequired,
	updateModel: PropTypes.func.isRequired
}

export default Model;
