import Axios from 'axios';

import {
	INSTANCE_OPTIONS_GET,
	INSTANCE_OPTIONS_ERROR,
	INSTANCE_MACHINES_GET,
	INSTANCE_MACHINES_ERROR
} from './types';

export const get_instance_options = () => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/resources/options';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: INSTANCE_OPTIONS_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INSTANCE_OPTIONS_ERROR,
			payload: { type: 'instances_options', msg: err.message }
		});
	})
}

export const get_instance_machines = () => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/resources/machines';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: INSTANCE_MACHINES_GET,
			payload: res.data
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: INSTANCE_MACHINES_ERROR,
			payload: { type: 'instances_machines', msg: err.message }
		});
	})
}
