import React from 'react';

// Packages
import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import StatusBadge from '../../utils/StatusBadge';
import JobInfo from './JobInfo';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';
import { getServiceStatusDesc } from '../../../utils/getStatusDesc';

const JobInstance = (props) => {
	const { jobInstance } = props;

	return (
		<Card.Body>
			<Card.Title className='text-center' as='h5'>{jobInstance?.name}</Card.Title>
			<Card.Subtitle className='mb-2 text-center text-muted' as='p'>{jobInstance?.description}</Card.Subtitle>
			<Card.Subtitle className='mb-2 text-center text-muted' as='p'><strong>Id:</strong> {jobInstance?._id?.$oid}</Card.Subtitle>
			<hr/>
			<Container className='p-0'>
				<Row>
					<Col>
						<p className='text-center m-0 p-0'><strong>Date:</strong> {epochToLocalDate(jobInstance?.date?.$date)}</p>
						<p className='text-center m-0 p-0'>
							<strong>Organization:</strong> {jobInstance?.organization === null ? 'Null' : jobInstance?.organization?.$oid }
						</p>
					</Col>
					<Col>
						<p className='text-center m-0 p-0'><strong>Status:</strong> <StatusBadge status={getServiceStatusDesc(jobInstance?.status)} /></p>
						<p className='text-center m-0 p-0'><strong>Speed:</strong> {jobInstance?.speed}</p>
					</Col>
				</Row>
			</Container>
			<hr/>
		</Card.Body>
	)
}

JobInstance.propTypes = {
	jobInstance: PropTypes.object.isRequired
}

const JobsFound = (props) => {
	const { jobsFound } = props;

	return (
		<div>
			{jobsFound.map((job, jobIdx) => (
				<Card key={jobIdx} className='shadow mb-5 bg-body rounded card-border'>
					<Card.Body>
						<JobInstance jobInstance={job?.instance} />
						<Row>
							{job?.match.map((item, idx) => (
								<Col key={idx} xs={job?.match?.length > 1 ? 6 : 12}>
									<JobInfo title='Job' jobsType='workers' jobInfo={item} instanceId={job?.instance?._id?.$oid} displayLogs />
								</Col>
							))}
						</Row>
					</Card.Body>
				</Card>
			))}
		</div>
	)
}

JobsFound.propTypes = {
	jobsFound: PropTypes.array
}

export default JobsFound;
