import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// React Bootstrap
import { Table } from 'react-bootstrap';

// Components
import Pagination from '../../utils/Pagination';

// Actions
import { get_helper_instance_data_history } from '../../../actions/helperInstanceActions';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';

const DataHistory = () => {
	const { instance_id } = useParams();
	
	const dispatch = useDispatch();
	
	const { helper_instance_data_history } = useSelector(state => state.helperInstance);
	
	const [skip, setSkip] = useState(0);
	const [limit] = useState(10);
	
	const nPages = Math.ceil(helper_instance_data_history.count / limit);
	
	useEffect(() => {
		dispatch(get_helper_instance_data_history(instance_id, skip, limit));
	}, [skip]);
	
	return (
		<Fragment>
			<Table className='mt-4' responsive>
				<thead style={{ backgroundColor: '#e7f1ff', verticalAlign: 'middle' }}>
					<tr>
						<th>Id</th>
						<th>Resource</th>
						<th>Organization</th>
						<th>Num of processes</th>
						{/* <th>Last process time</th> */}
						<th>Min process time</th>
						<th>Max process time</th>
						<th>Sum process times</th>
						<th>Average process time</th>
						<th>Date</th>
					</tr>
				</thead>
				<tbody>
					{helper_instance_data_history.count > 0
						?	helper_instance_data_history.history.map((data) => (
								<Fragment key={data._id.$oid}>
									<tr>
										<td>{data._id.$oid}</td>
										<td>{data.resource.$oid ? data.resource.$oid : data.resource._id.$oid}</td>
										<td>{data.organization === null ? 'Null' : data.organization.$oid}</td>
										<td>{data.n_processes}</td>
										{/* <td>{data.last_process_time}</td> */}
										<td>{data.min_process_time}</td>
										<td>{data.max_process_time}</td>
										<td>{data.sum_process_times}</td>
										<td>{data.average_process_time}</td>
										<td>{epochToLocalDate(data.date.$date)}</td>
									</tr>
								</Fragment>
							))
						:	<tr>
								<td className='text-center' colSpan={4}>No data history</td>
							</tr>
					}
				</tbody>
			</Table>
			<Pagination
				count={helper_instance_data_history.count}
				arrayLength={helper_instance_data_history.history.length}
				nPages={nPages}
				skip={skip}
				setSkip={setSkip}
				limit={limit}
			/>
		</Fragment>
	);
}

export default DataHistory;
