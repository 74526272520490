import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// React Bootstrap
import { Modal, Container, Button, Form, Spinner } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';

// Components
import SelectButton from '../../utils/SelectButton';
import Alert from '../../utils/Alert';

// Actions
import { get_all_worker_resources } from '../../../actions/workersActions';
import { create_message, update_message } from '../../../actions/messagesActions';

// Utils
import errorExists from '../../../utils/errorExists';

// Icons
import checkAnimation from '../../../static/icons/checkAnimation.json';

const MessagesFormModal = (props) => {
	const { show, onHide, create, messageId } = props;

	const dispatch = useDispatch();

	const { workers_loading, all_worker_resources } = useSelector(state => state.workers);

	const { update_message_loading, create_message_success, update_message_success, messages_errors } = useSelector(state => state.messages);

	const [messageType, setMessageType] = useState(0);
	const [worker, setWorker] = useState('');

	const initialMessageInfo = { name: '', text: '', path: '' };
	const [messageInfo, setMessageInfo] = useState(initialMessageInfo);

	const initialAlert = { display: false, type: '', msg: '' };
	const [alert, setAlert] = useState(initialAlert);

	const closeModalRef = useRef();
	const lottieRef	= useRef();

	useEffect(() => {
		dispatch(get_all_worker_resources());
	}, []);

	const closeModal = () => {
		onHide();
		setAlert(initialAlert);
		setMessageType(0);
		setWorker('');
		setMessageInfo(initialMessageInfo);
	}

	const createWorkerOptions = (all_worker_resources) => {
		let workerOptions = [];

		all_worker_resources.workers.forEach((worker) => {
			workerOptions.push({ name: worker.name, value: worker._id.$oid });
		});

		return workerOptions;
	}

	const validateMsgInfo = messageInfo.name === '' || messageInfo.text === '' || messageInfo.path === '';

	const workerMessageHandler = (e) => {
		e.preventDefault();

		if (create) {
			if (messageType === 0 || validateMsgInfo) {
				setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
				return;
			}
			setAlert(initialAlert);

			dispatch(create_message({ ...messageInfo, message_type: messageType, worker }, closeModalRef));
		}
		else {
			if (validateMsgInfo) {
				setAlert({ display: true, type: 'danger', msg: 'Missing fields!' });
				return;
			}
			setAlert(initialAlert);
			
			dispatch(update_message(messageId, { ...messageInfo }, closeModalRef));
		}
	}

	return (
		<Modal
			show={show}
			onHide={closeModal}
			backdrop='static'
			keyboard={false}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{create ? 'Create Worker Message' : 'Edit Worker Message'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{alert.display && <Alert type={alert.type} msg={alert.msg} />}
				{errorExists(messages_errors, 'create_message') &&
					<Alert type='danger' msg={messages_errors['create_message'].msg} />
				}

				{create &&
					<Fragment>
						<Container className='mb-3 p-0' fluid>
							<p className='mb-2'>Message Type</p>
							<SelectButton
								options={[
									{ name: 'Global', value: 1 },
									{ name: 'Single', value: 2 },
									{ name: 'Custom', value: 3 }
								]}
								name='messageType'
								value={messageType}
								onChange={(e) => setMessageType(e !== null ? e.value : 0)}
							/>
						</Container>

						{messageType === 2 &&
							<Container className='mb-3 p-0' fluid>
								<p className='mb-2'>Worker</p>
								<SelectButton
									options={createWorkerOptions(all_worker_resources)}
									name='worker'
									value={worker}
									onChange={(e) => setWorker(e !== null ? e.value : '')}
								/>
							</Container>
						}
					</Fragment>
				}

				<Form>
					<Form.Group className='mb-3' controlId='range.ControlInput1'>
						<Form.Label>Name</Form.Label>
						<Form.Control
							type='text'
							placeholder='Type name'
							onChange={(e) => setMessageInfo({...messageInfo, name: e.target.value})}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='range.ControlInput1'>
						<Form.Label>Text</Form.Label>
						<Form.Control
							type='text'
							placeholder='Type text'
							onChange={(e) => setMessageInfo({...messageInfo, text: e.target.value})}
						/>
					</Form.Group>
					<Form.Group className='mb-3' controlId='range.ControlInput1'>
						<Form.Label>Path</Form.Label>
						<Form.Control
							type='text'
							placeholder='Type path'
							onChange={(e) => setMessageInfo({...messageInfo, path: e.target.value})}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='outline-secondary' onClick={closeModal} ref={closeModalRef}>Cancel</Button>
				<Button className={create_message_success || update_message_success ? 'success-btn' : 'submit-btn'} 
					onClick={(e) => workerMessageHandler(e)}
				>
					{create_message_success || update_message_success
						? <Lottie
								loop={false}
								lottieRef={lottieRef}
								animationData={checkAnimation}
								style={{ height: '24px' }}
							/>
						: update_message_loading
							? <Fragment>
									<Spinner className='me-2' as='span' animation='border' size='sm' role='status' aria-hidden='true' />
									{create ? 'Creating' : 'Editing'}
								</Fragment>
							: create ? 'Create' : 'Edit'
					}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

MessagesFormModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	create: PropTypes.bool.isRequired,
	messageId: PropTypes.string
}

export default MessagesFormModal;
