import React, { Fragment } from 'react';

// React Bootstrap
import { Container, Row, Col, Accordion } from 'react-bootstrap';

// Packages
import PropTypes from 'prop-types';

// Utils
import { epochToLocalDate } from '../../../utils/dateConverter';

const WorkerResource = (props) => {
	const { title, workerResource } = props;

	return (
		<Fragment>
			<hr/>
			<h5 className='text-center'>{title}</h5>

			<Accordion className='mt-3 mb-3'>
				<Accordion.Item eventKey='0'>
					<Accordion.Header>{workerResource?.name}</Accordion.Header>
					<Accordion.Body>
						<Container>
							<Row>
								<Col>
									<Row>
										<p className='m-0 p-0'><strong>Id:</strong> {workerResource?._id?.$oid}</p>
									</Row>
									<Row>
										<p className='m-0 p-0'><strong>Description:</strong> {workerResource?.description}</p>
									</Row>
								</Col>
								<Col>
									<Row>
										<p className='m-0 p-0'>
											<strong>Organization:</strong> {workerResource?.organization === null ? 'Null' : workerResource?.organization}
										</p>
									</Row>
									<Row>
										<p className='m-0 p-0'><strong>Date:</strong> {epochToLocalDate(workerResource?.date?.$date)}</p></Row>
								</Col>
							</Row>
						</Container>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Fragment>
	);
}

WorkerResource.propTypes = {
	title: PropTypes.string.isRequired,
	workerResource: PropTypes.object.isRequired
}

export default WorkerResource;
