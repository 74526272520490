import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

// React Bootstrap
import { Container, Row, Col, OverlayTrigger, Tooltip, Button, Spinner } from 'react-bootstrap';

// Components
import LoadingSpinner from '../../utils/LoadingSpinner';
import JobInfo from '../jobs/JobInfo';
import Error from '../../utils/Error';
import LogsTerminal from './LogsTerminal';

// Actions
import { get_job_info } from '../../../actions/jobsActions';
import { get_logs } from '../../../actions/logsActions';

// Utils
import errorExists from '../../../utils/errorExists';

const LogsInfo = () => {
	const location = useLocation();
	const { worker_id, helper_id, instance_id, job_id } = useParams();

	const dispatch = useDispatch();

	const { logs_loading, logs, logs_errors } = useSelector(state => state.logs);

	const { job_search_loading, job_info, jobs_errors } = useSelector(state => state.jobs);

	const [jobsType, setJobsType] = useState('');

	useEffect(() => {
		if (location.pathname.includes('worker')) {
			setJobsType('workers');
		}
		else if (location.pathname.includes('helper')) {
			setJobsType('helpers');
		}
	}, [location.pathname]);

	useEffect(() => {
		if (jobsType) {
			dispatch(get_job_info(jobsType, instance_id, job_id));
	
			let logData;

			if (jobsType === 'workers') {
				logData = { worker: worker_id, job: job_id };
			}
			else if (jobsType === 'helpers') {
				logData = { helper: helper_id, job: job_id };
			}
	
			dispatch(get_logs(jobsType, instance_id, logData));
		}
	}, [jobsType, instance_id, job_id, worker_id, helper_id, dispatch]);

	if (job_search_loading) {
		return (
			<LoadingSpinner loadingMsg='Loading logs' />
		);
	}
	else {
		return (
			<Container className='mt-3 custom-p-container'>
				<h2 className='text-center'>Job Logs</h2>

				{errorExists(jobs_errors, 'get_job_info')
					?	<Error title='Job Info' type='danger' msg={jobs_errors['get_job_info'].msg} />
					:	<JobInfo title='Job Info' jobsType={jobsType} jobInfo={job_info} displayLogs={false} />
				}

				{logs_loading 
					? <Container className='d-flex align-items-center justify-content-center' style={{ height: '500px' }}>
							<p style={{ marginBottom: 0, fontSize: '1.3rem' }}>Loading logs</p>
							<div className='spinner-margin'>
								<Spinner animation='border' style={{ color: '#004982' }} />
							</div>
						</Container>
					:	!errorExists(logs_errors, 'logs')
						?	<Fragment>
								<Container>
									<Row className='mb-3 align-items-center'>
										<Col className='text-end' xs='12'>
											<OverlayTrigger
												placement='bottom'
												overlay={<Tooltip id='button-tooltip-2'>Refresh</Tooltip>}
											>
												<Button type='button' className='jobs-actions me-2' 
													onClick={() => dispatch(get_logs(instance_id, { worker: worker_id, job: job_id }))}
												>
													<i className='bi bi-arrow-clockwise bi-bold fs-5'></i>
												</Button>
												</OverlayTrigger>
										</Col>
									</Row>
								</Container>
								<LogsTerminal logs={logs} />
							</Fragment>
						: <Error title='Logs' type='danger' msg={logs_errors['logs'].msg} />
				}
			</Container>
		);
	}
}

export default LogsInfo;
